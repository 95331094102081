import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import IncidenceForm from './IncidenceForm'
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import IncidenceSolution from './IncidenceSolution';
import IncidenceClose from './IncidenceClose';

function TooltipButton({ tooltip, button, id }) {
    return (
      <>
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={id}>
                    {tooltip}
                </Tooltip>
            }
        >
            {button}
        </OverlayTrigger>
      </>
    );
  }

const Incidence = ({ nameSingle, namePlural, separator }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [dataUpdated, setDataUpdated] = useState({})
    const [dataClose, setDataClose] = useState({})
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [loadSubmitDelete, setLoadSubmitDelete] = useState(false)
    const [dataDeleted, setDataDeleted] = useState({})

    const [modalShowReply, setModalShowReply] = useState(false);
    const [modalTitleReply, setModalTitleReply] = useState('');
    const [dataComments, setDatComments] = useState('')
    const [modalShowClose, setModalShowClose] = useState(false);
    const [modalTitleClose, setModalTitleClose] = useState('');
    const [loadSubmitClose, setLoadSubmitClose] = useState(false)

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers.incidence.get_incidence().then(res => {
                setRows(res.data)
            }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setIsProccesing(false)
                setReload(false)
            })
        }
    }, [reload])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.incidence.incidence_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setModalShow(false)
                setDataUpdated({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
            setReload(true)
        })
    }

    const handleGet = e => {
        let $this = e.currentTarget

        if (!$this.dataset?.idIncidence) {
            setModalTitle(`Registrar ${nameSingle}`)
            setDataUpdated({})
            setModalShow(true);
            return
        }

        let buttonHTML = $this.innerHTML;
        $this.innerHTML = '<i className="fa fa-circle-notch fa-spin"></i>'
        let { idIncidence } = $this.dataset

        Controllers.incidence.get_incidence_id({ p_id_incidence: idIncidence }).then(res => {
            setModalTitle(`Modificar ${nameSingle} #${idIncidence}`)
            setDataUpdated({ data: res.data })
            setModalShow(true);
        }).catch(req => {
            Helpers.promise.catch({ req, toast })
            setReload(true)
        })
        .finally(() => $this.innerHTML = buttonHTML)
    }

    const handleDelete = () => {
        setLoadSubmitDelete(true)

        Controllers.incidence.incidence_delete(dataDeleted).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setShowConfirmDelete(false)
                setDataDeleted({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setReload(true)
            setLoadSubmitDelete(false)
        })
    }

    const handleHideModalForm = () => {
        setModalShow(false)
        setLoadSubmit(false)
    }

    const handleShowModalDelete = e => {
        let { idIncidence, descriptionIncidence } = e.currentTarget.dataset
        setDataDeleted({
            p_id_incidence: idIncidence,
            p_description_incidence: descriptionIncidence
        })
        setShowConfirmDelete(true)
    }

    const handleHideModalDelete = () => setShowConfirmDelete(false)

    const handleShowModalReply = e => {
        let { idIncidence } = e.currentTarget.dataset
        setDatComments(idIncidence)
        setModalTitleReply('Incidencia #' + idIncidence)
        setModalShowReply(true)
    }

    const handleShowModalClose = e => {
        let { idIncidence } = e.currentTarget.dataset
        setDataClose(idIncidence)
        setModalTitleClose('Incidencia #' + idIncidence)
        setModalShowClose(true)
    }

    const handleSubmitClose = ({ data }) => {
        setLoadSubmitClose(true)
        Controllers.incidence.incidence_close(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setModalShowClose(false)
                setDataClose({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmitClose(false)
            setReload(true)
            setLoadSubmit(false)
        })
    }

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_incidence', text: 'ID', align: 'center' },
                { name: 'date_incidence', text: 'Fecha' },
                { name: 'folio_incidence', text: 'Folio' },
                { name: 'name_category', text: 'Departamento' },
                { name: 'description_incidence', text: 'Descripción' },
                { name: 'g', text: 'G', align: 'center', render: r => parseInt(r.g) > 0 ? <i className="fas fa-check-circle text-success"></i> : <i className="fas fa-clock text-warning"></i> },
                { name: 'sg', text: 'SG', align: 'center', render: r => parseInt(r.sg) > 0 ? <i className="fas fa-check-circle text-success"></i> : <i className="fas fa-clock text-warning"></i>  },
                { name: 'rh', text: 'RH', align: 'center', render: r => parseInt(r.rh) > 0 ? <i className="fas fa-check-circle text-success"></i> : <i className="fas fa-clock text-warning"></i>  },
                { name: 'adc', text: 'ADC', align: 'center', render: r => parseInt(r.adc) > 0 ? <i className="fas fa-check-circle text-success"></i> : <i className="fas fa-clock text-warning"></i>  },
                { name: 'ca', text: 'CA', align: 'center', render: r => parseInt(r.ca) > 0 ? <i className="fas fa-check-circle text-success"></i> : <i className="fas fa-clock text-warning"></i>  },
                { name: 'ao', text: 'AO', align: 'center', render: r => parseInt(r.ao) > 0 ? <i className="fas fa-check-circle text-success"></i> : <i className="fas fa-clock text-warning"></i>  },
                { name: 'lic', text: 'LIC', align: 'center', render: r => parseInt(r.lic) > 0 ? <i className="fas fa-check-circle text-success"></i> : <i className="fas fa-clock text-warning"></i>  },
                { name: 'solution_incidence', text: 'Solución de Gerencia Sucursal' },
                { name: 'date_close_incidence', text: 'Fecha de Cierre' },
                { name: 'folio_close_incidence', text: 'Folio de Cierre' },
                { name: 'state_incidence', text: 'Estado', align: 'center', render: r => <div className={'badge badge-' + (parseInt(r.state_incidence) === 1 ? 'info' : 'dark')}>
                    {parseInt(r.state_incidence) === 1 ? 'En Proceso' : 'Cerrado'}
                </div> },
                { name: 'actions', style: { minWidth: 135 }, text: <i className="fa fa-cog"></i>, align: 'center', render: u => {
                    return <div>
                        {parseInt(u.state_incidence) === 1 ? <TooltipButton
                            tooltip="Modificar"
                            id={'btn-incidence-edit-' + u.id_incidence}
                                button={<Button className="mr-1" size="xs" data-id-incidence={u.id_incidence} onClick={handleGet}>
                                <i className="fa fa-edit"></i>
                            </Button>}
                        /> : ''}
                        <TooltipButton
                            tooltip="Eliminar"
                            id={'btn-incidence-delete-' + u.id_incidence}
                            button={<Button variant="danger" className="mr-1" size="xs" data-id-incidence={u.id_incidence} data-description-incidence={u.description_incidence} onClick={handleShowModalDelete}>
                                <i className="fa fa-trash"></i>
                            </Button>}
                        />
                        {parseInt(u.state_incidence) === 1 ? <TooltipButton
                            tooltip="Brindar Solución"
                            id={'btn-incidence-solution-' + u.id_incidence}
                            button={<Button variant="success" className="mr-1" size="xs" data-id-incidence={u.id_incidence} data-description-incidence={u.description_incidence} onClick={handleShowModalReply}>
                                <i className="fas fa-reply"></i>
                            </Button>}
                        /> : ''}
                        {parseInt(u.state_incidence) === 1 ? <TooltipButton
                            tooltip="Cerrar Incidencia"
                            id={'btn-incidence-close-' + u.id_incidence}
                            button={<Button variant="dark" className="mr-1" size="xs" data-id-incidence={u.id_incidence}  onClick={handleShowModalClose}>
                                <i className="fas fa-times"></i>
                            </Button>}
                        /> : ''}
                    </div>
                } },
            ],
        ]}
        rows={rows}
        isProcessing={isProcessing}
    />, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="d-flex justify-content-between bg-danger text-white">
                <span>Listado De {namePlural}</span>
                <Button size="sm" onClick={handleGet}>
                    <i className="fa fa-plus mr-1"></i> Registrar {nameSingle}
                </Button>
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
        <Modal show={modalShow} onHide={handleHideModalForm} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <IncidenceForm onSubmit={handleInsertUpdate} loadSubmit={loadSubmit} {...dataUpdated} />
            </Modal.Body>
        </Modal>
        <Modal show={modalShowReply} size="xl" onHide={_ => setModalShowReply(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitleReply}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <IncidenceSolution idIncidence={dataComments} onSubmit={_ => setReload(true)} />
            </Modal.Body>
        </Modal>
        <Modal show={modalShowClose} onHide={_ => setModalShowClose(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitleClose}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <IncidenceClose loadSubmit={loadSubmitClose} onSubmit={handleSubmitClose} idIncidence={dataClose} />
            </Modal.Body>
        </Modal>
        <SweetAlert
            icon="delete"
            show={showConfirmDelete}
            showCancelButton
            title={<>¿Desea eliminar {separator} {nameSingle}?</>}
            subtitle={<>#{dataDeleted.p_id_incidence} - {dataDeleted.p_description_incidence}</>}
            confirmButtonText="¡Sí, eliminar!"
            cancelButtonText="¡No!"
            loadSubmit={loadSubmitDelete}
            onCancel={handleHideModalDelete}
            onConfirm={handleDelete}
        />
    </>
}

export default Incidence