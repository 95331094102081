import Helpers from '../Components/Helpers/Helpers';

const Controllers = {
  users: {
    get_login: (data = {}) => Helpers.axios({ url: '/users/login', method: 'POST', data }),
    get_users: () => Helpers.axios({ url: '/users/get/all' }),
    get_users_id: ({ p_id_user }) => Helpers.axios({ url: '/users/get/id/' + p_id_user }),
    users_insert_update: (data = {}) => Helpers.axios({ url: '/users/insert/update', method: 'POST', data }),
    users_delete: (data = {}) => Helpers.axios({ url: '/users/delete', method: 'POST', data }),
    get_user_information: () => Helpers.axios({ url: '/users/get/information', method: 'POST' }),
  },
  profile: {
    get_profile: () => Helpers.axios({ url: '/profile/get/all' }),
    get_profile_id: ({ p_id_profile }) => Helpers.axios({ url: '/profile/get/id/' + p_id_profile }),
    profile_insert_update: (data = {}) => Helpers.axios({ url: '/profile/insert/update', method: 'POST', data }),
    profile_delete: (data = {}) => Helpers.axios({ url: '/profile/delete', method: 'POST', data })
  },
  tags_items: {
    get_tags_items_profile: ({ p_id_profile = '' } = {}) => Helpers.axios({ url: '/tagsitems/profile/get/all/' + p_id_profile }),
  },
  customers: {
    get_customers: () => Helpers.axios({ url: '/customers/get/all' }),
    get_customers_branch_office: ({ p_id_branch_office = '' } = {}) => Helpers.axios({ url: '/customers/branch_office/all/' + p_id_branch_office }),
    get_customers_birthday: () => Helpers.axios({ url: '/customers/birthday/get/all' }),
    get_customers_id: ({ p_id_customers }) => Helpers.axios({ url: '/customers/get/id/' + p_id_customers }),
    customers_insert_update: (data = {}) => Helpers.axios({ url: '/customers/insert/update', method: 'POST', data, hasFile: true }),
    customers_delete: (data = {}) => Helpers.axios({ url: '/customers/delete', method: 'POST', data })
  },
  category: {
    get_category: ({ p_state_category = '' } = {}) => Helpers.axios({ url: '/category/get/all/' + p_state_category }),
    get_category_qty_article: () => Helpers.axios({ url: '/category/article/qty/get/all' }),
    get_category_qty_event: () => Helpers.axios({ url: '/category/event/qty/get/all' }),
    get_category_qty_noti: () => Helpers.axios({ url: '/category/noti/qty/get/all' }),
    get_category_id: ({ p_id_category }) => Helpers.axios({ url: '/category/get/id/' + p_id_category }),
    category_insert_update: (data = {}) => Helpers.axios({ url: '/category/insert/update', method: 'POST', data }),
    category_delete: (data = {}) => Helpers.axios({ url: '/category/delete', method: 'POST', data })
  },
  tag: {
    get_tag: () => Helpers.axios({ url: '/tag/get/all' }),
    get_tag_id: ({ p_id_tag }) => Helpers.axios({ url: '/tag/get/id/' + p_id_tag }),
    tag_insert_update: (data = {}) => Helpers.axios({ url: '/tag/insert/update', method: 'POST', data }),
    tag_delete: (data = {}) => Helpers.axios({ url: '/tag/delete', method: 'POST', data })
  },
  article: {
    get_article: ({ p_paginate = 0, p_filter_text = '', p_slug_category = '' } = {}) => Helpers.axios({ url: '/article/get/all/', method: 'POST', data: { p_paginate, p_filter_text, p_slug_category } }),
    get_article_populated: () => Helpers.axios({ url: '/article/get/populated' }),
    get_article_token: ({ p_token_article }) => Helpers.axios({ url: '/article/get/token/' + p_token_article }),
    get_article_id: ({ p_id_article }) => Helpers.axios({ url: '/article/get/id/' + p_id_article }),
    article_insert_update: (data = {}) => Helpers.axios({ url: '/article/insert/update', method: 'POST', data }),
    article_delete: (data = {}) => Helpers.axios({ url: '/article/delete', method: 'POST', data })
  },
  event: {
    get_event: ({ p_paginate = 0, p_filter_text = '', p_slug_category = '' } = {}) => Helpers.axios({ url: '/event/get/all/', method: 'POST', data: { p_paginate, p_filter_text, p_slug_category } }),
    get_event_populated: () => Helpers.axios({ url: '/event/get/populated' }),
    get_event_token: ({ p_token_event }) => Helpers.axios({ url: '/event/get/token/' + p_token_event }),
    get_event_id: ({ p_id_event }) => Helpers.axios({ url: '/event/get/id/' + p_id_event }),
    event_insert_update: (data = {}) => Helpers.axios({ url: '/event/insert/update', method: 'POST', data }),
    event_delete: (data = {}) => Helpers.axios({ url: '/event/delete', method: 'POST', data }),
    event_main_update: (data = {}) => Helpers.axios({ url: '/event/main/update', method: 'POST', data }),
  },
  noti: {
    get_noti: ({ p_paginate = 0, p_filter_text = '', p_slug_category = '' } = {}) => Helpers.axios({ url: '/noti/get/all/', method: 'POST', data: { p_paginate, p_filter_text, p_slug_category } }),
    get_noti_main: (data = {}) => Helpers.axios({ url: '/noti/get/main/all', data }),
    get_noti_populated: () => Helpers.axios({ url: '/noti/get/populated' }),
    get_noti_token: ({ p_token_noti }) => Helpers.axios({ url: '/noti/get/token/' + p_token_noti }),
    get_noti_id: ({ p_id_noti }) => Helpers.axios({ url: '/noti/get/id/' + p_id_noti }),
    noti_insert_update: (data = {}) => Helpers.axios({ url: '/noti/insert/update', method: 'POST', data }),
    noti_delete: (data = {}) => Helpers.axios({ url: '/noti/delete', method: 'POST', data }),
    noti_main_update: (data = {}) => Helpers.axios({ url: '/noti/main/update', method: 'POST', data }),
  },
  images: {
    get_images: () => Helpers.axios({ url: '/images/get/all' }),
    get_images_id: ({ p_id_images }) => Helpers.axios({ url: '/images/get/id/' + p_id_images }),
    images_insert_update: (data = {}) => Helpers.axios({ url: '/images/insert/update', method: 'POST', data, hasFile: true }),
    images_delete: (data = {}) => Helpers.axios({ url: '/images/delete', method: 'POST', data })
  },
  comment: {
    get_comment: ({ p_id_article }) => Helpers.axios({ url: '/comment/get/all/' + p_id_article }),
    comment_insert_update: (data = {}) => Helpers.axios({ url: '/comment/insert/update', method: 'POST', data }),
    get_comment_event: ({ p_id_event }) => Helpers.axios({ url: '/comment/event/get/all/' + p_id_event }),
    comment_event_insert_update: (data = {}) => Helpers.axios({ url: '/comment/event/insert/update', method: 'POST', data }),
    get_comment_foro: ({ p_token_foro }) => Helpers.axios({ url: '/comment/foro/get/all/' + p_token_foro }),
    comment_foro_insert_update: (data = {}) => Helpers.axios({ url: '/comment/foro/insert/update', method: 'POST', data }),
    get_comment_video: ({ p_token_video }) => Helpers.axios({ url: '/comment/video/get/all/' + p_token_video }),
    comment_video_insert_update: (data = {}) => Helpers.axios({ url: '/comment/video/insert/update', method: 'POST', data }),
    get_comment_noti: ({ p_id_noti }) => Helpers.axios({ url: '/comment/noti/get/all/' + p_id_noti }),
    comment_noti_insert_update: (data = {}) => Helpers.axios({ url: '/comment/noti/insert/update', method: 'POST', data }),
  },
  text: {
    get_mision_vision: () => Helpers.axios({ url: '/mision/vision/get/all' }),
    mision_vision_update: (data = {}) => Helpers.axios({ url: '/mision/vision/update', method: 'POST', data }),
    get_porra: () => Helpers.axios({ url: '/porra/get/all' }),
    porra_update: (data = {}) => Helpers.axios({ url: '/porra/update', method: 'POST', data }),
    get_queja: () => Helpers.axios({ url: '/queja/get/all' }),
    queja_update: (data = {}) => Helpers.axios({ url: '/queja/update', method: 'POST', data }),
    get_yo_soy: () => Helpers.axios({ url: '/yosoy/get/all' }),
    yo_soy_update: (data = {}) => Helpers.axios({ url: '/yosoy/update', method: 'POST', data }),
    get_standard_seller: () => Helpers.axios({ url: '/standardseller/get/all' }),
    standard_seller_update: (data = {}) => Helpers.axios({ url: '/standardseller/update', method: 'POST', data }),
    get_organigrama: () => Helpers.axios({ url: '/organigrama/get/all' }),
    organigrama_update: (data = {}) => Helpers.axios({ url: '/organigrama/update', method: 'POST', data }),
    get_privacidad: () => Helpers.axios({ url: '/privacidad/get/all' }),
    privacidad_update: (data = {}) => Helpers.axios({ url: '/privacidad/update', method: 'POST', data }),
  },
  job: {
    get_job: () => Helpers.axios({ url: '/job/get/all' }),
    get_job_id: ({ p_id_job }) => Helpers.axios({ url: '/job/get/id/' + p_id_job }),
    job_insert_update: (data = {}) => Helpers.axios({ url: '/job/insert/update', method: 'POST', data }),
    job_delete: (data = {}) => Helpers.axios({ url: '/job/delete', method: 'POST', data })
  },
  branch_office: {
    get_branch_office: () => Helpers.axios({ url: '/branchoffice/get/all' }),
    get_branch_office_id: ({ p_id_branch_office }) => Helpers.axios({ url: '/branchoffice/get/id/' + p_id_branch_office }),
    branch_office_insert_update: (data = {}) => Helpers.axios({ url: '/branchoffice/insert/update', method: 'POST', data }),
    branch_office_delete: (data = {}) => Helpers.axios({ url: '/branchoffice/delete', method: 'POST', data })
  },
  product: {
    get_product: () => Helpers.axios({ url: '/product/get/all' }),
    get_product_seller: () => Helpers.axios({ url: '/product/get/seller/all' }),
    get_product_id: ({ p_id_product }) => Helpers.axios({ url: '/product/get/id/' + p_id_product }),
    product_insert_update: (data = {}) => Helpers.axios({ url: '/product/insert/update', method: 'POST', data }),
    product_delete: (data = {}) => Helpers.axios({ url: '/product/delete', method: 'POST', data })
  },
  foro: {
    get_foro: (data = {}) => Helpers.axios({ url: '/foro/get/all', method: 'POST', data }),
    get_foro_main: (data = {}) => Helpers.axios({ url: '/foro/get/main/all', data }),
    get_foro_id: ({ p_id_foro }) => Helpers.axios({ url: '/foro/get/id/' + p_id_foro }),
    get_foro_token: ({ p_token_foro }) => Helpers.axios({ url: '/foro/get/token/' + p_token_foro }),
    foro_insert_update: (data = {}) => Helpers.axios({ url: '/foro/insert/update', method: 'POST', data, hasFile: true }),
    foro_main_update: (data = {}) => Helpers.axios({ url: '/foro/main/update', method: 'POST', data }),
    foro_delete: (data = {}) => Helpers.axios({ url: '/foro/delete', method: 'POST', data })
  },
  video: {
    get_video: () => Helpers.axios({ url: '/video/get/all' }),
    get_video_related: ({ p_token_video }) => Helpers.axios({ url: '/video/get/related/all/' + p_token_video }),
    get_video_public: (data = {}) => Helpers.axios({ url: '/video/get/all/public', method: 'POST', data }),
    get_video_id: ({ p_id_video }) => Helpers.axios({ url: '/video/get/id/' + p_id_video }),
    get_video_token: ({ p_token_video }) => Helpers.axios({ url: '/video/get/token/' + p_token_video }),
    video_insert_update: ({ data = {}, onUploadProgress = () => {} } = {}) => Helpers.axios({ url: '/video/insert/update', method: 'POST', data, hasFile: true, onUploadProgress }),
    video_delete: (data = {}) => Helpers.axios({ url: '/video/delete', method: 'POST', data }),
    get_video_additional: ({ p_id_video } = {}) => Helpers.axios({ url: '/videoadditional/get/all/' + p_id_video }),
    get_video_additional_id: ({ p_id_video_additional }) => Helpers.axios({ url: '/videoadditional/get/id/' + p_id_video_additional }),
    video_additional_insert_update: (data = {}) => Helpers.axios({ url: '/videoadditional/insert/update', method: 'POST', data, hasFile: true }),
    video_additional_delete: (data = {}) => Helpers.axios({ url: '/videoadditional/delete', method: 'POST', data })
  },
  course: {
    get_course: () => Helpers.axios({ url: '/course/get/all' }),
    get_course_public: (data) => Helpers.axios({ url: '/course/get/all/public', data, method: 'POST' }),
    get_course_id: ({ p_id_course }) => Helpers.axios({ url: '/course/get/id/' + p_id_course }),
    course_insert_update: (data = {}) => Helpers.axios({ url: '/course/insert/update', method: 'POST', data }),
    course_delete: (data = {}) => Helpers.axios({ url: '/course/delete', method: 'POST', data })
  },
  evaluation: {
    evaluation_insert_update: (data = {}) => Helpers.axios({ url: '/evaluation/insert/update', method: 'POST', data }),
    reporte_evalution_by_customers: ({ p_id_customers }) => Helpers.axios({ url: '/evaluation/excel/' + p_id_customers }),
  },
  bitacora: {
    get_bitacora_customers: ({ p_id_customers = '' } = {}) => Helpers.axios({ url: '/bitacora/get/customers/' + p_id_customers }),
    bitacora_insert_update: (data = {}) => Helpers.axios({ url: '/bitacora/insert/update', method: 'POST', data })
  },
  notification: {
    get_notification_customers: () => Helpers.axios({ url: '/notification/get/all' }),
    notification_customers_insert: (data = {}) => Helpers.axios({ url: '/notification/customers/insert', method: 'POST', data }),
    get_notification_exists: _ => Helpers.axios({ url: '/notification/exists' })
  },
  incidence: {
    get_incidence: () => Helpers.axios({ url: '/incidence/get/all' }),
    get_incidence_id: ({ p_id_incidence }) => Helpers.axios({ url: '/incidence/get/id/' + p_id_incidence }),
    incidence_insert_update: (data = {}) => Helpers.axios({ url: '/incidence/insert/update', method: 'POST', data }),
    incidence_delete: (data = {}) => Helpers.axios({ url: '/incidence/delete', method: 'POST', data })
  },
  period: {
    get_period: () => Helpers.axios({ url: '/period/get/all' }),
    get_period_id: ({ p_id_period }) => Helpers.axios({ url: '/period/get/id/' + p_id_period }),
    period_insert_update: (data = {}) => Helpers.axios({ url: '/period/insert/update', method: 'POST', data }),
    period_delete: (data = {}) => Helpers.axios({ url: '/period/delete', method: 'POST', data })
  },
  incidence: {
    get_incidence: () => Helpers.axios({ url: '/incidence/get/all' }),
    get_incidence_id: ({ p_id_incidence }) => Helpers.axios({ url: '/incidence/get/id/' + p_id_incidence }),
    incidence_insert_update: (data = {}) => Helpers.axios({ url: '/incidence/insert/update', method: 'POST', data }),
    incidence_delete: (data = {}) => Helpers.axios({ url: '/incidence/delete', method: 'POST', data }),
    incidence_comment_insert: (data = {}) => Helpers.axios({ url: '/incidence/comment/insert', method: 'POST', data }),
    get_incidence_comment: ({ p_id_incidence }) => Helpers.axios({ url: '/incidence/get/comment/' + p_id_incidence }),
    incidence_close: (data = {}) => Helpers.axios({ url: '/incidence/close', method: 'POST', data }),
  },
  dashboard: {
    get_searching: ({ p_keyword }) => Helpers.axios({ url: '/searching/' + p_keyword }),
  },
  category_product: {
    get_category_product: () => Helpers.axios({ url: '/categoryproduct/get/all' }),
    get_category_product_id: ({ p_id_category_product }) => Helpers.axios({ url: '/categoryproduct/get/id/' + p_id_category_product }),
    category_product_insert_update: (data = {}) => Helpers.axios({ url: '/categoryproduct/insert/update', method: 'POST', data }),
    category_product_delete: (data = {}) => Helpers.axios({ url: '/categoryproduct/delete', method: 'POST', data })
  },
  sale: {
    get_sale: () => Helpers.axios({ url: '/sale/get/all' }),
    get_sale_dashboard: () => Helpers.axios({ url: '/sale/get/dashboard' }),
    get_sale_id: ({ p_id_sale }) => Helpers.axios({ url: '/sale/get/id/' + p_id_sale }),
    sale_insert_update: (data = {}) => Helpers.axios({ url: '/sale/insert/update', method: 'POST', data, hasFile: true  }),
    sale_delete: (data = {}) => Helpers.axios({ url: '/sale/delete', method: 'POST', data})
  },
}
export default Controllers;