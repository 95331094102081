import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, CheckBox } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import JoditEditor from "jodit-react";
import ImagesModal from '../Images/ImagesModal'
import { useHistory } from 'react-router-dom'

const NotiForm = ({ data }) => {
    const [row, setRow] = useState({
        p_id_noti: data.id_noti,
        p_name_noti: data.name_noti,
        p_state_noti: data.state_noti,
        p_visibility_noti: data.visibility_noti,
        p_date_noti: data.date_noti,
        p_categories: data.categories,
        p_id_images: data.id_images
    })

    const [isFileImage, setIsFileImage] = useState(false)
    const editor = useRef(null)
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [ready, setReady] = useState(false)
    const [categories, setCategories] = useState([])
    const [tags, setTags] = useState([])
    const [content, setContent] = useState('')
    const [modalShow, setModalShow] = useState(false)
    const hideModalImages = _ => setModalShow(false)

    const history = useHistory()

    const dataSubmit = useCallback(() => {
        return {
            ...row,
            p_state_noti: 1,
            p_content_noti: content,
            p_id_images: row.p_id_images?.id_images
        }
    }, [row, content])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.noti.noti_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                history.push(Helpers.config.convertUrl({ url: '/noti-historico/' }))
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
        })
    }

    const contentHTML = useMemo(() => {
        return <>
            <JoditEditor
                ref={editor}
                value={content}
                config={{
                    minHeight: 600
                }}
                tabIndex={1}
                onBlur={e => {
                    setContent(editor.current.value)
                }}
            />
        </>
    }, [editor, content])

    const handleAddFileImage = _ => {
        setIsFileImage(true)
        setModalShow(true)
    }

    useEffect(() => {
        let promises = [
            Controllers.category.get_category({
                p_state_category: 1
            }),
            Controllers.tag.get_tag()
        ]
        Promise.all(promises).then(res => {
            setCategories(res[0].data)
            setTags(res[1].data)
            setReady(true)
        })
    }, [])

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
        <FormCustom viewSubmit={false} dataSubmit={dataSubmit()} onSubmit={({ data }) => {
            if (!data.p_content_noti) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, ingresar contenido', toast })
                return 
            }
            if (data.p_categories.length == 0) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar Departamento', toast })
                return 
            }
            if (!data.p_id_images) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar imagen destacada', toast })
                return 
            }
            handleInsertUpdate({ data })
        }} loadSubmit={loadSubmit}>
           <div className="col-12 col-md-8">
                <Card className="mb-4">
                    <Card.Body>
                    <Input
                        text="Nombre de la Noti Súper"
                        placeholder="Nombre de la Noti Súper"
                        classNameParent=""
                        required
                        invalid="El campo es obligatorio."
                        defaultValue={row.p_name_noti}
                        onChange={e => setRow({ ...row, p_name_noti: e.currentTarget.value })}
                        maxLength={255}
                    />
                    </Card.Body>
                </Card>
                <Card className="mb-4">
                    <Card.Body>
                        <Button className="mb-2" onClick={_ => {
                            setModalShow(true)
                            setIsFileImage(false)
                        }}>
                            <i className="fa fa-images mr-1"></i>Buscar Imagen
                        </Button>
                        {contentHTML}
                    </Card.Body>
                </Card>
           </div>
           <div className="col-12 col-md-4">
                <Card className="mb-4">
                    <Card.Header>
                        Publicar
                    </Card.Header>
                    <Card.Body>
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-2">
                                <i className="fa fa-key mr-2" style={{
                                    minWidth: '10px'
                                }}></i>
                                <span className="mr-2">Estado:</span>
                                <strong>{parseInt(data.state_noti) === 1 ? 'Publicado' : 'En Creación'}</strong>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <i className="fa fa-eye mr-2" style={{
                                    minWidth: '10px'
                                }}></i>
                                <span className="mr-2">Visibilidad:</span>
                                <strong>Público</strong>
                            </div>
                            <div className="d-flex align-items-center mb-4">
                                <i className="fa fa-calendar-alt mr-2" style={{
                                    minWidth: '10px'
                                }}></i>
                                <span className="mr-2" style={{ minWidth: '90px' }}>Publicado el:</span>
                                <strong>{parseInt(data.state_noti) === 1 ? '10 Diciembre 2020 a las 11:44' : 'Pendiente'}</strong>
                            </div>
                            <Button variant="primary btn-gradient-primary" type="submit">
                                <i className="fa fa-plus mr-1"></i>Registrar
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
                <Card className="mb-4">
                    <Card.Header>
                        Departamento
                    </Card.Header>
                    <Card.Body>
                        {React.Children.toArray(categories.map(c => {
                            return <CheckBox
                                id={'chk-category-' + c.id_category}
                                text={c.name_category}
                                checked={row.p_categories.some(cc => parseInt(cc.id_category) === parseInt(c.id_category))}
                                onChange={e => {
                                    if (e.currentTarget.checked) {
                                        setRow({ ...row, p_categories: [ c ] })
                                    } else {
                                        setRow({ ...row, p_categories: row.p_categories.filter(cc => parseInt(cc.id_category) !== parseInt(c.id_category)) })
                                    }
                                }}
                            />
                        }))}
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>
                        Imagen Destacada
                    </Card.Header>
                    <Card.Body>
                        {row.p_id_images?.file_images ? <img className="img-thumbnail hover-effect cur-pointer" onClick={handleAddFileImage} src={Helpers.config.resourcesUrl({ url: row.p_id_images.file_images })} width="150" /> : <Button variant="link" className="cur-pointer text-" onClick={handleAddFileImage}>Sin imagen destacada</Button>}
                    </Card.Body>
                </Card>
           </div>
        </FormCustom>
        <ImagesModal modalShow={modalShow} handleHideModalForm={hideModalImages} addImage={l => {
            if (isFileImage) {
                setRow({ ...row, p_id_images: l })
            } else {
                setContent(content + `<img src="${Helpers.config.resourcesUrl({ url: l.file_images })}" width="150" alt="${l.alt_images}" />`)
            }
        }} />
    </ReactPlaceholder>
}

NotiForm.defaultProps = {
    data: {
       id_noti: '',
       content_noti: '',
       state_noti: '0',
       visibility_noti: '1',
       date_noti: '',
       categories: [],
       id_images: {}
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default NotiForm;