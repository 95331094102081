import React, { useState } from 'react'
import { Input, FormCustom, SwitchToggle } from '../../Components/Form/Form'

const CategoryProductForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_category_product: data.id_category_product,
        p_name_category_product: data.name_category_product,
        p_state_category_product: data.state_category_product
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Descripción"
            placeholder="Descripción"
            classNameParent="col-12 mt-2"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.name_category_product}
            onChange={e => setRow({ ...row, p_name_category_product: e.currentTarget.value })}
            maxLength={150}
        />
        <SwitchToggle
            classNameParent="col-12 mb-2"
            id="category_product_state"
            defaultChecked={parseInt(row.p_state_category_product) === 1}
            onChange={e => setRow({ ...row, p_state_category_product: e.currentTarget.checked ? 1 : 0 })}
        />
    </FormCustom>
}

CategoryProductForm.defaultProps = {
    data: {
        id_category_product: '',
        name_category_product: '',
        state_category_product: 1,
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default CategoryProductForm;