import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import VideoForm from './VideoForm'
import VideoAdditional from './VideoAdditional'
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'

const Video = ({ nameSingle, namePlural, separator }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [dataUpdated, setDataUpdated] = useState({})
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [loadSubmitDelete, setLoadSubmitDelete] = useState(false)
    const [dataDeleted, setDataDeleted] = useState({})

    const [modalTitleFile, setModalTitleFile] = useState('');
    const [modalShowFile, setModalShowFile] = useState(false);
    const [loadSubmitFile, setLoadSubmitFile] = useState(false)
    const [dataUpdatedFile, setDataUpdatedFile] = useState({})
    const [percentageUpload, setPercentageUpload] = useState(0)

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers.video.get_video().then(res => {
                setRows(res.data)
            }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setIsProccesing(false)
                setReload(false)
            })
        }
    }, [reload])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.video.video_insert_update({
            data,
            onUploadProgress: ({ currentProgress }) => {
                setDataUpdated({ ...dataUpdated, percentageUpload: currentProgress })
            }
        }).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setModalShow(false)
                setDataUpdated({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
            setReload(true)
            setDataUpdated({ ...dataUpdated, percentageUpload: 0 })
        })
    }

    const handleGet = e => {
        let $this = e.currentTarget

        if (!$this.dataset?.idVideo) {
            setModalTitle(`Registrar ${nameSingle}`)
            setDataUpdated({
                percentageUpload
            })
            setModalShow(true);
            return
        }

        let buttonHTML = $this.innerHTML;
        $this.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'
        let { idVideo } = $this.dataset

        Controllers.video.get_video_id({ p_id_video: idVideo }).then(res => {
            setModalTitle(`Modificar ${nameSingle} #${idVideo}`)
            setDataUpdated({ data: res.data, percentageUpload })
            setModalShow(true);
        }).catch(req => {
            Helpers.promise.catch({ req, toast })
            setReload(true)
        })
        .finally(() => $this.innerHTML = buttonHTML)
    }

    const handleDelete = () => {
        setLoadSubmitDelete(true)

        Controllers.video.video_delete(dataDeleted).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setShowConfirmDelete(false)
                setDataDeleted({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setReload(true)
            setLoadSubmitDelete(false)
        })
    }

    const handleHideModalForm = () => {
        setModalShow(false)
        setLoadSubmit(false)
    }

    const handleShowModalDelete = e => {
        let { idVideo, nameVideo } = e.currentTarget.dataset
        setDataDeleted({
            p_id_video: idVideo,
            p_name_video: nameVideo
        })
        setShowConfirmDelete(true)
    }

    const handleHideModalDelete = () => setShowConfirmDelete(false)

    const addContentAdditional = e => {
        let { idVideo } = e.currentTarget.dataset
        setDataUpdatedFile({ idVideo })
        setModalShowFile(true)
        setModalTitleFile(<><i className="fa fa-plus mr-1"></i>Agregar Material Adicional a Video # {idVideo}</>)
    }

    const handleHideModalFormFile = () => {
        setModalShowFile(false)
        setLoadSubmitFile(false)
    }


    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_video', text: 'ID', align: 'center' },
                { name: 'name_video', text: 'Descripción' },
                { name: 'file_images', text: 'Imagen Destacada', align: 'center', render: r => <img className="img-thumbnail" src={Helpers.config.resourcesUrl({ url: r.file_images })} height="100" width="100" /> },
                /* { name: 'name_category', text: 'Departamento' }, */
                { name: 'status_video', text: 'Estado', align: 'center', render: r => <div className={'badge badge-' + (parseInt(r.status_video) === 1 ? 'success' : 'danger')}>
                    {parseInt(r.status_video) === 1 ? 'Activo' : 'Inactivo'}
                </div> },
                { name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => {
                    return <div>
                        <Button className="mr-1" size="xs" data-id-video={u.id_video} onClick={handleGet}>
                            <i className="fa fa-edit"></i>
                        </Button>
                        <Button variant="danger mr-1" size="xs" data-id-video={u.id_video} data-name-video={u.name_video} onClick={handleShowModalDelete}>
                            <i className="fa fa-trash"></i>
                        </Button>
                        <Button variant="dark" size="xs" data-id-video={u.id_video} onClick={addContentAdditional}>
                            <i className="fa fa-file"></i>
                        </Button>
                    </div>
                } },
            ],
        ]}
        rows={rows}
        isProcessing={isProcessing}
    />, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="d-flex justify-content-between bg-danger text-white">
                <span>Listado De {namePlural}</span>
                <Button size="sm" onClick={handleGet}>
                    <i className="fa fa-plus mr-1"></i> Registrar {nameSingle}
                </Button>
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
        <Modal show={modalShow} onHide={handleHideModalForm} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <VideoForm onSubmit={handleInsertUpdate} loadSubmit={loadSubmit} {...dataUpdated} />
            </Modal.Body>
        </Modal>
        <Modal show={modalShowFile} onHide={handleHideModalFormFile} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitleFile}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <VideoAdditional nameSingle="Material Adicional" namePlural="Materiales Adicionales" separator="el"  loadSubmit={loadSubmitFile} {...dataUpdatedFile} />
            </Modal.Body>
        </Modal>
        <SweetAlert
            icon="delete"
            show={showConfirmDelete}
            showCancelButton
            title={<>¿Desea eliminar {separator} {nameSingle}?</>}
            subtitle={<>#{dataDeleted.p_id_video} - {dataDeleted.p_name_video}</>}
            confirmButtonText="¡Sí, eliminar!"
            cancelButtonText="¡No!"
            loadSubmit={loadSubmitDelete}
            onCancel={handleHideModalDelete}
            onConfirm={handleDelete}
        />
    </>
}

export default Video