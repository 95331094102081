import React from 'react'
import ArticleForm from './ArticleForm'
import Card from 'react-bootstrap/Card';

const Article = () => {
    return <>
        <Card>
            <Card.Header className="bg-danger text-white">
                Nuevo Blog
            </Card.Header>
            <Card.Body className="bg-secondary">
                <ArticleForm />
            </Card.Body>
        </Card>
    </>
}

export default Article