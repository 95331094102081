import React, { useState } from 'react'
import { Input, FormCustom, SwitchToggle } from '../../Components/Form/Form'

const CategoryForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_category: data.id_category,
        p_name_category: data.name_category,
        p_slug_category: data.slug_category,
        p_state_category: data.state_category
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Descripción"
            placeholder="Descripción"
            classNameParent="col-12 mt-2"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.name_category}
            onChange={e => setRow({ ...row, p_name_category: e.currentTarget.value })}
            maxLength={150}
        />
        <Input
            text="Slug"
            placeholder="Slug"
            classNameParent="col-12 mt-2 mb-2"
            required
            invalid="Error en formato. Ej: recursos-humanos"
            defaultValue={data.slug_category}
            onChange={e => setRow({ ...row, p_slug_category: e.currentTarget.value })}
            maxLength={150}
            pattern="[a-z-]{1,150}"
        />
        <SwitchToggle
            classNameParent="col-12 mb-2"
            id="category_state"
            defaultChecked={parseInt(row.p_state_category) === 1}
            onChange={e => setRow({ ...row, p_state_category: e.currentTarget.checked ? 1 : 0 })}
        />
    </FormCustom>
}

CategoryForm.defaultProps = {
    data: {
        id_category: '',
        name_category: '',
        slug_category: '',
        state_category: 1,
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default CategoryForm;