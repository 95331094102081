import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import CustomersForm from './CustomersForm'
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import { FormCustom, Input, Select } from '../../Components/Form/Form';
import ReactPlaceholder from 'react-placeholder/lib';
import { useParams } from 'react-router-dom';

const Customers = () => {
    const { idCustomers } = useParams()
    const [data, setData] = useState({})
    const [ready, setReady] = useState(false)

    useEffect(() => {
        if (idCustomers) {
            Controllers.customers.get_customers_id({
                p_id_customers: idCustomers
            }).then(res => {
                setData(res.data)
                setReady(true)
            })
        } else {
            setData({})
            setReady(true)
        }
    }, [idCustomers])

    return <>
        <Card>
            <Card.Body>
                <ReactPlaceholder ready={ready} showLoadingAnimation rows={20}>
                    <CustomersForm {...(data?.id_customers ? { data } : {})} />
                </ReactPlaceholder>
            </Card.Body>
        </Card>
    </>
}

export default Customers