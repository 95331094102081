import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'

const TextArea = ({ defaultValue, value, onChange, classNameParent, className, text, size, icon, append, invalid, smallColor, ...props }) => {
  const propInput = useCallback(() => {
    return defaultValue ? { defaultValue } : { value }
  }, [defaultValue, value])

  const refInput = useRef(null)
  const [floating, setFloating] = useState((defaultValue !== '' && defaultValue !== undefined) || (value !== '' && value !== undefined))

  return (<div className={classNameParent}>
    {/* <small className="text-muted">{text}</small> */}
    <div className={'input-group input-group-' + size}>
      {/* icon ? <div className="input-group-prepend">
        <div className="input-group-text"><i className={icon}></i></div>
      </div> : '' */}
      <textarea {...props} onFocus={_ => setFloating(true)} onBlur={_ => setFloating(refInput.current?.value !== '')} {...propInput()} onChange={onChange} ref={refInput} className={'form-control ' + className} placeholder="" />
      <small className={smallColor + ' textarea-floating-label ' + (floating || refInput.current?.value ? 'up-text' : '')}>{text}</small>
      <div className="input-group-append">
        {React.Children.toArray(append)}
      </div>
      <div className="invalid-feedback">
        {invalid}
      </div>
    </div>
  </div>)
}
TextArea.defaultProps = {
  size: 'sm',
  icon: '',
  classNameParent: 'col-12',
  required: false,
  append: [],
  className: '',
  onChange: () => {},
  smallColor: 'text-muted',
  invalid: ''
}
TextArea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  classNameParent: PropTypes.string,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  append: PropTypes.array,
  maxLength: PropTypes.number,
  onChange: PropTypes.func
}
export default TextArea