import React, { useState, useMemo } from 'react'
import { useEffect } from 'react'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, SwitchToggle, Select } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/esm/Button'
import ImagesModal from '../Images/ImagesModal'

const ProductForm = ({ data, onSubmit, disablebSubmit, loadSubmit, showPreview }) => {
    const [row, setRow] = useState({
        p_id_product: data.id_product,
        p_code_product: data.code_product,
        p_name_product: data.name_product,
        p_presentation_product: data.presentation_product,
        p_observation_product: data.observation_product,
        p_price_product: data.price_product,
        p_id_branch_office: data.id_branch_office,
        p_state_product: data.state_product,
        p_main_product: data.main_product,
        p_id_category: data.id_category,
        p_id_images: data.id_images
    })

    const [ready, setReady] = useState(false)
    const [branchOffices, setBranchOffices] = useState([])
    const [categories, setCategories] = useState([])
    const [imageVideo, setImageVideo] = useState(isNaN(parseInt(data.id_product)) ? '' : {
        id_images: data.id_images,
        file_images: data.file_images
    })
    const [modalShow, setModalShow] = useState(false)
    
    useEffect(() => {
        Promise.all([
            Controllers.branch_office.get_branch_office(),
            Controllers.category_product.get_category_product()
        ]).then(res => {
            setBranchOffices(res[0].data)
            setCategories(res[1].data)
            setReady(true)
        })
    }, [])

    const branchOffice = useMemo(() => {
        if (row.p_id_branch_office === '') {
            return
        }
        
        let finded = branchOffices.find(j => parseInt(j.id_branch_office) === parseInt(row.p_id_branch_office))

        if (!finded) {
            return
        }

        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [row.p_id_branch_office, branchOffices])

    const category = useMemo(() => {
        if (row.p_id_category === '') {
            return
        }
        
        let finded = categories.find(j => parseInt(j.id_category_product) === parseInt(row.p_id_category))

        if (!finded) {
            return
        }

        return {
            label: finded.name_category_product,
            value: finded.id_category_product
        }
    }, [row.p_id_category, categories])

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
        <FormCustom dataSubmit={row} onSubmit={({ data }) => {
                if (showPreview) {
                    return
                }
                if (!row.p_id_branch_office) {
                    Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar sucursal.', toast })
                    return
                }
                if (!row.p_id_category) {
                    Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar categoría.', toast })
                    return
                }
                if (!row.p_id_images) {
                    Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar imagen.', toast })
                    return
                }
                onSubmit({ data })
            }} disablebSubmit={showPreview} viewSubmit={!showPreview} loadSubmit={loadSubmit}>
            <Input
                text="Código"
                placeholder="Código"
                classNameParent="col-12 mt-2 mb-2"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.code_product}
                onChange={e => setRow({ ...row, p_code_product: e.currentTarget.value })}
                maxLength={100}
                disabled={showPreview}
            />
            <Select
                text="Sucursal"
                classNameParent="col-12 mb-2"
                options={branchOffices.map(j => {
                    return {
                        label: j.name_branch_office,
                        value: j.id_branch_office
                    }
                })}
                value={branchOffice}
                onChange={e => setRow({ ...row, p_id_branch_office: e.value })}
                disabled={showPreview}
            />
            <Select
                text="Categoría"
                classNameParent="col-12 mb-4"
                options={categories.map(j => {
                    return {
                        label: j.name_category_product,
                        value: j.id_category_product
                    }
                })}
                value={category}
                onChange={e => setRow({ ...row, p_id_category: e.value })}
                disabled={showPreview}
            />
            <Input
                text="Descripción"
                placeholder="Descripción"
                classNameParent="col-12 mt-2 mb-2"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.name_product}
                onChange={e => setRow({ ...row, p_name_product: e.currentTarget.value })}
                maxLength={255}
                disabled={showPreview}
            />
            <Input
                text="Presentación"
                placeholder="Presentación"
                classNameParent="col-12 mt-2 mb-2"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.presentation_product}
                onChange={e => setRow({ ...row, p_presentation_product: e.currentTarget.value })}
                maxLength={150}
                disabled={showPreview}
            />
            <Input
                text="Observación"
                placeholder="Observación"
                classNameParent="col-12 mt-2 mb-2"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.observation_product}
                onChange={e => setRow({ ...row, p_observation_product: e.currentTarget.value })}
                maxLength={255}
                disabled={showPreview}
            />
            <Input
                type="number"
                min="0.10"
                step="0.10"
                text="Monto de Venta"
                placeholder="Monto de Venta"
                classNameParent="col-12 mt-2 mb-2"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.price_product}
                onChange={e => setRow({ ...row, p_price_product: e.currentTarget.value })}
                maxLength={255}
                disabled={showPreview}
            />
            {showPreview ? '' : 
            <div className="col-12 d-flex flex-column mb-2">
                <img className="img-thumbnail mb-2" src={Helpers.config.resourcesUrl({ url: imageVideo ? imageVideo.file_images : '/images/dashboard/sin_imagen.png' })} width="150" />
                <Button onClick={_ => setModalShow(true)} block>
                    <i className="fa fa-search mr-1"></i>Seleccionar Imagen
                </Button>
            </div>}
            <SwitchToggle
                classNameParent="col-12 mb-2"
                id="product_state"
                defaultChecked={parseInt(row.p_state_product) === 1}
                onChange={e => setRow({ ...row, p_state_product: e.currentTarget.checked ? 1 : 0 })}
                disabled={showPreview}
            />
            {showPreview ? '' : <SwitchToggle
                classNameParent="col-12 mb-2"
                id="product_main"
                textCheck="Mostrar en Escritorio"
                textUnChecked="Oculto en Escritorio"
                defaultChecked={parseInt(row.p_main_product) === 1}
                onChange={e => setRow({ ...row, p_main_product: e.currentTarget.checked ? 1 : 0 })}
            />}
        </FormCustom>
        <ImagesModal modalShow={modalShow} handleHideModalForm={() => setModalShow(false)} addImage={l => {
            setImageVideo(l)
            setRow({ ...row, p_id_images: l.id_images })
            setModalShow(false)
        }} />
    </ReactPlaceholder>
}

ProductForm.defaultProps = {
    data: {
        id_product: '',
        code_product: '',
        name_product: '',
        presentation_product: '',
        observation_product: '',
        price_product: '',
        id_branch_office: '',
        state_product: 1,
        main_product: 0,
        id_category: '',
        id_images: '',
        file_images: ''
    },
    onSubmit: () => {},
    loadSubmit: false,
    showPreview: false
}

export default ProductForm;