import React, { useCallback, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import { useEffect } from 'react';
import { useState } from 'react';
import ReactPlaceholder from 'react-placeholder/lib';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { FormCustom, Input } from '../../Components/Form/Form';
import Button from 'react-bootstrap/esm/Button';
import { useSelector } from 'react-redux';
import CustomersForm from '../Customers/CustomersForm'

const PersonalInformation = () => {
    const store = useSelector(store => store)
    const [ready, setReady] = useState(false)
    const [dataUser, setDataUser] = useState([])
    
    useEffect(() => {
        Controllers.users.get_user_information().then(res => {
            setDataUser(res.data)
            setReady(true)
        })
    }, [])

    return <>
        <div className="form row">
            <div className="col-12 col-md-4">
                <ul className="list-group" style={{ minWidth: 250 }}>
                    <li className="list-group-item text-center">
                        <img src={store.session.file_photo_user} width="100" style={{
                            borderRadius: '100px'
                        }} />
                        <h5 className="text-danger font-weight-bold pt-2">{store.session.full_name}</h5>
                        <p>{store.session.username}</p>
                    </li>
                </ul>
            </div>
            <div className="col-12 col-md-8">
                <ReactPlaceholder ready={ready} rows={20} showLoadingAnimation>
                    <Card>
                        <Card.Body>
                            <CustomersForm data={dataUser} isModifyUser />
                        </Card.Body>
                    </Card>
                </ReactPlaceholder>
            </div>
        </div>
    </>
}

export default PersonalInformation