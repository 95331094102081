import React, { useState, useEffect, useMemo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import Nav from './Styled/Nav'
import Header from './Styled/Header'
import PageWrapper from './Styled/PageWrapper'
import Footer from './Styled/Footer'
import { Link, useHistory } from 'react-router-dom'
import Helpers from '../Helpers/Helpers'
import { useSelector } from 'react-redux'
import Dropdown from 'react-bootstrap/esm/Dropdown'
import Controllers from '../../Api/Controllers'
import { FormCustom, Input } from '../Form/Form'
import Button from 'react-bootstrap/esm/Button'

const CustomToggle = forwardRef(({ children, onClick }, ref) => {
  const store = useSelector(res => res)
  return <Header.Right.Icon ref={ref} onClick={(e) => {
    e.preventDefault()
    onClick(e)
  }}
  >
    <Header.Right.Icon className="far fa-bell" />
    {children}
  </Header.Right.Icon>
})

const Layout = ({ page, type, children, title, showBread, permissions, companyName, folderPath, imageLogo, iconsHeader, resizeNav, subtitle }) => {

  const store = useSelector(res => res)

  const [resize, setResize] = useState(false)
  const [resizeOpen, setResizeOpen] = useState(false)
  const [mobile, setMobile] = useState(false)
  const [navigation, setNavigation] = useState([])
  const [idxActive, setIdxActive] = useState(-1)
  const [touchStartX, setTouchStartX] = useState(0)

  useEffect(() => {
    window.document.title = title + ' - ' + companyName
  }, [title, companyName])

  const [existsNotification, setExistsNotification] = useState([])

  useEffect(() => {
    Controllers.notification.get_notification_exists().then(res => {
      setExistsNotification(res.data)
    })
  }, [])

  useEffect(() => {
    let nav = []
    let i = 0

    permissions.forEach(d => {
      /* nav.push(<Nav.Title resizeOpen={resizeOpen} resize={resize}>
        <span>{d.parent.description}</span>
        <i className="fas fa-ellipsis-h"></i>
      </Nav.Title>) */

      d.childrens.forEach(p => {
        let childs = []
        let itemActive = false

        p.childrens.forEach(ch => {
          if (!itemActive) {
            if (idxActive === -1) {
              if (ch.a_href === page) {
                setIdxActive(i)
              }
            } else {
              if (idxActive === i) {
                itemActive = true
              }
            }
          }

          if (parseInt(ch.show) === 1) {
            childs.push(<Link to={Helpers.config.convertUrl({ url: '/' + ch.a_href })} style={{
              textDecoration: 'none',
              color: 'inherit'
            }}>
              <Nav.SecondLevel.Item active={page === ch.a_href}>
                <Nav.SecondLevel.Title>
                  <Nav.SecondLevel.Icon />
                  <Nav.SecondLevel.Description resizeOpen={resizeOpen} resize={resize}>{ch.description}</Nav.SecondLevel.Description>
                </Nav.SecondLevel.Title>
              </Nav.SecondLevel.Item>
            </Link>)
          }
        })

        nav.push(<Nav.OneLevel.Item resizeOpen={resizeOpen} resize={resize}>
          <Nav.OneLevel.TitleContent resizeOpen={resizeOpen} resize={resize} data-idx={i} active={itemActive} onClick={e => setIdxActive(idxActive === parseInt(e.currentTarget.dataset.idx) ? -2 : parseInt(e.currentTarget.dataset.idx))}>
            <Nav.OneLevel.Title>
              <Nav.OneLevel.Icon resizeOpen={resizeOpen} resize={resize}>
                {type === 'FontAwesome' ? <i className={p.parent.icon}></i> : ''}
              </Nav.OneLevel.Icon>
              <Nav.OneLevel.Description resizeOpen={resizeOpen} resize={resize}>{p.parent.description}</Nav.OneLevel.Description>
            </Nav.OneLevel.Title>
            {<Nav.OneLevel.IconArrow resizeOpen={resizeOpen} resize={resize} active={itemActive} />}
          </Nav.OneLevel.TitleContent>

          <Nav.SecondLevel active={itemActive} resizeOpen={resizeOpen} resize={resize}>
            {React.Children.toArray(childs)}
          </Nav.SecondLevel>
        </Nav.OneLevel.Item>)

        i++
      })
    })

    if (parseInt(store.session.id_profile) === 1) {
      nav.push(<Nav.OneLevel.Item resizeOpen={resizeOpen} resize={resize}>
        <Nav.SecondLevel resizeOpen={resizeOpen} resize={resize} className="d-block">
          <a href="mailto:buzon@superpapelera.com.mx" style={{
            textDecoration: 'none',
            color: 'inherit'
          }}>
            <Nav.SecondLevel.Item>
              <Nav.SecondLevel.Title>
                <Nav.OneLevel.Icon style={{ color: 'white' }} resizeOpen={resizeOpen} resize={resize}>
                  <i className="fa fa-envelope"></i>
                </Nav.OneLevel.Icon>
                <Nav.SecondLevel.Description resizeOpen={resizeOpen} resize={resize}>Buzón de Correo</Nav.SecondLevel.Description>
              </Nav.SecondLevel.Title>
            </Nav.SecondLevel.Item>
          </a>
        </Nav.SecondLevel>
      </Nav.OneLevel.Item>)
    }
    setNavigation(nav)
  }, [permissions, type, resize, resizeOpen, idxActive, page])

  const breadCrumb = useMemo(() => {
    let bread = {}

    permissions.forEach(d => {
      d.childrens.forEach(p => {
        p.childrens.forEach(ch => {
          if (ch.a_href === page) {
            bread = {
              parent: d.parent.description,
              child: p.parent.description,
              current: ch.description
            }
          }
        })
      })
    })

    return <>
      {/* <PageWrapper.BreadCrumb.Title>{bread.parent}</PageWrapper.BreadCrumb.Title>
      <PageWrapper.BreadCrumb.Icon />
      <PageWrapper.BreadCrumb.Title>{bread.child}</PageWrapper.BreadCrumb.Title>
      <PageWrapper.BreadCrumb.Icon />
      <PageWrapper.BreadCrumb.Title active>{bread.current}</PageWrapper.BreadCrumb.Title> */}
    </>
  }, [page, permissions])

  const [notification, setNotification] = useState([])

  useEffect(() => {
    Controllers.notification.get_notification_customers().then(res => {
      setNotification(res.data)
    })
  }, [])

  const [textFilter, setTextFilter] = useState('')
  const history = useHistory()
  const [showForm, setShowForm] = useState(false)

  return (<>
    <Nav.Swipe
      /* onTouchStart={e => setTouchStartX(e.changedTouches[0].screenX)}
      onTouchEnd={e => {
        if (touchStartX < e.changedTouches[0].screenX) {
          setMobile(true)
        }
      }} */ />
    <Nav.Bg mobile={mobile} onClick={() => setMobile(false)}
      /* onTouchStart={e => setTouchStartX(e.changedTouches[0].screenX)}
      onTouchEnd={e => {
        if (touchStartX > e.changedTouches[0].screenX) {
          setMobile(false)
        }
      }} */
    />
    <Nav resizeOpen={resizeOpen} resize={resize} mobile={mobile} /* onMouseEnter={() => setResizeOpen(true)} onMouseLeave={() => setResizeOpen(false)} */>
      <Nav.Brand resize={resize} resizeOpen={resizeOpen}>
        <Nav.Brand.Link href={folderPath + '/'}>
          <Nav.Brand.Logo src={imageLogo} alt="" resize={resize} resizeOpen={resizeOpen} />
        </Nav.Brand.Link>
        {/* <Nav.Brand.IconSize resize={resize} resizeOpen={resizeOpen} onClick={() => setResize(!resize)}>
          {resize ? <i className="far fa-circle" /> : <i className="far fa-dot-circle"></i>}
        </Nav.Brand.IconSize> */}
        <Nav.Brand.IconMobile onClick={() => setMobile(!mobile)}>
          <i className="fa fa-times" />
        </Nav.Brand.IconMobile>
      </Nav.Brand>

      <div className={resize ? 'd-none' : 'd-flex'} style={{
        padding: '20px',
        paddingTop: '120px'
      }}>
        <div>
          <Link to={Helpers.config.convertUrl({ url: '/mi-perfil' })}>
            <img src={store.session.file_photo_user} style={{
              maxWidth: '70px',
              borderRadius: '50px',
              boxShadow: '0 1px 5px 0 rgba(0,0,0,.2)'
            }} />
          </Link>
        </div>
        <Link to={Helpers.config.convertUrl({ url: '/mi-perfil' })} className="d-flex p-2 flex-column">
          <span className="text-white">{store.session.full_name} <i className="fa fa-edit ml-2"></i></span>
          <span className="text-white f-size-11 mt-2">{store.session.name_job}</span>
        </Link>
      </div>

      <Nav.OneLevel>
        {React.Children.toArray(navigation)}
      </Nav.OneLevel>
      <div style={{
        maxWidth: 246,
        marginBottom: 50
      }}>
        <img src={Helpers.config.resourcesUrl({ url: '/images/dashboard/mascota-super-papelera.png' })} style={{
          maxWidth: 250
        }} />
      </div>
    </Nav>

    <Header resize={resize}>
      <Header.Left>
        <Header.Right.Icon className={(resize ? 'far fa-hand-point-right' : 'fas fa fa-tasks') + ' align-self-center d-none d-lg-block'} onClick={() => setResize(!resize)} style={{
          marginLeft: '-12px',
          position: 'relative',
          background: '#201430',
          width: '44px',
          padding: '18px',
          cursor: 'pointer',
          borderRadius: '44px',
          fontSize: '20px'
        }}></Header.Right.Icon>
        <i className={(resize ? 'far fa-hand-point-right' : 'fas fa fa-tasks') + ' align-self-center d-block d-lg-none'} onClick={() => setMobile(!resize)}></i>
        <Dropdown>
          <Dropdown.Toggle variant="success" as={CustomToggle} />
          <Dropdown.Menu>
            <div className="p-3">
              Notificaciones
            </div>
            <div className="d-flex flex-column" style={{
              minWidth: 250,
              overflowY: 'auto',
              maxHeight: 300
            }}>
              {React.Children.toArray(notification.map(n => {
                const handleCheckNotification = _ => {
                  if (parseInt(n.check) !== 1) {
                    setNotification(notification.map(n1 => {
                      if (parseInt(n.id_notification) === parseInt(n1.id_notification)) {
                        n1.check = 1

                        Controllers.notification.notification_customers_insert({
                          p_id_notification: n.id_notification
                        })
                      }
                      return n1
                    }))
                  }
                }

                return <>
                  <div className="d-flex justify-content-between">
                    <Link onClick={handleCheckNotification} className="mb-2" to={Helpers.config.convertUrl({ url: '/' + n.type_notification + '/' + n.token })}>
                      <div className="d-flex pl-3 pr-3">
                        <img src={Helpers.config.resourcesUrl({ url: n.file_images })} width="30" height="30" className="rounded" />
                        <div className="d-flex flex-column pl-2 alig-self-start">
                          <span>
                            <span className="f-size-11 mr-2">{Helpers.string.toCamelCase(n.type_notification)}</span>
                            <strong>{n.name_notification}</strong>
                          </span>
                          <span className="f-size-11">{n.date_created}</span>
                        </div>

                      </div>
                    </Link>
                    <div className="align-self-center pr-3">
                      <i className={'fas fa-check-double ' + (parseInt(n.check) === 1 ? 'text-primary' : 'cur-pointer')} style={{
                        opacity: parseInt(n.check) === 1 ? 1 : .3
                      }} onClick={handleCheckNotification} ></i>
                    </div>
                  </div>
                  <hr className="dropdown-divider" />
                </>
              }))}
            </div>
          </Dropdown.Menu>
        </Dropdown>
        
        <FormCustom viewSubmit={false} className={'w-100 align-self-center form-search-custom ' + (showForm ? 'd-block' : '')} onSubmit={_ => {
          history.push(Helpers.config.convertUrl({ url: '/buscador/' + textFilter  }))
        }}>
          <Input
            classNameParent="col-12 align-self-center"
            text="Buscar"
            className="text-white"
            placeholder="Buscar"
            append={showForm ? [
              <Button type="submit">
                <i className="fa fa-search"></i> Buscar
              </Button>,
              <Button type="button" variant="dark" onClick={_ => setShowForm(false)}>
                <i className="fa fa-times"></i> Cancelar
              </Button>
            ] : [
              <Button type="submit">
                <i className="fa fa-search"></i> Buscar
              </Button>,
            ]}
            marginBottom=""
            required
            smallColor={showForm ? 'text-dark' : 'text-white'}
            hidePlaceholder
          onChange={e => setTextFilter(e.currentTarget.value)}
          />
        </FormCustom>
      </Header.Left>
      <Header.Brand>
        <Nav.Brand.Link href={folderPath + '/'}>
          <Header.Brand.Logo src={imageLogo} alt="" />
        </Nav.Brand.Link>
      </Header.Brand>
      {React.Children.toArray(iconsHeader.map(i => {
        return <><Header.Right>
         
          <Header.Right.Icon className="fa fa-search icon-search-custom" onClick={_ => {
            setShowForm(true)
          }} />
         {i}
        </Header.Right>
          
        </>
      }))}

    </Header>

    <PageWrapper resize={resize}>
      <PageWrapper.BgTop>
        <PageWrapper.Title>
          <span>{title}</span>
          <span className="text-dark">{subtitle}</span>
        </PageWrapper.Title>
      </PageWrapper.BgTop>
      <PageWrapper.Body>
        {existsNotification.length === 0 ? '' : <div className={'alert alert-' + existsNotification?.response}>
          <div className={'mr-2 badge badge-' + existsNotification?.response}>
            Revisa
          </div>
          <span>{existsNotification?.message}</span>
        </div>}
        {children}
      </PageWrapper.Body>
    </PageWrapper>

    {/* <Footer resize={resize}>
      Derechos Reservados Por {companyName}
    </Footer> */}
  </>
  )
}

Layout.defaultProps = {
  type: 'FontAwesome',
  page: '',
  showBread: true,
  permissions: [],
  companyName: '',
  folderPath: '',
  imageLogo: '',
  iconsHeader: [],
  resizeNav: false
}

Layout.propTypes = {
  page: PropTypes.string,
  logo: PropTypes.string,
  type: PropTypes.oneOf(['FontAwesome']),
  title: PropTypes.string,
  title: PropTypes.string,
  showBread: PropTypes.bool,
  permissions: PropTypes.array,
  companyName: PropTypes.string,
  folderPath: PropTypes.string,
  imageLogo: PropTypes.string,
  iconsHeader: PropTypes.array,
  resizeNav: PropTypes.bool
}

export { Layout, Header }