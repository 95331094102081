import React from 'react'
import Card from 'react-bootstrap/Card';
import { useEffect } from 'react';
import { useState } from 'react';
import ReactPlaceholder from 'react-placeholder/lib';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import parse from 'html-react-parser';
import { Link, useHistory, useParams } from 'react-router-dom'
import { FormCustom, Input } from '../../Components/Form/Form';
import Button from 'react-bootstrap/esm/Button';
import ArticleBlog from '../Article/ArticleBlog';

const History = ({ showFull }) => {
    const [readyCategory, setReadyCategory] = useState(false)
    const [readyArticlesPopulates, setReadyArticlesPopulated] = useState(false)
    const [readyArticles, setReadyArticles] = useState(false)
    const [articles, setArticles] = useState([])
    const [articlesPopulated, setArticlesPopulated] = useState([])
    const [categories, setCategories] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [filterText, setFilterText] = useState('')

    const { slugCategory = '', searchFilter = '', paginate = 1 } = useParams()
    const history = useHistory()
    
    useEffect(() => {
        setReadyArticles(false)
        
        Controllers.article.get_article({
            p_slug_category: slugCategory,
            p_filter_text: searchFilter,
            p_paginate: paginate - 1
        }).then(res => {
            setHasMore(res.data.length > 0)
            setArticles(res.data)
            setReadyArticles(true)
        })
    }, [slugCategory, searchFilter, paginate])

    useEffect(() => {
        Controllers.category.get_category_qty_article().then(res => {
            setCategories(res.data)
            setReadyCategory(true)
        })

        Controllers.article.get_article_populated().then(res => {
            setArticlesPopulated(res.data)
            setReadyArticlesPopulated(true)
        })
    }, [])

    const handleSearchArticle = _ => {
        history.push(Helpers.config.convertUrl({ url: '/blog-historico/1/' + filterText }))
    }

    return <>
        <Card>
            <Card.Header className="bg-danger text-white">
                {showFull ? 'Histórico' : 'Tema del Blog'}
            </Card.Header>
            <Card.Body className="form-row">
                <div className="col-12 col-md-8 p-5">
                    {showFull ? <ReactPlaceholder ready={readyArticles} showLoadingAnimation rows={20}>
                        <h2 className=""><strong>Balance de Vida</strong></h2>
                        {searchFilter !== '' ?  <p>
                            Filtrado por: <strong>{searchFilter}</strong>
                        </p> : (slugCategory === '' ? '' : <p>
                            Departamento: <strong>{slugCategory}</strong>
                        </p>)}
                        {articles.length > 0 ? <>
                            {React.Children.toArray(articles.map(a => <>
                                <section className="d-flex pt-5">
                                    {a.file_images ? <Link to={Helpers.config.convertUrl({ url: '/blog/' + a.token_article })}>
                                        <img src={Helpers.config.resourcesUrl({ url: a.file_images })} width="80" height="80" />
                                    </Link> : ''}
                                    <div className="pl-4">
                                        <Link to={Helpers.config.convertUrl({ url: '/blog/' + a.token_article })} className="text-decoration-none">
                                            <h4 className="font-weight-bold">{a.name_article}</h4>
                                        </Link>
                                        <p className="d-flex flex-column flex-md-row justify-content-between mt-3">
                                            <span className="mr-3">
                                                <i className="fa fa-calendar-alt mr-2"></i>
                                                <span>{a.date_article}</span>
                                            </span>
                                            <Link className="mr-3 text-decoration-none" to={Helpers.config.convertUrl({ url: '/categoria/1/' + a.slug_category })}>
                                                <i className="fa fa-folder mr-2"></i>
                                                <span>{a.name_category}</span>
                                            </Link>
                                            <span className="mr-3">
                                                <i className="fa fa-comment mr-2"></i>
                                                <span>{a.qty_comment_article}</span>
                                            </span>
                                        </p>
                                        <div className="mt-3 content_article_preview">
                                            {parse(a.content_article)}
                                        </div>
                                    </div>
                                </section>
                                <hr />
                            </>)) }
                            {hasMore ? <div className="d-flex justify-content-center mt-4">
                                <Link to={Helpers.config.convertUrl({ 
                                    url: slugCategory ? '/categoria/' + (parseInt(paginate)  - 1) + '/' + slugCategory : '/blog-historico/' + (parseInt(paginate)  - 1)
                                })}>
                                    <Button variant={parseInt(paginate) <= 1 ? 'secondary' : 'primary'} disabled={parseInt(paginate) <= 1} className="mr-2">
                                        <i className="fa fa-arrow-left mr-1"></i>Atrás
                                    </Button>
                                </Link>
                                <Link to={Helpers.config.convertUrl({
                                    url: slugCategory ? '/categoria/' + (parseInt(paginate)  + 1) + '/' + slugCategory : '/blog-historico/' + (parseInt(paginate)  + 1)
                                })}>
                                    <Button variant={articles.length < 10 ? 'secondary' : 'primary'} disabled={articles.length < 10}>
                                        <i className="fa fa-arrow-right mr-1"></i>Siguiente
                                    </Button>
                                </Link>
                            </div> : ''}
                        </> : <div className="alert alert-warning">
                            <strong>¡Lo sentimos!</strong> No se ha encontrado información.
                        </div>}
                    </ReactPlaceholder> : <ArticleBlog />}
                </div>
                <div className="col-12 col-md-4">
                    <div className="bg-secondary h-100 p-5">
                        <FormCustom viewSubmit={false} onSubmit={handleSearchArticle}>
                            <Input
                                text="Buscar"
                                placeholder="Buscar"
                                maxLength="255"
                                value={filterText}
                                onChange={e => setFilterText(e.currentTarget.value)}
                                append={[
                                    <Button type="submit">
                                        <i className="fa fa-search"></i>
                                    </Button>
                                ]}
                            />
                        </FormCustom>

                        <ReactPlaceholder ready={readyCategory} showLoadingAnimation rows={10} className="mb-4">
                            <h3 className="mb-4">Departamentos</h3>
                            <Link className="text-decoration-none" to={Helpers.config.convertUrl({ url: '/blog-historico' })}>Todas</Link>
                            <hr />
                            {React.Children.toArray(categories.map(c => <>
                                <p className="d-flex justify-content-between text-dark">
                                    <Link className="text-decoration-none" to={Helpers.config.convertUrl({ url: '/categoria/1/' + c.slug_category })}>{c.name_category}</Link>
                                    <span>({c.qty_articles})</span>
                                </p>
                                <hr />
                            </>))}
                        </ReactPlaceholder>
                        
                        <h3 className="mb-4">Artículos Populares</h3>
                        <ReactPlaceholder ready={readyArticlesPopulates} showLoadingAnimation rows={10}>
                            {React.Children.toArray(articlesPopulated.map(a => <>
                                <section className="d-flex pt-3">
                                    {a.file_images ? <Link to={Helpers.config.convertUrl({ url: '/blog/' + a.token_article })}>
                                        <img src={Helpers.config.resourcesUrl({ url: a.file_images })} width="80" height="80" />
                                    </Link> : ''}
                                    <div className="pl-4">
                                        <Link to={Helpers.config.convertUrl({ url: '/blog/' + a.token_article })} className="text-decoration-none">
                                            <h6 className="font-weight-bold">{a.name_article}</h6>
                                        </Link>
                                        <p className="d-flex flex-column mt-3">
                                            <span className="mr-3">
                                                <i className="fa fa-calendar-alt mr-2"></i>
                                                <span className="f-size-10">{a.date_article}</span>
                                            </span>
                                            <span className="mr-3">
                                                <i className="fa fa-user mr-2"></i>
                                                <span className="f-size-10">{a.user_created}</span>
                                            </span>
                                            <span className="mr-3">
                                                <i className="fa fa-comment mr-2"></i>
                                                <span className="f-size-10">{a.qty_comment_article}</span>
                                            </span>
                                        </p>
                                    </div>
                                </section>
                                <hr />
                            </>))}
                        </ReactPlaceholder>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </>
}

History.defaultProps = {
    showFull: true
}

export default History