import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import { Link } from 'react-router-dom';

const CustomersList = () => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers.customers.get_customers().then(res => {
                setRows(res.data)
            }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setIsProccesing(false)
                setReload(false)
            })
        }
    }, [reload])

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_nomina', text: 'ID', align: 'center' },
                { name: 'full_name_cust', text: 'Nombres' },
                { name: 'email_cust', text: 'Email' },
                { name: 'state_civil_cust', text: 'Estado Civil' },
                { name: 'state_cust', text: 'Estado', align: 'center', render: r => <div className={'badge badge-' + (parseInt(r.state_cust) === 1 ? 'success' : 'danger')}>
                    {parseInt(r.state_cust) === 1 ? 'Activo' : 'Inactivo'}
                </div> },
                { name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => {
                    if (parseInt(u.id_customers) === 1 && Helpers.config.isDeveloperWeb()) {
                        return ''
                    }
                    return <div>
                        <Link to={Helpers.config.convertUrl({ url: '/colaboradores/' + u.id_customers })}>
                            <Button className="mr-1" size="xs">
                                <i className="fa fa-edit"></i>
                            </Button>
                        </Link>
                        {/* <Button variant="danger" size="xs" data-id-bank={u.id_bank} data-name-bank={u.name_bank}>
                            <i className="fa fa-trash"></i>
                        </Button> */}
                    </div>
                } },
            ],
        ]}
        rows={rows}
        isProcessing={isProcessing}
    />, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="d-flex justify-content-between bg-danger text-white">
                <span className="align-self-center">Listado De Colaboradores CRM</span>
                <Link to={Helpers.config.convertUrl({ url: '/registro-colaborador' })}>
                    <Button size="sm">
                        <i className="fa fa-plus mr-1"></i> Registrar Colaborador
                    </Button>
                </Link>
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
    </>
}

export default CustomersList