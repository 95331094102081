import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import SaleForm from './SaleForm'
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'

const Sale = ({ nameSingle, namePlural, separator }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [dataUpdated, setDataUpdated] = useState({})
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [loadSubmitDelete, setLoadSubmitDelete] = useState(false)
    const [dataDeleted, setDataDeleted] = useState({})

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers.sale.get_sale().then(res => {
                setRows(res.data)
            }).catch(req => Helpers.promise.catch({ req, toast }))
                .finally(() => {
                    setIsProccesing(false)
                    setReload(false)
                })
        }
    }, [reload])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.sale.sale_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setModalShow(false)
                setDataUpdated({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setLoadSubmit(false)
                setReload(true)
            })
    }

    const handleGet = e => {
        let $this = e.currentTarget

        if (!$this.dataset?.idSale) {
            setModalTitle(`Registrar ${nameSingle}`)
            setDataUpdated({})
            setModalShow(true);
            return
        }

        let buttonHTML = $this.innerHTML;
        $this.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'
        let { idSale } = $this.dataset

        Controllers.sale.get_sale_id({ p_id_sale: idSale }).then(res => {
            setModalTitle(`Modificar ${nameSingle} #${idSale}`)
            setDataUpdated({ data: res.data })
            setModalShow(true);
        }).catch(req => {
            Helpers.promise.catch({ req, toast })
            setReload(true)
        })
            .finally(() => $this.innerHTML = buttonHTML)
    }

    const handleDelete = () => {
        setLoadSubmitDelete(true)

        Controllers.sale.sale_delete(dataDeleted).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setShowConfirmDelete(false)
                setDataDeleted({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setReload(true)
                setLoadSubmitDelete(false)
            })
    }

    const handleHideModalForm = () => {
        setModalShow(false)
        setLoadSubmit(false)
    }

    const handleShowModalDelete = e => {
        let { idSale, descriptionSale } = e.currentTarget.dataset
        setDataDeleted({
            p_id_sale: idSale,
            p_description_sale: descriptionSale
        })
        setShowConfirmDelete(true)
    }

    const handleHideModalDelete = () => setShowConfirmDelete(false)

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_sale', text: 'ID', align: 'center' },
                { name: 'description_sale', text: 'Tienda' },
                {
                    name: 'type_sale', align: 'center', text: 'Resultados', render: s => {
                        let color = ''
                        switch (s.type_sale) {
                            case 'Negro':
                                color = 'dark'
                                break;

                            case 'Rojo':
                                color = 'danger'
                                break;

                            case 'Estrella':
                                color = 'warning'
                                break;
                        }
                        return <div className={'badge badge-' + color}>
                            {s.type_sale}
                        </div>
                    }
                },
                {
                    name: 'show_dashboard_sale', text: 'Mostrar en Escritorio', align: 'center', render: r => <div className={'badge badge-' + (parseInt(r.show_dashboard_sale) === 1 ? 'success' : 'danger')}>
                        {parseInt(r.show_dashboard_sale) === 1 ? 'SI' : 'NO'}
                    </div>
                },
                {
                    name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => {
                        return <div>
                            <Button className="mr-1" size="xs" data-id-sale={u.id_sale} onClick={handleGet}>
                                <i className="fa fa-edit"></i>
                            </Button>
                            <Button variant="danger" size="xs" data-id-sale={u.id_sale} data-description-sale={u.description_sale} onClick={handleShowModalDelete}>
                                <i className="fa fa-trash"></i>
                            </Button>
                        </div>
                    }
                },
            ],
        ]}
        rows={rows}
        isProcessing={isProcessing}
    />, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="d-flex justify-content-between bg-danger text-white">
                <span>Listado De {namePlural}</span>
                <Button size="sm" onClick={handleGet}>
                    <i className="fa fa-plus mr-1"></i> Registrar {nameSingle}
                </Button>
            </Card.Header>
            <Card.Body>
                {dtRows}
            </Card.Body>
        </Card>
        <Modal show={modalShow} onHide={handleHideModalForm} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SaleForm onSubmit={handleInsertUpdate} loadSubmit={loadSubmit} {...dataUpdated} />
            </Modal.Body>
        </Modal>
        <SweetAlert
            icon="delete"
            show={showConfirmDelete}
            showCancelButton
            title={<>¿Desea eliminar {separator} {nameSingle}?</>}
            subtitle={<>#{dataDeleted.p_id_sale} - {dataDeleted.p_description_sale}</>}
            confirmButtonText="¡Sí, eliminar!"
            cancelButtonText="¡No!"
            loadSubmit={loadSubmitDelete}
            onCancel={handleHideModalDelete}
            onConfirm={handleDelete}
        />
    </>
}

export default Sale