import React, { useCallback, useState } from 'react'
import { useRef } from 'react'
import Button from 'react-bootstrap/esm/Button'
import { Input, FormCustom, SwitchToggle, TextArea, CheckBox } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import ImagesModal from '../Images/ImagesModal'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import Controllers from '../../Api/Controllers'
import ReactPlaceholder from 'react-placeholder/lib'
import ProgressBar from 'react-bootstrap/ProgressBar'

const VideoForm = ({ data, onSubmit, disablebSubmit, loadSubmit, percentageUpload }) => {
    const [row, setRow] = useState({
        p_id_video: data.id_video,
        p_name_video: data.name_video,
        p_description_video: data.description_video,
        p_file_video: data.file_video,
        p_id_images: data.id_images,
        p_categories: data.categories,
        p_status_video: data.status_video,
        p_has_video: data.has_video
    })

    const [ready, setReady] = useState(false)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        Controllers.category.get_category().then(res => {
            setCategories(res.data)
            setReady(true)
        })
    }, [])

    const refVideo = useRef(null)
    const [fileVideo, setFileVideo] = useState(isNaN(parseInt(data.id_video)) ? '' : Helpers.config.resourcesUrl({ url: data.file_video }))
    const [imageVideo, setImageVideo] = useState(isNaN(parseInt(data.id_video)) ? '' : {
        id_images: data.id_images,
        file_images: data.file_images
    })
    const [modalShow, setModalShow] = useState(false)

    const dataSubmit = useCallback(() => {
        let formData = new FormData()

        for (const key in row) {
            if (key === 'p_categories') {
                formData.append('p_categories', JSON.stringify(row[key]))
                continue
            }

            if (key === 'p_file_video') {
                if (row.p_file_video?.files) {
                    if (row.p_file_video.files.length > 0) {
                        formData.append('p_file_video', row.p_file_video.files[0])
                        continue
                    }
                }
                formData.append('p_file_video', '')
                continue
            }
            formData.append(key, row[key])
        }

        return formData
    }, [row])

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
        <FormCustom dataSubmit={dataSubmit()} onSubmit={({ data }) => {
            if (!row.p_id_images) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar imagen destacada', toast })
                return
            }

            onSubmit({ data })
        }} disablebSubmit={loadSubmit} loadSubmit={loadSubmit}>
            <div className="col-12 col-md-6">
                <div className="form-row">
                    <Input
                        text="Nombre del video"
                        classNameParent="col-12 mt-2 mb-2"
                        required
                        invalid="El campo es obligatorio."
                        defaultValue={data.name_video}
                        onChange={e => setRow({ ...row, p_name_video: e.currentTarget.value })}
                        maxLength={150}
                    />
                    <TextArea
                        text="Descripción"
                        classNameParent="col-12 mt-2 mb-3"
                        required
                        invalid="El campo es obligatorio."
                        defaultValue={data.description_video}
                        onChange={e => setRow({ ...row, p_description_video: e.currentTarget.value })}
                        rows={5}
                    />
                    <SwitchToggle
                        classNameParent="col-12 mb-2"
                        id="video_state"
                        defaultChecked={parseInt(row.p_status_video) === 1}
                        onChange={e => setRow({ ...row, p_status_video: e.currentTarget.checked ? 1 : 0 })}
                    />

                    {/* <div className="col-12">
                        <hr className="border-light" />
                        <p>Departamentos</p>
                    </div> */}

                    {/* React.Children.toArray(categories.map(c => {
                        return <CheckBox
                            id={'chk-category-' + c.id_category}
                            text={c.name_category}
                            checked={row.p_categories.some(cc => parseInt(cc.id_category) === parseInt(c.id_category))}
                            onChange={e => {
                                if (e.currentTarget.checked) {
                                    setRow({ ...row, p_categories: [ ...row.p_categories, c ] })
                                } else {
                                    setRow({ ...row, p_categories: row.p_categories.filter(cc => parseInt(cc.id_category) !== parseInt(c.id_category)) })
                                }
                            }}
                        />
                    })) */}
                    <div className="col-12 d-flex flex-column mb-2 align-items-center">
                        <img className="img-thumbnail mb-2" src={Helpers.config.resourcesUrl({ url: imageVideo ? imageVideo.file_images : '/images/dashboard/sin_imagen.png' })} width="150" />
                        <Button onClick={_ => setModalShow(true)} block>
                            <i className="fa fa-search mr-1"></i>Seleccionar Imagen Destacada
                        </Button>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-row">
                    <div className="col-12">
                        {parseInt(row.p_has_video) === 1 ? <>
                            <video controls ref={refVideo} className={fileVideo ? '' : 'd-none'} width="100%" height="200">
                                <source type="video/mp4" src={fileVideo} />
                            </video>
                            <img className={'img-thumbnail mb-2 ' + (fileVideo ? 'd-none' : '')} src={Helpers.config.resourcesUrl({ url: '/images/dashboard/sin_video.png' })} width="100%" height="300" />
                            <Input
                                type="file"
                                classNameParent=""
                                required={isNaN(parseInt(data?.id_video))}
                                invalid="El campo es obligatorio."
                                accept="video/mp4"
                                onChange={e => {
                                    setRow({ ...row, p_file_video: e.currentTarget })
                                    if (e.currentTarget.files.length > 0) {
                                        setFileVideo(URL.createObjectURL(e.currentTarget.files[0]))
                                        refVideo.current.load()
                                    } else {
                                        setFileVideo('')
                                    }
                                }}
                            />
                        </> : ''}

                        <SwitchToggle
                            classNameParent="w-100 mb-2"
                            id="has_video"
                            textCheck="Tiene Video"
                            textUnChecked="No Tiene Video"
                            defaultChecked={parseInt(row.p_has_video) === 1}
                            onChange={e => setRow({ ...row, p_has_video: e.currentTarget.checked ? 1 : 0 })}
                        />
                    </div>

                </div>
            </div>

            {loadSubmit ? <div className="col-12">
                <ProgressBar striped variant="success" now={percentageUpload} animated={loadSubmit} label={percentageUpload < 100 ? 'Subiendo Video ' + percentageUpload + '%' : <div className="d-flex justify-content-center">
                    <span className="align-self-center">Enviando Video</span>
                    <i className=" ml-3 fas fa-ellipsis-h fa-spin"></i>
                </div>} />
            </div> : ''}

        </FormCustom>
        <ImagesModal modalShow={modalShow} handleHideModalForm={() => setModalShow(false)} addImage={l => {
            setImageVideo(l)
            setRow({ ...row, p_id_images: l.id_images })
            setModalShow(false)
        }} />
    </ReactPlaceholder>
}

VideoForm.defaultProps = {
    data: {
        id_video: '',
        file_images: '',
        name_video: '',
        description_video: '',
        file_video: '',
        id_images: '',
        status_video: 1,
        categories: [],
        has_video: 1
    },
    onSubmit: () => { },
    loadSubmit: false
}

export default VideoForm;