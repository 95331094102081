import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, Select, SwitchToggle, CheckBox } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

const ImagesForm = ({ data, callbackSuccess }) => {
    const [row, setRow] = useState({
        p_id_images: data.id_images,
        p_file_images: data.file_images,
        p_state_images: data.state_images,
        p_alt_images: data.alt_images,
    })

    const [loadSubmit, setLoadSubmit] = useState(false)
    const [photo, setPhoto] = useState('')

    const dataSubmit = useCallback(() => {
        let formData = new FormData()

        for (const key in row) {
            if (key === 'p_file_images') {
                if (row.p_file_images?.files) {
                    if (row.p_file_images.files.length > 0) {
                        formData.append('p_file_images', row.p_file_images.files[0])
                        continue
                    }
                }
                formData.append('p_file_images', '')
                continue
            }
            formData.append(key, row[key])
        }

        return formData
    }, [row])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.images.images_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                callbackSuccess()
                setRow({ ...row, p_alt_images: '' })
                setPhoto('')
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
        })
    }

    return <>
        <FormCustom viewSubmit={false} dataSubmit={dataSubmit()} onSubmit={({ data }) => {
            if (!photo) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar imagen', toast })
                return
            }
            handleInsertUpdate({ data })
        }}>
            <Card className="col-12">
                <Card.Header>
                    <i className="fa fa-plus mr-1"></i>Cargar Imagen
                </Card.Header>
                <Card.Body className="row">
                    <Input
                        text="Nombre"
                        classNameParent="col-12"
                        required
                        invalid="El campo es obligatorio."
                        value={row.p_alt_images}
                        maxLength={255}
                        onChange={e => setRow({ ...row, p_alt_images: e.currentTarget.value })}
                    />
                    <div className="col-12">
                        <img className="img-thumbnail mb-2" src={photo ? photo : Helpers.config.resourcesUrl({ url: '/images/dashboard/sin_imagen.png' })} width="150" />
                        <Input
                            type="file"
                            classNameParent=""
                            required
                            invalid="El campo es obligatorio."
                            accept="image/*"
                            onChange={e => {
                                setRow({ ...row, p_file_images: e.currentTarget })
                                if (e.currentTarget.files.length > 0) {
                                    let selectedFile = e.currentTarget.files[0]
                                    let reader = new FileReader()

                                    reader.onload = function(event) {
                                        setPhoto(event.target.result)
                                    }
                                    reader.readAsDataURL(selectedFile)
                                } else {
                                    setPhoto('')
                                }
                            }}
                        />
                    </div>
                    <div className="col-12">
                        <Button type="submit" variant="primary btn-gradient-primary">
                            {loadSubmit ? <i className="fa fa-circle-notch fa-spin"></i> : 'Guardar Imagen'}
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </FormCustom>
    </>
}

ImagesForm.defaultProps = {
    data: {
        id_images: '',
        file_images: '',
        state_images: '1',
        alt_images: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default ImagesForm;