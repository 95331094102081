import React, { useState, useCallback, useEffect } from 'react'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, CheckBox, Radio, Select, SwitchToggle } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import { useHistory, Link } from 'react-router-dom';
import ReactPlaceholder from 'react-placeholder/lib';
import { useMemo } from 'react';
import ProfileForm from '../Profile/ProfileForm';
import Modal from 'react-bootstrap/esm/Modal';

const CustomersForm = ({ data, isModifyUser }) => {
    const [row, setRow] = useState({
        p_id_customers: data.id_customers,
        p_full_name_cust: data.full_name_cust,
        p_file_photo_cust: data.file_photo_cust,
        p_email_cust: data.email_cust,
        p_address_cust: data.address_cust,
        p_phone_cust: data.phone_cust,
        p_state_civil_cust: data.state_civil_cust,
        p_life_with_cust: data.life_with_cust,
        p_type_blood_cust: data.type_blood_cust,
        p_diseases_cust: data.diseases_cust,
        p_medicine_cust: data.medicine_cust,
        p_allergies_cust: data.allergies_cust,
        p_contact_full_name_one_cust: data.contact_full_name_one_cust,
        p_contact_phone_one_cust: data.contact_phone_one_cust,
        p_contact_relation_one_cust: data.contact_relation_one_cust,
        p_contact_full_name_two_cust: data.contact_full_name_two_cust,
        p_contact_phone_two_cust: data.contact_phone_two_cust,
        p_contact_relation_two_cust: data.contact_relation_two_cust,
        p_father_lives_cust: data.father_lives_cust,
        p_mother_lives_cust: data.mother_lives_cust,
        p_qty_sons_cust: data.qty_sons_cust,
        p_qty_grandchildren_cust: data.qty_grandchildren_cust,
        p_sports_cust: data.sports_cust,
        p_type_pet_cust: data.type_pet_cust,
        p_favorite_music_cust: data.favorite_music_cust,
        p_hobbies_cust: data.hobbies_cust,
        p_goals_profession_cust: data.goals_profession_cust,
        p_goals_personal_cust: data.goals_personal_cust,
        p_state_cust: data.state_cust,
        p_password_cust: data.password_cust,
        p_date_birthday_cust: data.date_birthday_cust,
        p_id_branch_office: data.id_branch_office,
        p_id_job: data.id_job,
        p_id_category: data.id_category,
        p_id_profile: data.id_profile,
        p_id_nomina: data.id_nomina
    })

    const [viewPassword, setViewPassword] = useState(false)
    const history = useHistory()
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [photo, setPhoto] = useState(data?.file_photo_cust ? Helpers.config.resourcesUrl({ url: data.file_photo_cust }) : '')

    const [autoGenerateId, setAutoGenerateId] = useState(false)

    const dataSubmit = useCallback(() => {
        let formData = new FormData()

        for (const key in row) {
            if (key === 'p_file_photo_cust') {
                if (row.p_file_photo_cust?.files) {
                    if (row.p_file_photo_cust.files.length > 0) {
                        formData.append('p_file_photo_cust', row.p_file_photo_cust.files[0])
                        continue
                    }
                }
                formData.append('p_file_photo_cust', '')
                continue
            }
            formData.append(key, row[key])
        }

        return formData
    }, [row])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.customers.customers_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setLoadSubmit(false)
                if (!isModifyUser) {
                    history.push(Helpers.config.convertUrl({ url: '/colaboradores' }))    
                }
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
    }

    const handleChangeLifesWith = e => {
        if (e.currentTarget.checked) {
            let { value } = e.currentTarget.dataset
            setRow({ ...row, p_life_with_cust: row.p_life_with_cust + (row.p_life_with_cust === '' ? '' : ',') + value })
        } else {
            let { value } = e.currentTarget.dataset
            let lastLifesWithChecked = row.p_life_with_cust.replace(value, '')
            lastLifesWithChecked = lastLifesWithChecked.replace(',,', ',')
            if (lastLifesWithChecked.charAt(0) === ',') {
                lastLifesWithChecked = lastLifesWithChecked.substr(1, lastLifesWithChecked.length)
            }
            if (lastLifesWithChecked.charAt(lastLifesWithChecked.length - 1) === ',') {
                lastLifesWithChecked = lastLifesWithChecked.substr(0, lastLifesWithChecked.length - 1)
            }
            setRow({ ...row, p_life_with_cust: lastLifesWithChecked })
        }
    }

    const handleChanfeFatherLives = e => {
        let { value } = e.currentTarget.dataset
        setRow({ ...row, p_father_lives_cust: value })
    }

    const handleChanfeMotherLives = e => {
        let { value } = e.currentTarget.dataset
        setRow({ ...row, p_mother_lives_cust: value })
    }

    const [jobs, setJobs] = useState([])
    const [branchOffices, setBranchOffices] = useState([])
    const [ready, setReady] = useState(false)
    const [categorys, setCategories] = useState([])
    const [profiles, setProfiles] = useState([])
    const [modalProfile, setModalProfile] = useState(false)
    const [dataProfile, setDataProfile] = useState({})
    const [loadProfile, setLoadProfile] = useState(false)

    useEffect(() => {
        Promise.all([
            Controllers.job.get_job(),
            Controllers.branch_office.get_branch_office(),
            Controllers.category.get_category(),
            Controllers.profile.get_profile()
        ]).then(res => {
            setJobs(res[0].data)
            setBranchOffices(res[1].data)
            setCategories(res[2].data)
            setProfiles(res[3].data)
            setReady(true)
        })
    }, [])

    useEffect(() => {
        if (loadProfile && row.p_id_profile !== '') {
            Controllers.profile.get_profile_id({
                p_id_profile: row.p_id_profile
            }).then(res => {
                setDataProfile(res.data)
                setModalProfile(true)
                setLoadProfile(false)
            })
        }
    }, [loadProfile, row.p_id_profile])
    
    const job = useMemo(() => {
        if (row.p_id_job == '') {
            return
        }
        
        let finded = jobs.find(j => parseInt(j.id_job) === parseInt(row.p_id_job))

        if (!finded) {
            return
        }

        return {
            label: finded.name_job,
            value: finded.id_job
        }
    }, [row.p_id_job, jobs])

    const branchOffice = useMemo(() => {
        if (row.p_id_branch_office === '') {
            return
        }
        
        let finded = branchOffices.find(j => parseInt(j.id_branch_office) === parseInt(row.p_id_branch_office))

        if (!finded) {
            return
        }

        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [row.p_id_branch_office, branchOffices])

    const category = useMemo(() => {
        if (row.p_id_category === '') {
            return
        }
        
        let finded = categorys.find(j => parseInt(j.id_category) === parseInt(row.p_id_category))

        if (!finded) {
            return
        }

        return {
            label: finded.name_category,
            value: finded.id_category
        }
    }, [row.p_id_category, categorys])

    const profile = useMemo(() => {
        if (row.p_id_profile === '') {
            return
        }
        
        let finded = profiles.find(j => parseInt(j.id_profile) === parseInt(row.p_id_profile))

        if (!finded) {
            return
        }

        return {
            label: finded.description_profile,
            value: finded.id_profile
        }
    }, [row.p_id_profile, profiles])

    return <>
        {isModifyUser ? <div className="mt-3"></div> : <>
            <Link to={Helpers.config.convertUrl({ url: '/colaboradores' })} >
                <Button size="sm" className="mb-4">
                    <i className="fa fa-arrow-left mr-1"></i>Regresar
                </Button>
            </Link>
            {parseInt(data?.id_customers) > 0 ? <h4 className="mb-4">
                Modificando información del Colaborador #{data?.id_nomina}
            </h4> : ''}
        </>}
        <ReactPlaceholder rows={20} ready={ready} showLoadingAnimation>
            <FormCustom dataSubmit={dataSubmit()} onSubmit={({ data }) => {
                if (!row.p_id_branch_office) {
                    Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar sucursal.', toast })
                    return
                }
                if (!row.p_id_job) {
                    Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar puesto de trabajo.', toast })
                    return
                }
                if (!row.p_id_category) {
                    Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar Departamento.', toast })
                    return
                }
                if (!row.p_id_profile) {
                    Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar perfil.', toast })
                    return
                }
                handleInsertUpdate({ data })
            }} viewSubmit={false}>
                {/* !data.id_customers ? <CheckBox
                    text="¿Autogenerar ID?"
                    classNameParent="col-12 mb-4"
                    id="chk-auto-generate-id"
                    checked={autoGenerateId}
                    onChange={e => setAutoGenerateId(e.currentTarget.checked)}
                /> : '' */}
                <Input
                    text="ID Colaborador"
                    value={row.p_id_nomina}
                    type="number"
                    pattern="[0-9]{1,9}"
                    required
                    onChange={e => setRow({ ...row, p_id_nomina: e.currentTarget.value })}
                    maxLength={9}
                />
                <Input
                    text="Nombres"
                    placeholder="Nombres"
                    classNameParent="col-12"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_full_name_cust}
                    maxLength={255}
                    onChange={e => setRow({ ...row, p_full_name_cust: e.currentTarget.value })}
                    disabled={isModifyUser}
                />
                {isModifyUser ? '' :  <div className="col-12">
                    <img className="img-thumbnail mb-2" src={photo ? photo : Helpers.config.resourcesUrl({ url: '/images/dashboard/sin_imagen.png' })} width="150" />
                    <Input
                        type="file"
                        classNameParent=""
                        required={isNaN(parseInt(data?.id_customers))}
                        invalid="El campo es obligatorio."
                        accept="image/*"
                        onChange={e => {
                            setRow({ ...row, p_file_photo_cust: e.currentTarget })
                            if (e.currentTarget.files.length > 0) {
                                let selectedFile = e.currentTarget.files[0]
                                let reader = new FileReader()

                                reader.onload = function(event) {
                                    setPhoto(event.target.result)
                                }
                                reader.readAsDataURL(selectedFile)
                            } else {
                                setPhoto('')
                            }
                        }}
                    />
                </div>}
                <div className="col-12 mb-2">
                    <hr className="border-light" />
                </div>
                <Input
                    text="Email"
                    type="email"
                    placeholder="Email"
                    classNameParent="col-6"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_email_cust}
                    maxLength={150}
                    onChange={e => setRow({ ...row, p_email_cust: e.currentTarget.value })}
                    disabled={isModifyUser}
                />
                <Input
                    text="Contraseña"
                    placeholder="Contraseña"
                    classNameParent="col-6"
                    required
                    type={viewPassword ? 'text' : 'password'}
                    invalid="El campo es obligatorio."
                    value={row.p_password_cust}
                    maxLength={255}
                    onChange={e => setRow({ ...row, p_password_cust: e.currentTarget.value })}
                    append={[
                        <Button onMouseEnter={_ => setViewPassword(true)} onMouseLeave={_ => setViewPassword(false)}>
                            <i className="fa fa-eye"></i>
                        </Button>
                    ]}
                />
                <Input
                    text="Fecha Nacimiento"
                    classNameParent="col-6"
                    required
                    type="date"
                    invalid="El campo es obligatorio."
                    value={row.p_date_birthday_cust}
                    onChange={e => setRow({ ...row, p_date_birthday_cust: e.currentTarget.value })}
                    disabled={isModifyUser}
                />
                <Select
                    text="Sucursal"
                    classNameParent="col-6"
                    options={branchOffices.map(j => {
                        return {
                            label: j.name_branch_office,
                            value: j.id_branch_office
                        }
                    })}
                    value={branchOffice}
                    onChange={e => setRow({ ...row, p_id_branch_office: e.value })}
                    disabled={isModifyUser}
                />
                <Select
                    text="Puesto de Trabajo"
                    classNameParent="col-6"
                    options={jobs.map(j => {
                        return {
                            label: j.name_job,
                            value: j.id_job
                        }
                    })}
                    value={job}
                    onChange={e => setRow({ ...row, p_id_job: e.value })}
                    disabled={isModifyUser}
                />
                <Select
                    text="Departamento"
                    classNameParent="col-6 mb-3"
                    options={categorys.map(j => {
                        return {
                            label: j.name_category,
                            value: j.id_category
                        }
                    })}
                    value={category}
                    onChange={e => setRow({ ...row, p_id_category: e.value })}
                    disabled={isModifyUser}
                />
                <Select
                    text="Perfil de Privilegios"
                    classNameParent="col-6 mb-3"
                    options={profiles.map(j => {
                        return {
                            label: j.description_profile,
                            value: j.id_profile
                        }
                    })}
                    value={profile}
                    onChange={e => setRow({ ...row, p_id_profile: e.value })}
                    disabled={isModifyUser}
                    append={row.p_id_profile === '' ? '' : [
                        <Button onClick={_ => {
                            setLoadProfile(true)
                        }}>
                            <i className={loadProfile ? 'fa fa-circle-notch fa-spin' : 'fa fa-user-tie'}></i>
                        </Button>
                    ]}
                />
                <SwitchToggle
                    classNameParent="col-12 mb-2"
                    id="state-cust"
                    defaultChecked={parseInt(row.p_state_cust) === 1}
                    onChange={e => setRow({ ...row, p_state_cust: e.currentTarget.checked ? 1 : 0 })}
                    disabled={isModifyUser}
                />
                <div className="col-12 mb-2">
                    <hr className="border-light" />
                </div>
                <Input
                    text="Dirección"
                    placeholder="Dirección"
                    classNameParent="col-12"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_address_cust}
                    maxLength={255}
                    onChange={e => setRow({ ...row, p_address_cust: e.currentTarget.value })}
                />
                <div className="col-12 mb-2">
                    <hr className="border-light" />
                </div>
                <Input
                    text="Telefono"
                    placeholder="Telefono"
                    classNameParent="col-6"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_phone_cust}
                    maxLength={30}
                    onChange={e => setRow({ ...row, p_phone_cust: e.currentTarget.value })}
                />
                <Input
                    text="Estado Civil"
                    placeholder="Estado Civil"
                    classNameParent="col-6"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_state_civil_cust}
                    maxLength={30}
                    onChange={e => setRow({ ...row, p_state_civil_cust: e.currentTarget.value })}
                />
                <div className="col-12 mb-2">
                    <hr className="border-light" />
                </div>
                <label className="text-muted col-12">Vive Con</label>
                <div className="col-12 d-flex">
                    {React.Children.toArray(['conyuge', 'hijos', 'padres', 'otros']).map((v, i) => {
                        return <CheckBox
                            key={i}
                            text={Helpers.string.toCamelCase(v)}
                            id={`lifes-with-${v}`}
                            classNameParent="mr-2"
                            required={row.p_life_with_cust === ''}
                            data-value={v}
                            checked={row.p_life_with_cust?.includes(v)}
                            onChange={handleChangeLifesWith}
                        />
                    })}
                </div>
                <div className="col-12 mb-2">
                    <hr className="border-light" />
                </div>
                <Input
                    text="Tipo de Sangre"
                    placeholder="Tipo de Sangre"
                    classNameParent="col-6"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_type_blood_cust}
                    maxLength={100}
                    onChange={e => setRow({ ...row, p_type_blood_cust: e.currentTarget.value })}
                />
                <Input
                    text="Enfermedades Crónicas"
                    placeholder="Enfermedades Crónicas"
                    classNameParent="col-6"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_diseases_cust}
                    maxLength={255}
                    onChange={e => setRow({ ...row, p_diseases_cust: e.currentTarget.value })}
                />
                <div className="col-12 mb-2">
                    <hr className="border-light" />
                </div>
                <Input
                    text="Medicamentos Especiales"
                    placeholder="Medicamentos Especiales"
                    classNameParent="col-6"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_medicine_cust}
                    maxLength={255}
                    onChange={e => setRow({ ...row, p_medicine_cust: e.currentTarget.value })}
                />
                <Input
                    text="Alergias"
                    placeholder="Alergias"
                    classNameParent="col-6"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_allergies_cust}
                    onChange={e => setRow({ ...row, p_allergies_cust: e.currentTarget.value })}
                    maxLength={255}
                />
                <div className="col-12 mb-2">
                    <hr className="border-light" />
                </div>
                <Input
                    text="Contacto de Emergencia"
                    placeholder="Contacto de Emergencia"
                    classNameParent="col-12"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_contact_full_name_one_cust}
                    maxLength={255}
                    onChange={e => setRow({ ...row, p_contact_full_name_one_cust: e.currentTarget.value })}
                />
                <Input
                    text="Telefono"
                    placeholder="Telefono"
                    classNameParent="col-12"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_contact_phone_one_cust}
                    maxLength={30}
                    onChange={e => setRow({ ...row, p_contact_phone_one_cust: e.currentTarget.value })}
                />
                <Input
                    text="Relación o Parentesco"
                    placeholder="Relación o Parentesco"
                    classNameParent="col-12"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_contact_relation_one_cust}
                    onChange={e => setRow({ ...row, p_contact_relation_one_cust: e.currentTarget.value })}
                    maxLength={150}
                />
                <div className="col-12 mb-2">
                    <hr className="border-light" />
                </div>
                <Input
                    text="Contacto de Emergencia 2"
                    placeholder="Contacto de Emergencia 2"
                    classNameParent="col-12"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_contact_full_name_two_cust}
                    maxLength={255}
                    onChange={e => setRow({ ...row, p_contact_full_name_two_cust: e.currentTarget.value })}
                />
                <Input
                    text="Telefono"
                    placeholder="Telefono"
                    classNameParent="col-12"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_contact_phone_two_cust}
                    maxLength={30}
                    onChange={e => setRow({ ...row, p_contact_phone_two_cust: e.currentTarget.value })}
                />
                <Input
                    text="Relación o Parentesco"
                    placeholder="Relación o Parentesco"
                    classNameParent="col-12"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_contact_relation_two_cust}
                    maxLength={150}
                    onChange={e => setRow({ ...row, p_contact_relation_two_cust: e.currentTarget.value })}
                />
                <div className="col-12 mb-2">
                    <hr className="border-light" />
                </div>
                <div className="col-12 col-md-6 d-flex">
                    <strong className="text-muted mr-4">Papá</strong>
                    <Radio
                        text="Vive"
                        id="father-lives"
                        classNameParent="mr-4"
                        name="rdb-father-lives"
                        required={row.p_father_lives_cust === ''}
                        data-value="1"
                        checked={parseInt(row.p_father_lives_cust) === 1}
                        onChange={handleChanfeFatherLives}
                    />
                    <Radio
                        text="Finado"
                        id="father-dead"
                        classNameParent=""
                        name="rdb-father-lives"
                        required={row.p_father_lives_cust === ''}
                        data-value="0"
                        checked={parseInt(row.p_father_lives_cust) === 0}
                        onChange={handleChanfeFatherLives}
                    />
                </div>
                <div className="col-12 col-md-6 d-flex">
                    <strong className="text-muted mr-4">Mamá</strong>
                    <Radio
                        text="Vive"
                        id="mother-lives"
                        classNameParent="mr-4"
                        name="rdb-mother-lives"
                        required={row.p_mother_lives_cust === ''}
                        data-value="1"
                        checked={parseInt(row.p_mother_lives_cust) === 1}
                        onChange={handleChanfeMotherLives}
                    />
                    <Radio
                        text="Finado"
                        id="mother-dead"
                        classNameParent=""
                        name="rdb-mother-lives"
                        required={row.p_mother_lives_cust === ''}
                        data-value="0"
                        checked={parseInt(row.p_mother_lives_cust) === 0}
                        onChange={handleChanfeMotherLives}
                    />
                </div>
                <div className="col-12 mb-2">
                    <hr className="border-light" />
                </div>
                <Input
                    text="# Hijos"
                    placeholder="# Hijos"
                    classNameParent="col-4"
                    type="number"
                    pattern="[0-9]{1,2}"
                    min="0"
                    max="10"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_qty_sons_cust}
                    maxLength={150}
                    onChange={e => setRow({ ...row, p_qty_sons_cust: e.currentTarget.value })}
                />
                <Input
                    text="# Nietos"
                    placeholder="# Nietos"
                    classNameParent="col-4"
                    type="number"
                    pattern="[0-9]{1,2}"
                    min="0"
                    max="10"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_qty_grandchildren_cust}
                    maxLength={150}
                    onChange={e => setRow({ ...row, p_qty_grandchildren_cust: e.currentTarget.value })}
                />
                <Input
                    text="Deportes que Practica"
                    placeholder="Deportes que Practica"
                    classNameParent="col-4"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_sports_cust}
                    maxLength={255}
                    onChange={e => setRow({ ...row, p_sports_cust: e.currentTarget.value })}
                />
                <div className="col-12 mb-2">
                    <hr className="border-light" />
                </div>
                <Input
                    text="Tipo Mascota"
                    placeholder="Tipo Mascota"
                    classNameParent="col-4"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_type_pet_cust}
                    maxLength={255}
                    onChange={e => setRow({ ...row, p_type_pet_cust: e.currentTarget.value })}
                />
                <Input
                    text="Música Favorita"
                    placeholder="Música Favorita"
                    classNameParent="col-4"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_favorite_music_cust}
                    maxLength={255}
                    onChange={e => setRow({ ...row, p_favorite_music_cust: e.currentTarget.value })}
                />
                <Input
                    text="Hobbies Pasatiempos"
                    placeholder="Hobbies Pasatiempos"
                    classNameParent="col-4"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_hobbies_cust}
                    maxLength={255}
                    onChange={e => setRow({ ...row, p_hobbies_cust: e.currentTarget.value })}
                />
                <div className="col-12 mb-2">
                    <hr className="border-light" />
                </div>
                <Input
                    text="Metas Profesionales"
                    placeholder="Metas Profesionales"
                    classNameParent="col-4"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_goals_profession_cust}
                    maxLength={255}
                    onChange={e => setRow({ ...row, p_goals_profession_cust: e.currentTarget.value })}
                />
                <Input
                    text="Metas Personales"
                    placeholder="Metas Personales"
                    classNameParent="col-4"
                    required
                    invalid="El campo es obligatorio."
                    value={row.p_goals_personal_cust}
                    maxLength={255}
                    onChange={e => setRow({ ...row, p_goals_personal_cust: e.currentTarget.value })}
                />
                <div className="col-12 mb-2">
                    <hr className="border-light" />
                </div>
                <div className="col-12">
                    <Button type="submit" variant="primary btn-gradient-primary" size="lg" block>
                        {loadSubmit ? <i className="fa fa-circle-notch fa-spin"></i> : 'Enviar Datos'}
                    </Button>
                </div>
            </FormCustom>
            <Modal show={modalProfile} onHide={_ => {
                setModalProfile(false)
            }} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title as="div">
                        Detalle del Perfil #{row.p_id_profile}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProfileForm data={dataProfile} viewSubmit={false} />
                </Modal.Body>
            </Modal>
        </ReactPlaceholder>
    </>
}

CustomersForm.defaultProps = {
    data: {
        id_customers: '',
        full_name_cust: '',
        file_photo_cust: '',
        email_cust: '',
        address_cust: '',
        phone_cust: '',
        state_civil_cust: '',
        life_with_cust: '',
        type_blood_cust: '',
        diseases_cust: '',
        medicine_cust: '',
        allergies_cust: '',
        contact_full_name_one_cust: '',
        contact_phone_one_cust: '',
        contact_relation_one_cust: '',
        contact_full_name_two_cust: '',
        contact_phone_two_cust: '',
        contact_relation_two_cust: '',
        father_lives_cust: '',
        mother_lives_cust: '',
        qty_sons_cust: '',
        qty_grandchildren_cust: '',
        sports_cust: '',
        type_pet_cust: '',
        favorite_music_cust: '',
        hobbies_cust: '',
        goals_profession_cust: '',
        goals_personal_cust: '',
        state_cust: '1',
        password_cust: '',
        date_birthday_cust: '',
        id_branch_office: '',
        id_job: '',
        id_category: '',
        id_profile: '',
        id_nomina: ''
    },
    onSubmit: () => {},
    loadSubmit: false,
    isModifyUser: false
}

export default CustomersForm;