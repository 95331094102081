import React, { useRef, useState, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import JoditEditor from "jodit-react";
import { FormCustom, Input } from '../../Components/Form/Form'
import { useEffect } from 'react';
import Controllers from '../../Api/Controllers';
import { useCallback } from 'react';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'
import ReactPlaceholder from 'react-placeholder/lib';
import Button from 'react-bootstrap/esm/Button';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Modal from 'react-bootstrap/esm/Modal';
import ProductForm from '../Product/ProductForm';

const Dashboard = _ => {
    const [readyBirthday, setReadyBirthday] = useState(false)
    const [birthday, setBirthday] = useState([])
    useEffect(() => {
        Controllers.customers.get_customers_birthday().then(res => {
            setBirthday(res.data)
            setReadyBirthday(true)
        })
    }, [])

    const history = useHistory()

    const [readyEvent, setReadyEvent] = useState(false)
    const [events, setEvents] = useState([])
    useEffect(() => {
        Controllers.event.get_event_populated().then(res => {
            setEvents(res.data)
            setReadyEvent(true)
        })
    }, [])

    const [foro, setForo] = useState([])
    const [readyForo, setReadyForo] = useState(false)
    useEffect(() => {
        Controllers.foro.get_foro_main().then(res => {
            setForo(res.data)
            setReadyForo(true)
        })
    }, [])

    const [noti, setNoti] = useState([])
    const [readyNoti, setReadyNoti] = useState(false)
    useEffect(() => {
        Controllers.noti.get_noti_main().then(res => {
            setNoti(res.data)
            setReadyNoti(true)
        })
    }, [])

    const [product, setProduct] = useState([])
    const [readyProduct, setReadyProduct] = useState(false)
    useEffect(() => {
        Controllers.product.get_product_seller().then(res => {
            setProduct(res.data)
            setReadyProduct(true)
        })
    }, [])

    const [sale, setSale] = useState([])
    const [readySale, setReadySale] = useState(false)
    useEffect(() => {
        Controllers.sale.get_sale_dashboard().then(res => {
            setSale(res.data)
            setReadySale(true)
        })
    }, [])

    const [eventIndex, setEventIndex] = useState(0)

    const eventCurrent = useMemo(() => {
        if (events.length === 0) {
            return {}
        }

        return events[eventIndex]
    }, [events, eventIndex])

    /* const [textFilter, setTextFilter] = useState('') */

    const [value, onChange] = useState(new Date());

    const [modalShow, setModalShow] = useState(false);
    const [dataUpdated, setDataUpdated] = useState({})

    const handleGet = e => {
        let { idProduct } = e.currentTarget.dataset

        Controllers.product.get_product_id({ p_id_product: idProduct }).then(res => {
            setDataUpdated({ data: res.data })
            setModalShow(true);
        }).catch(req => {
            Helpers.promise.catch({ req, toast })
        })
    }

    return <div className="form-row">
        {/* <div className="col-12 mb-4">
            <Card>
                <Card.Body>
                    <FormCustom viewSubmit={false} onSubmit={_ => {
                        history.push(Helpers.config.convertUrl({ url: '/buscador/' + textFilter  }))
                    }}>
                        <Input
                            classNameParent="col-12"
                            text="Buscar"
                            placeholder="Buscar"
                            append={[
                                <Button type="submit">
                                    <i className="fa fa-search"></i>
                                </Button>
                            ]}
                            required
                            onChange={e => setTextFilter(e.currentTarget.value)}
                        />
                    </FormCustom>
                </Card.Body>
            </Card>
        </div> */}
        <div className="col-12 col-lg-6">
            <div className="form-row">
                <ReactPlaceholder ready={readyEvent} rows={15} showLoadingAnimation>
                    {events.length > 0 ? <div className="col-12 mb-4">
                        <div>
                            <img src={Helpers.config.resourcesUrl({ url: eventCurrent.file_images })} className="img-thumbnail" />
                            <div className="text-white" style={{
                                position: 'absolute',
                                bottom: 50,
                                right: '10%'
                            }}>
                                <Link className="text-decoration-none" to={Helpers.config.convertUrl({ url: '/evento/' + eventCurrent.token_event })}>
                                    <h3 className="text-right text-white font-weight-bold" style={{ maxWidth: 300 }}>{eventCurrent.name_event}</h3>
                                </Link>
                                <hr style={{ maxWidth: 300, minWidth: 200, height: '2px' }} className="bg-danger" />
                            </div>
                            <div className="text-white d-flex justify-content-center" style={{
                                position: 'absolute',
                                bottom: 10,
                                width: '100%'
                            }}>
                                <div>
                                    {React.Children.toArray(events.map((e, i) => <i className={`${eventIndex === i ? 'fas fa-dot-circle' : 'far fa-circle'} mr-2 cur-pointer`} onClick={_ => setEventIndex(i)}></i>))}
                                </div>
                            </div>

                            <div className="text-white d-flex flex-column justify-content-center" style={{
                                position: 'absolute',
                                left: 20,
                                height: '100%',
                                cursor: 'pointer',
                                padding: '5x'
                            }} onClick={_ => setEventIndex(eventIndex - 1 < 0 ? events.length - 1 : eventIndex - 1)}>
                                <i className="fa fa-chevron-left"></i>
                            </div>
                            <div className="text-white d-flex flex-column justify-content-center" style={{
                                position: 'absolute',
                                right: 20,
                                height: '100%',
                                cursor: 'pointer',
                                padding: '5x'
                            }} onClick={_ => setEventIndex(eventIndex + 1 >= events.length ? 0 : eventIndex + 1)}>
                                <i className="fa fa-chevron-right"></i>
                            </div>
                        </div>
                    </div> : ''}
                </ReactPlaceholder>
                <div className="col-lg-7 col-md-6 col-12 mb-4">
                    <Card className="mb-4">
                        <Card.Header className="bg-white p-4 d-flex justify-content-between">
                            <div>
                                <h5 className="text-danger">Nuestro Foro</h5>
                                <h6>Dudas recientes</h6>
                            </div>
                            <Link to={Helpers.config.convertUrl({ url: '/foro' })} className="align-self-center">
                                <Button variant="outline-dark">
                                    Ver Más
                                </Button>
                            </Link>
                        </Card.Header>

                        <ul className="list-group">
                            <ReactPlaceholder ready={readyForo} showLoadingAnimation rows={20} className="p-3">
                                {React.Children.toArray(foro.map(f => {
                                    return <li className="list-group-item d-flex pt-4 pb-4">
                                        <div>
                                            <img src={Helpers.config.resourcesUrl({ url: f.file_photo_foro })} width="40" style={{
                                                borderRadius: 50
                                            }} />
                                        </div>
                                        <div className="d-flex flex-column pl-3" style={{ maxWidth: 170 }}>
                                            <h6 style={{ color: '#211431' }}>
                                                <strong>{f.full_name_cust}</strong>
                                            </h6>
                                            <Link to={Helpers.config.convertUrl({ url: '/foro/' + f.token_foro })}>
                                                <h6 className="font-weight-bold text-danger">{f.name_foro}</h6>
                                            </Link>
                                            <span className="f-size-11">{f.observation_foro}</span>
                                        </div>
                                    </li>
                                }))}
                            </ReactPlaceholder>
                        </ul>
                    </Card>

                    <Card>
                        <Card.Header className="bg-white p-4 d-flex justify-content-between">
                            <div>
                                <h5 className="text-danger">Noti Súper</h5>
                            </div>
                            <Link to={Helpers.config.convertUrl({ url: '/noti-historico' })} className="align-self-center">
                                <Button variant="outline-dark">
                                    Ver Más
                                </Button>
                            </Link>
                        </Card.Header>

                        <ul className="list-group">
                            <ReactPlaceholder ready={readyForo} showLoadingAnimation rows={20} className="p-3">
                                {React.Children.toArray(noti.map(f => {
                                    return <li className="list-group-item d-flex pt-4 pb-4">
                                        <div>
                                            <img src={Helpers.config.resourcesUrl({ url: f.file_images })} width="50" height="50" style={{
                                                borderRadius: 50
                                            }} />
                                        </div>
                                        <div className="d-flex flex-column pl-3" style={{ maxWidth: 170 }}>
                                            <Link to={Helpers.config.convertUrl({ url: '/noti/' + f.token_noti })}>
                                                <h6 style={{ color: '#211431' }}>
                                                    <strong>{f.name_noti}</strong>
                                                </h6>
                                            </Link>
                                            <span className="f-size-11">{f.date_created}</span>
                                        </div>
                                    </li>
                                }))}
                            </ReactPlaceholder>
                        </ul>
                    </Card>
                </div>
                <div className="col-lg-5 col-md-6 col-12 mb-4">
                    <Card>
                        <Card.Header className="bg-white p-4 d-flex justify-content-center text-center">
                            <h5 className="text-danger">Productos Nuevos</h5>
                        </Card.Header>

                        <ul className="list-group">
                            <ReactPlaceholder ready={readyProduct} showLoadingAnimation rows={20} className="p-3">
                                {React.Children.toArray(product.map(p => {
                                    return <li className="list-group-item d-flex pt-4 pb-4 cur-pointer hover-effect" onClick={handleGet} data-id-product={p.id_product}>
                                        <div>
                                            <img src={Helpers.config.resourcesUrl({ url: p.file_images })} width="50" height="50" style={{
                                                borderRadius: 50
                                            }} />
                                        </div>
                                        <div className="d-flex flex-column pl-3" style={{ maxWidth: 170 }}>
                                            <h6 style={{ color: '#211431' }}>
                                                <strong>{p.name_product}</strong>
                                            </h6>
                                            <span className="f-size-11">{p.observation_product}</span>
                                        </div>
                                    </li>
                                }))}
                            </ReactPlaceholder>
                        </ul>
                    </Card>
                </div>
            </div>
        </div>
        <div className="col-lg-3 col-md-6 col-12 mb-4">
            <Card>
                <Card.Header>
                    Resultados de Ventas Semanal
                </Card.Header>
                <ReactPlaceholder showLoadingAnimation ready={readySale}>
                    {sale.length === 0 ? <li className="list-group-item d-flex pt-4 pb-4">
                        <div className="alert alert-warning">
                            No hay ventas registradas
                        </div>
                    </li> : <ul className="list-group">
                        {React.Children.toArray(sale.map(s => {
                            let color = ''
                            switch (s.type_sale) {
                                case 'Negro':
                                    color = 'dark'
                                    break;

                                case 'Rojo':
                                    color = 'danger'
                                    break;

                                case 'Estrella':
                                    color = 'warning'
                                    break;
                            }

                            return <li className="list-group-item d-flex pt-4 pb-4">
                                <div>
                                    <img src={Helpers.config.resourcesUrl({ url: s.image_sale })} width="50" height="50" style={{
                                        borderRadius: 50
                                    }} />
                                </div>
                                <div className="d-flex flex-column pl-3" style={{ maxWidth: 170 }}>
                                    <h6 style={{ color: '#211431' }}>
                                        <strong>{s.description_sale}</strong>
                                    </h6>
                                    <span className="f-size-11">{s.amount_sale}</span>
                                    <span className="f-size-11">
                                        <div className={'badge badge-' + color}>
                                            {s.type_sale}
                                        </div>
                                    </span>
                                </div>
                            </li>
                        }))}
                    </ul>}
                </ReactPlaceholder>
            </Card>
        </div>
        <div className="col-lg-3 col-md-6 col-12">
            <Calendar
                onChange={onChange}
                value={value}
            />
            <Card style={{
                minWidth: 250
            }} className="mt-4 mb-4">
                <Card.Header className="bg-danger text-white" style={{
                    background: `url('${Helpers.config.resourcesUrl({ url: '/images/dashboard/cumple2.png' })}')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right'
                }}>
                    Próximos Cumpleaños
                </Card.Header>

                <ul className="list-group">
                    {birthday.length === 0 ? <li className="list-group-item d-flex pt-4 pb-4">
                        <div className="alert alert-warning">
                            No hay cumpleaños cercanos.
                        </div>
                    </li> : <ReactPlaceholder ready={readyBirthday} showLoadingAnimation rows={20} className="p-3">
                        {React.Children.toArray(birthday.map(b => <li className="list-group-item d-flex pt-4 pb-4">
                            <div>
                                <img src={Helpers.config.resourcesUrl({ url: b.file_photo_cust })} width="40" style={{
                                    borderRadius: 50
                                }} />
                            </div>
                            <div className="d-flex flex-column pl-3" style={{ maxWidth: 170 }}>
                                <h6 style={{ color: '#211431' }}>
                                    <strong>{b.full_name_cust}</strong>
                                </h6>
                                <span className="f-size-11">{b.email_cust}</span>
                                <span>
                                    <i className="fas fa-birthday-cake mr-1"></i>{b.date_birthday_cust}
                                    <span className={`badge badge-${b.day === 'today' ? 'success' : (b.day === 'tomorrow' ? 'warning' : 'info')} ml-2`}>
                                        {b.day === 'today' ? 'Hoy' : (b.day === 'tomorrow' ? 'Mañana' : 'Pronto')}
                                    </span>
                                </span>
                            </div>
                        </li>))}
                    </ReactPlaceholder>}
                </ul>
            </Card>
        </div>
        <Modal show={modalShow} onHide={_ => setModalShow(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title as="div">Información de Producto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProductForm showPreview {...dataUpdated} />
            </Modal.Body>
        </Modal>
    </div>
}

export default Dashboard