const boxShadow = '0 1px 5px 0 rgba(0,0,0,.2)'
const primary = '#FF152A'
const bgPrimaryLight = `rgba(55,106,135, .5)`
const primaryGradient = `linear-gradient(118deg, ${primary}, rgba(255, 255, 255))`
const widthFull = '280px'
const widthResize = '60px'
const widthWithPadding = '240px'
const widthResizeWithPadding = '40px'
const heightHeader = '52px'
const heightFooter = '20px'

export {
  boxShadow, primary, bgPrimaryLight, primaryGradient, widthFull, widthResize, heightHeader, heightFooter, widthWithPadding, widthResizeWithPadding
}