import React, {useRef, useState, useMemo} from 'react'
import Card from 'react-bootstrap/Card';
import JoditEditor from "jodit-react";
import { FormCustom } from '../../Components/Form/Form'
import { useEffect } from 'react';
import Controllers from '../../Api/Controllers';
import { useCallback } from 'react';
import Helpers from '../../Components/Helpers/Helpers';
import {toast} from 'react-toastify'
import ReactPlaceholder from 'react-placeholder/lib';
import Button from 'react-bootstrap/esm/Button';
import { useHistory } from 'react-router-dom';

const Privacidad = ({ readonly }) => {
    const [ privacidad, setPrivacidad ] = useState({})
    const [ready, setReady] = useState(false)

    const history = useHistory()

    const config = useMemo(() => {
        if (readonly) {
            return {
                minHeight: 450,
                preset: 'inline',
                readonly
            }
        } else {
            return {
                minHeight: 450,
                language: 'es',
                askBeforePasteHTML: false,
                askBeforePasteFromWord: false,
            }
        }
    }, [readonly])

    useEffect(() => {
        Controllers.text.get_privacidad().then(res => {
            setPrivacidad(res.data)
            setReady(true)
        })
    }, [])

    const editorPrivacidad = useRef(null)

    const contentHTMLPrivacidad = useMemo(() => {
        return <>
        <JoditEditor
            ref={editorPrivacidad}
            value={privacidad?.content_text ? privacidad.content_text : ''}
            config={config}
            tabIndex={1}
            onBlur={_ => {
                setPrivacidad({ ...privacidad, content_text: editorPrivacidad.current.value })
            }}
        /></>
    }, [editorPrivacidad, config, privacidad])

    const dataSubmit = useCallback(() => {
        return {
            p_privacidad: privacidad,
        }
    }, [privacidad])

    const [loadSubmit, setLoadSubmit] = useState(false)

    const handleSubmit = ({ data }) => {
        setLoadSubmit(true)
        Controllers.text.privacidad_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast, message: 'Cambios guardados correctamente.' })
            history.push(Helpers.config.convertUrl({ url: '/privacidad' }))
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
        })
    }

    return <>
        <Card>
            <Card.Header className="bg-danger text-white">
                Privacidad
            </Card.Header>
            <Card.Body>
                <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
                <FormCustom dataSubmit={dataSubmit()} onSubmit={handleSubmit} loadSubmit={loadSubmit} viewSubmit={false}>
                    <div className={'form-row mb-2 col-12 col-md-' + (readonly ? '12' : '9')}>
                        <div className="col-12">
                            <h1 className="text-center">Privacidad</h1>
                            {contentHTMLPrivacidad}
                        </div>
                    </div>
                    {readonly ? '' : <div className="col-12 col-md-3 bg-secondary p-3">
                        <Card>
                            <Card.Header>
                                Actualizar
                            </Card.Header>
                            <Card.Body>
                                <p>
                                    Da click en el botón para guardar los cambios realizados.
                                </p>
                                <Button type="submit" variant="primary btn-gradient-primary f-size-11" size="lg" block>
                                    <i className="fa fa-plus mr-1"></i>Guardar Cambios
                                </Button>
                            </Card.Body>
                        </Card>
                    </div>}
                </FormCustom>
                </ReactPlaceholder>
            </Card.Body>
        </Card>
    </>
}

Privacidad.defaultProps = {
    readonly: false
}

export default Privacidad