import React, { useCallback, useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, SwitchToggle, CheckBox } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'

const ForoForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_foro: data.id_foro,
        p_name_foro: data.name_foro,
        p_state_foro: data.state_foro,
        p_file_photo_foro: data.file_photo_foro,
        p_observation_foro: data.observation_foro,
        p_categories: data.categories
    })

    const [photo, setPhoto] = useState(data?.file_photo_foro ? Helpers.config.resourcesUrl({ url: data.file_photo_foro }) : '')

    const [ready, setReady] = useState(false)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        Controllers.category.get_category().then(res => {
            setCategories(res.data)
            setReady(true)
        })
    }, [])

    const dataSubmit = useCallback(() => {
        let formData = new FormData()

        for (const key in row) {
            if (key === 'p_categories') {
                formData.append(key, JSON.stringify(row[key]))
                continue
            }

            if (key === 'p_file_photo_foro') {
                if (row.p_file_photo_foro?.files) {
                    if (row.p_file_photo_foro.files.length > 0) {
                        formData.append('p_file_photo_foro', row.p_file_photo_foro.files[0])
                        continue
                    }
                }
                formData.append('p_file_photo_foro', '')
                continue
            }
            formData.append(key, row[key])
        }

        return formData
    }, [row])

    return <ReactPlaceholder ready={ready} rows={15} showLoadingAnimation>
        <FormCustom dataSubmit={dataSubmit()} onSubmit={onSubmit} viewSubmit={false} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <Input
                text="Nombres"
                placeholder="Nombres"
                classNameParent="col-12 mt-2"
                required
                invalid="El campo es obligatorio."
                value={row.p_name_foro}
                maxLength={255}
                onChange={e => setRow({ ...row, p_name_foro: e.currentTarget.value })}
            />
            <div className="col-12 mb-2">
                <img className="img-thumbnail mb-2" src={photo ? photo : Helpers.config.resourcesUrl({ url: '/images/dashboard/sin_imagen.png' })} width="150" />
                <Input
                    type="file"
                    classNameParent=""
                    required={isNaN(parseInt(data?.id_foro))}
                    invalid="El campo es obligatorio."
                    accept="image/*"
                    onChange={e => {
                        setRow({ ...row, p_file_photo_foro: e.currentTarget })
                        if (e.currentTarget.files.length > 0) {
                            let selectedFile = e.currentTarget.files[0]
                            let reader = new FileReader()

                            reader.onload = function(event) {
                                setPhoto(event.target.result)
                            }
                            reader.readAsDataURL(selectedFile)
                        } else {
                            setPhoto('')
                        }
                    }}
                />
            </div>
            <div className="col-12 mb-2">
                <hr className="border-light" />
            </div>
            <div className="col-12 form-row">
                <div className="col-12">
                    <p>Departamentos Relacionadas</p>
                </div>
                {React.Children.toArray(categories.map(a => {
                    return <CheckBox
                        id={'chk-category-' + a.id_category}
                        text={a.name_category}
                        checked={row.p_categories.some(aa => parseInt(aa.id_category) === parseInt(a.id_category))}
                        onChange={e => {
                            if (e.currentTarget.checked) {
                                setRow({ ...row, p_categories: [ ...row.p_categories, a ] })
                            } else {
                                setRow({ ...row, p_categories: row.p_categories.filter(aa => parseInt(aa.id_category) !== parseInt(a.id_category)) })
                            }
                        }}
                        required={row.p_categories.length === 0}
                    />
                }))}
            </div>
            <div className="col-12 mb-2">
                <hr className="border-light" />
            </div>
            <Input
                text="Observación"
                placeholder="Observación"
                classNameParent="col-12"
                required
                invalid="El campo es obligatorio."
                value={row.p_observation_foro}
                maxLength={255}
                onChange={e => setRow({ ...row, p_observation_foro: e.currentTarget.value })}
            />
            <div className="col-12 text-center">
                <Button type="submit" variant="primary btn-gradient-primary">
                    {loadSubmit ? <i className="fa fa-circle-notch fa-spin"></i> : 'Guardar'}
                </Button>
            </div>
        </FormCustom>
    </ReactPlaceholder>
}

ForoForm.defaultProps = {
    data: {
        id_foro: '',
        name_foro: '',
        file_photo_foro: '',
        observation_foro: '',
        categories: [],
        state_foro: 1,
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default ForoForm;