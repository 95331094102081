import React, { useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import { useEffect } from 'react';
import { useState } from 'react';
import ReactPlaceholder from 'react-placeholder/lib';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { Link, useParams } from 'react-router-dom'
import { FormCustom, Input, Select, TextArea } from '../../Components/Form/Form';
import Button from 'react-bootstrap/esm/Button';
import { useSelector } from 'react-redux';
import {toast} from 'react-toastify'

const VideoHistory = _ => {
    const [readyCategory, setReadyCategory] = useState(false)
    const [readyVideos, setReadyVideos] = useState(false)
    const [videos, setVideos] = useState([])
    const [categories, setCategories] = useState([])
    const [formFilter, setFormFilter] = useState({
        p_id_category: '',
        p_filter_text: ''
    })
    const [row, setRow] = useState({
        p_description_comment: '',
    })
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [reloadComments, setReloadComments] = useState(false)

    const store = useSelector(res => res)

    const [readyVideo, setReadyVideo] = useState(false)
    const [video, setVideo] = useState({})

    const { tokenVideo } = useParams()
    const [hasToken, setHasToken] = useState(tokenVideo !== undefined)

    useEffect(() => {
        setHasToken(tokenVideo !== undefined)
    }, [tokenVideo])

    const category = useMemo(() => {
        if (formFilter.p_id_category === '') {
            return {
                label: 'Todas',
                value: ''
            }
        }
        
        let finded = categories.find(j => parseInt(j.id_category) === parseInt(formFilter.p_id_category))

        if (!finded) {
            return
        }

        return {
            label: finded.name_category,
            value: finded.id_category
        }
    }, [formFilter.p_id_category, categories])

    const [videosRelated, setVideosRelated] = useState([])
    useEffect(() => {
        if (tokenVideo && hasToken) {
            Promise.all([
                Controllers.video.get_video_token({
                    p_token_video: tokenVideo
                }),
                Controllers.video.get_video_related({
                    p_token_video: tokenVideo
                })
            ]).then(res => {
                setRow({ ...row, p_id_video: res[0].data?.id_video })
                setVideo(res[0].data)
                setVideosRelated(res[1].data)
                setReadyVideo(true)
            })
        }
    }, [ tokenVideo, hasToken ])

    const [comments, setComments] = useState([])
    useEffect(() => {
        if (reloadComments || hasToken) {
            Controllers.comment.get_comment_video({
                p_token_video: tokenVideo
            }).then(res => {
                setComments(res.data)
                setReloadComments(false)
            })
        }
    }, [ tokenVideo, reloadComments, hasToken ])

    useEffect(() => {
        if (!readyVideos && !hasToken) {
            Controllers.course.get_course_public(formFilter).then(res => {
                setVideos(res.data)
                setReadyVideos(true)
            })
        }
    }, [ readyVideos, formFilter, hasToken ])

    useEffect(() => {
        Controllers.category.get_category().then(res => {
            setCategories([
                { id_category: '', name_category: 'Todas' },
                ...res.data,
            ])
            setReadyCategory(true)
        })
    }, [])

    const handleSubmitComment = ({ data }) => {
        setLoadSubmit(true)
        Controllers.comment.comment_video_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            setRow({ ...row, p_description_comment: '' })
            setReloadComments(true)
            setLoadSubmit(false)
        })
    }

    return <>
        <Card>
            <Card.Header className="bg-danger text-white">
                Videos
            </Card.Header>
            <Card.Body className="form-row">
                <div className="col-12">
                    <ReactPlaceholder ready={readyCategory} rows={5} showLoadingAnimation>
                        <FormCustom viewSubmit={false} onSubmit={_ => {
                            setHasToken(false)
                            setReadyVideos(false)
                        }}>
                            <Select
                                text="Departamento"
                                classNameParent="col-4"
                                options={categories.map(j => {
                                    return {
                                        label: j.name_category,
                                        value: j.id_category
                                    }
                                })}
                                value={category}
                                onChange={e => setFormFilter({ ...formFilter, p_id_category: e.value })}
                            />
                            <div className="col-8" style={{
                                marginTop: '13px'
                            }}>
                                <Input
                                    text="Buscar"
                                    onChange={e => setFormFilter({ ...formFilter, p_filter_text: e.currentTarget.value })}
                                    append={[
                                        <Button type="submit">
                                            <i className="fa fa-search"></i>
                                        </Button>
                                    ]}
                                />
                            </div>
                        </FormCustom>
                    </ReactPlaceholder>
                </div>
                <div className="col-12 bg-secondary p-4">
                    {hasToken ? (video?.id_video || !readyVideo ? <ReactPlaceholder ready={readyVideo} rows={20} showLoadingAnimation>
                        <div className="form-row">
                            <div className="col-12 mb-2">
                                <h6>Curso: <strong>{video.name_course}</strong></h6>
                                <small>{video.description_course}</small>
                            </div>
                            <div className="col-12 col-md-8 mb-2">
                                {parseInt(video?.has_video) === 1 && video?.file_video ? <video controls width="100%" style={{
                                    maxHeight: 400
                                }}>
                                    <source type="video/mp4" src={Helpers.config.resourcesUrl({ url: video.file_video })} />
                                </video> : ''}
                                <Card>
                                    <Card.Header className="bg-white">
                                        <h5 className="font-weight-bold text-danger">{video.name_video}</h5>
                                        <span>
                                            {video.description_video}
                                        </span>
                                    </Card.Header>
                                    <Card.Header className="bg-white">
                                        <h6>{video.name_category}</h6>
                                        <span className="font-weight-bold">
                                            {video.date_created}
                                        </span>
                                    </Card.Header>
                                    {video.additional?.length > 0 ? <Card.Header className="bg-white">
                                        <h6>Material Adicional</h6>
                                        {video.additional ? React.Children.toArray(video.additional.map(a => {
                                            return <div className="d-flex mb-2">
                                                <div className="align-self-center pr-2">
                                                    <a target="_blank" href={Helpers.config.resourcesUrl({ url: a.file_add })}>
                                                        <Button size="sm">
                                                            <i className="fa fa-file"></i>
                                                        </Button>
                                                    </a>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <strong>{a.name_add}</strong>
                                                    <span>{a.description_add}</span>
                                                </div>
                                            </div>
                                        })) : ''}
                                    </Card.Header> : ''}
                                    <Card.Body className="d-flex flex-column">
                                        <div className="d-flex">
                                            <img src={store.session.file_photo_user} style={{
                                                borderRadius: '50px'
                                            }} width="30" className="mr-3 align-self-center" />
                                            <h5 className="bg-secondary p-2 align-self-center">Tu Comentario:</h5>
                                        </div>
                                        <FormCustom className="mb-4" viewSubmit={false} dataSubmit={row} onSubmit={handleSubmitComment}>
                                            <TextArea text="Comentario" classNameParent="col-12 mt-4" rows="5" required value={row.p_description_comment} onChange={e => setRow({ ...row, p_description_comment: e.currentTarget.value })} />
                                            <div className="col-12 mt-3">
                                                <Button type="submit">
                                                    {loadSubmit ? <i className="fa fa-circle-notch fa-spin"></i> : <><i className="fa fa-check mr-1"></i>Enviar</>}
                                                </Button>
                                            </div>
                                        </FormCustom>
                                        {React.Children.toArray(comments.map(c => {
                                            return <div className="d-flex flex-column">
                                                <div className="d-flex">
                                                    <div>
                                                        <img src={Helpers.config.resourcesUrl({ url: c.file_photo_cust })} width="30" style={{
                                                            borderRadius: 50
                                                        }} />
                                                    </div>
                                                    <div className="d-flex flex-column pl-2">
                                                        <h6 className="text-dark font-weight-bold">{c.full_name_cust}</h6>
                                                        <p>{c.description_comment}</p>
                                                    </div>
                                                </div>
                                                <hr className="bg-light" />
                                            </div>
                                        }))}
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-12 col-md-4">
                                <Card>
                                    <Card.Header className="bg-danger text-white">
                                        <strong>Videos Relacionados</strong>
                                    </Card.Header>
                                    {React.Children.toArray(videosRelated.map(v => {
                                        return <Card.Body>
                                            <div className="d-flex flex-column">
                                                <Link onClick={_ => {
                                                    setHasToken(true)
                                                    setReadyVideo(false)
                                                }} to={Helpers.config.convertUrl({ url: '/buscador-de-videos/' + v.token_video })} className="d-flex">
                                                    <img src={Helpers.config.resourcesUrl({ url: v.file_images })} style={{
                                                        width: 50,                                                        height: 50,
                                                        borderRadius: 50
                                                    }} />
                                                    <div className="bg-white align-self-center pl-3">
                                                        <h6 className="text-danger font-weight-bold">{ v.name_video }</h6>
                                                    </div>
                                                </Link>
                                                <div className="mt-2 d-flex flex-column">
                                                    <span>{v.description_video}</span>
                                                    <strong className="mt-1">{v.date_created}</strong>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    }))}
                                </Card>
                            </div>
                        </div>
                    </ReactPlaceholder> : <div className="alert alert-warning">
                            <strong>¡Lo sentimos!</strong> No hay información para mostrar.
                        </div>) : <ReactPlaceholder ready={readyVideos} rows={20} showLoadingAnimation>
                        <h5 className="mb-4">Todos</h5>
                        {videos.length > 0 ? <div className="form-row">
                            {React.Children.toArray(videos.map(v => {
                                return <section className="col-12 col-sm-6 col-md-3 mb-4">
                                    {v.categories.includes(store.session.id_category) ? <Link onClick={_ => {
                                        setHasToken(true)
                                        setReadyVideo(false)
                                    }} to={Helpers.config.convertUrl({ url: '/buscador-de-videos/' + v.token_video })}>
                                        <img src={Helpers.config.resourcesUrl({ url: v.file_images })} width="100%" height="150" />
                                        <div className="bg-white p-3">
                                            <h6 className="text-dark text-center">{ v.name_course }</h6>
                                        </div>
                                    </Link> : <div style={{
                                        opacity: '.5',
                                        cursor: 'not-allowed'
                                    }}>
                                        <img src={Helpers.config.resourcesUrl({ url: v.file_images })} width="100%" height="150" />
                                        <div className="bg-white p-3 d-flex flex-column align-items-center justify-content-center" style={{
                                            minHeight: 70
                                        }}>
                                            <h6 className="text-dark text-center">{ v.name_course }</h6>
                                            <strong className="text-danger">No pertence a tu área</strong>
                                        </div>
                                    </div>}
                                </section>
                            }))}
                        </div> : <div className="alert alert-warning">
                            <strong>¡Lo sentimos!</strong> No hay información para mostrar.
                        </div>}
                    </ReactPlaceholder>}
                </div>
            </Card.Body>
        </Card>
    </>
}

export default VideoHistory