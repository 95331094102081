import React, { useMemo } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPlaceholder from 'react-placeholder/lib';
import { useParams } from 'react-router-dom';
import Controllers from '../../Api/Controllers';
import parse from 'html-react-parser';
import Helpers from '../../Components/Helpers/Helpers';
import Button from 'react-bootstrap/esm/Button';
import { FormCustom, TextArea } from '../../Components/Form/Form';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Card from 'react-bootstrap/esm/Card';
import CommentForo from '../Foro/CommentForo';

const ForoBlog = () => {
    const [foro, setForo] = useState({})
    const [ready, setReady] = useState(false)
    const [readyComments, setReadyComments] = useState(false)
    const [reloadComments, setReloadComments] = useState(false)
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [row, setRow] = useState({
        p_id_foro: '',
        p_description_comment: ''
    })

    const store = useSelector(res => res)
    const [comments, setComments] = useState([])

    const { tokenForo } = useParams()

    useEffect(() => {
        setReady(false)
        Controllers.foro.get_foro_token({
            p_token_foro: tokenForo
        }).then(res => {
            setRow({ ...row, p_id_foro: res.data?.id_foro })
            setForo(res.data)
            setReady(true)
        })
    }, [tokenForo])

    useEffect(() => {
        setReadyComments(true)
        Controllers.comment.get_comment_foro({
            p_token_foro: tokenForo
        }).then(res => {
            setComments(res.data)
            setReloadComments(false)
            setReadyComments(true)
        })
    }, [tokenForo, reloadComments])

    const handleSubmitComment = ({ data }) => {
        setLoadSubmit(true)
        Controllers.comment.comment_foro_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            setRow({ ...row, p_description_comment: '' })
            setReloadComments(true)
            setLoadSubmit(false)
        })
    }

    return <Card>
        <Card.Header className="bg-danger text-white">
            Tema del Foro
        </Card.Header>
        <Card.Body>
            <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
                {foro?.name_foro ? <>
                    <div className="form-row">
                        <div className="col-12">
                            <div className="d-flex">
                                <div className="align-self-center mr-4">
                                    <img src={Helpers.config.resourcesUrl({ url: foro.file_photo_foro })} width="80" height="80" style={{
                                        borderRadius: 50
                                    }} />
                                </div>
                                <div>
                                    <h1>{foro.name_foro}</h1>
                                    <>
                                        {parse(`${foro.observation_foro}`)}
                                    </>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div className="col-12 bg-secondary form-row p-4">
                            <div className="col-12 col-md-4 mb-2">
                                <Card>
                                    <Card.Body>
                                        <div className="d-flex">
                                            <img src={store.session.file_photo_user} style={{
                                                borderRadius: '50px'
                                            }} width="30" className="mr-3 align-self-center" />
                                            <h5 className="bg-secondary p-2 align-self-center">Tu Comentario:</h5>
                                        </div>
                                        <FormCustom viewSubmit={false} dataSubmit={row} onSubmit={handleSubmitComment}>
                                            <TextArea text="Comentario" classNameParent="col-12 mt-4" rows="5" required value={row.p_description_comment} onChange={e => setRow({ ...row, p_description_comment: e.currentTarget.value })} />
                                            <div className="col-12 mt-3">
                                                <Button type="submit">
                                                    {loadSubmit ? <i className="fa fa-circle-notch fa-spin"></i> : <><i className="fa fa-check mr-1"></i>Enviar</>}
                                                </Button>
                                            </div>
                                        </FormCustom>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-12 col-md-8">
                                {comments.length === 0 ? <div className="alert alert-warning">
                                    <strong>¡Sé el primero!</strong> Deja tu comentario.
                                </div> : <ReactPlaceholder className="mb-2" ready={readyComments} showLoadingAnimation rows={15}>
                                    {React.Children.toArray(comments.map(comment => {
                                        return <CommentForo comment={comment} onSuccess={_ => setReloadComments(true)} />
                                    }))}
                                </ReactPlaceholder>}
                            </div>
                        </div>
                    </div>
                </> : <>
                    <div className="alert alert-warning">
                        <strong>¡Lo sentimos!</strong> No se ha encontrado información.
                    </div>
                    <Link to={Helpers.config.convertUrl({ url: '/foro' })}>
                        <Button>
                            <i className="fa fa-arrow-left mr-1"></i>Regresar
                        </Button>
                    </Link>
                </>}
            </ReactPlaceholder>
        </Card.Body>
    </Card>
}

export default ForoBlog