import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import ImagesForm from './ImagesForm'
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import { FormCustom, Input, Select } from '../../Components/Form/Form';
import ReactPlaceholder from 'react-placeholder/lib';

const ImagesModal = ({ modalShow, handleHideModalForm, addImage }) => {

    const [ready, setReady] = useState(false)
    const [listImages, setListImages] = useState([])
    const [reload, setReload] = useState(true)

    useEffect(() => {
        if (reload) {
            setReady(false)
            Controllers.images.get_images().then(res => {
                setListImages(res.data)
                setReady(true)
                setReload(false)
            })
        }
    }, [reload])

    return <>
        <Modal show={modalShow} onHide={handleHideModalForm} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">Imagenes Multimedia</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ImagesForm callbackSuccess={() => setReload(true)} />
                <div className="form-row">
                    <Card className="mt-4 col-12">
                        <Card.Header>
                            <i className="fa fa-list mr-1"></i>Listado de Imágenes
                        </Card.Header>
                        <Card.Body>
                            <ReactPlaceholder ready={ready} showLoadingAnimation>
                                <div className="form-row">
                                    {React.Children.toArray(listImages.map(l => {
                                        return <div className="col-6 col-md-4" onClick={_ => {
                                            addImage(l)
                                            handleHideModalForm()
                                        }}>
                                            <img className="img-thumbnail hover-effect cur-pointer" src={Helpers.config.resourcesUrl({ url: l.file_images })} />
                                        </div>
                                    }))}
                                </div>
                            </ReactPlaceholder>
                        </Card.Body>
                    </Card>
                </div>
            </Modal.Body>
        </Modal>
    </>
}

export default ImagesModal