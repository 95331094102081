import React, { useState, useCallback } from 'react'
import { Input, CheckBox } from '../../Form/Form'
import Button from 'react-bootstrap/Button'
import Box from './Styled/Box'
import Controllers from '../../../Api/Controllers'
import Helpers from '../../Helpers/Helpers'
import { toast } from 'react-toastify';

const Login = () => {
  const [session, setSession] = useState({
    id_nomina: Helpers.config.isDeveloperWeb() ? '1' : '',
    password: Helpers.config.isDeveloperWeb() ? '1234' : '',
  })

  const dataSubmit = useCallback(() => {
    return {
      p_id_nomina: session.id_nomina,
      p_password: session.password,
    }
  }, [session])

  const handleSubmit = e => {
    e.preventDefault()
    let $this = e.target

    if (!$this.checkValidity()) {
      $this.classList.add('was-validated')
    } else {
      $this.classList.remove('was-validated')

      let button = $this.querySelector('button');
      let buttonHTML = button.innerHTML;
      button.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'

      Controllers.users.get_login(dataSubmit()).then(res => {
        Helpers.toast.construct({ ...res, toast });
        if (res.response === 'success') {
          Helpers.localStorage.setSession({
            jwt: res.data.jwt
          });
          window.location.href = Helpers.config.folder_path;
        } else {
          button.innerHTML = buttonHTML;
        }
      }).catch(req => {
        Helpers.promise.catch({ req, toast });
        button.innerHTML = buttonHTML;
      });
    }
  }

  return <>
    <div className="d-flex">
      {Helpers.config.isCustomers() ? <div className="d-none d-md-block" style={{
        background: `url(${Helpers.config.resourcesUrl({ url: '/images/dashboard/background-auth.png' })})`,
        height: '100vh',
        width: '100%',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat'
      }}>

      </div> : ''}

      <Box backgroundImage={Helpers.config.resourcesUrl({ url: '/images/dashboard/background-auth-2.png' })}>
        <Box.Container>
          <Box.Content className="form-row justify-content-center">
            <div className="col-12 text-center mb-5">
              <Box.Logo src={Helpers.config.resourcesUrl({ url: '/images/dashboard/logo-2.png' })} className="box-bg-content-logo" alt="" />
            </div>

            <form onSubmit={handleSubmit} autoComplete="off" className="col-12 needs-validation" noValidate>
              <div className="form-row">
                <Input
                  text="Número Empleado"
                  classNameParent="col-12 mb-3"
                  required
                  defaultValue={session.id_nomina}
                  onChange={e => setSession({ ...session, id_nomina: e.currentTarget.value })}
                  size=""
                  smallColor="text-white"
                  className="text-white"
                /* type="number"
                pattern="[0-9]{1,11}"
                min="1" */
                />
                <Input type="password"
                  text="Contraseña"
                  classNameParent="col-12 mb-2"
                  required
                  defaultValue={session.password}
                  onChange={e => setSession({ ...session, password: e.currentTarget.value })}
                  size=""
                  maxLength="30"
                  smallColor="text-white"
                  className="text-white"
                />
                <CheckBox text="Recordarme" defaultChecked={false} classNameParent="col-12 text-white" id="remember_me" />
                <div className="col-12 mt-4">
                  <Button type="submit" size="" block style={{
                    height: '50px'
                  }}>
                    INGRESAR
                  </Button>
                </div>
              </div>
            </form>

          </Box.Content>
        </Box.Container>
      </Box>
    </div>
  </>
}

export default Login