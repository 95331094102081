import styled from 'styled-components';

const Box = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-color: #fff;
  background: url(${props => props.backgroundImage}) right no-repeat;
  background-position: right;
  display: flex;
  align-items: center;
  height: 100vh;

  width: 100%;
  padding: 100px;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 15px;
  }
`

Box.Container = styled.div`
  max-width: 400px;
`

Box.Content = styled.div`
  border-radius: 20px;
  padding: 30px;
  @media (max-width: 768px) {
    padding: 15px;
  }
`

Box.Logo = styled.img`
  max-width: 200px;
`

export default Box