import React, { useState } from 'react'
import { Input, FormCustom, SwitchToggle } from '../../Components/Form/Form'

const JobForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_job: data.id_job,
        p_name_job: data.name_job,
        p_state_job: data.state_job
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Descripción"
            placeholder="Descripción"
            classNameParent="col-12 mt-2 mb-2"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.name_job}
            onChange={e => setRow({ ...row, p_name_job: e.currentTarget.value })}
            maxLength={150}
        />
        <SwitchToggle
            classNameParent="col-12 mb-2"
            id="job_state"
            defaultChecked={parseInt(row.p_state_job) === 1}
            onChange={e => setRow({ ...row, p_state_job: e.currentTarget.checked ? 1 : 0 })}
        />
    </FormCustom>
}

JobForm.defaultProps = {
    data: {
        id_job: '',
        name_job: '',
        state_job: 1,
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default JobForm;