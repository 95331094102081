import React, { useMemo } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPlaceholder from 'react-placeholder/lib';
import { useParams } from 'react-router-dom';
import Controllers from '../../Api/Controllers';
import parse from 'html-react-parser';
import Helpers from '../../Components/Helpers/Helpers';
import Button from 'react-bootstrap/esm/Button';
import { FormCustom, TextArea } from '../../Components/Form/Form';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CommentEvent from './CommentEvent';

const EventBlog = () => {
    const [event, setEvent] = useState({})
    const [ready, setReady] = useState(false)
    const [readyComments, setReadyComments] = useState(false)
    const [reloadComments, setReloadComments] = useState(true)
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [row, setRow] = useState({
        p_id_event: '',
        p_description_comment: ''
    })

    const store = useSelector(res => res)
    const [comments, setComments] = useState([])

    const { tokenEvent } = useParams()

    useEffect(() => {
        setReady(false)
        Controllers.event.get_event_token({
            p_token_event: tokenEvent
        }).then(res => {
            setRow({ ...row, p_id_event: res.data?.id_event })
            setEvent(res.data)
            setReady(true)
        })
    }, [tokenEvent])

    useEffect(() => {
        setReadyComments(true)
        Controllers.comment.get_comment_event({
            p_id_event: tokenEvent
        }).then(res => {
            setComments(res.data)
            setReloadComments(false)
            setReadyComments(true)
        })
    }, [tokenEvent, reloadComments])

    const handleSubmitComment = ({ data }) => {
        setLoadSubmit(true)
        Controllers.comment.comment_event_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            setRow({ ...row, p_description_comment: '' })
            setReloadComments(true)
            setLoadSubmit(false)
        })
    }

    const qtyComments = useMemo(() => {
        let qty = comments.length

        comments.forEach(c => {
            qty += parseInt(c.comments_child.length)
        })
        return qty
    }, [comments])

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
        {event?.name_event ? <>
            <h1>{event.name_event}</h1>
            <p>
                <img className="img-thumbnail" src={Helpers.config.resourcesUrl({ url: event.file_images })} />
            </p>
            <hr />
            <p className="content_article_preview">
                {parse(`${event.content_event}`)}
            </p>
            <hr />
            <ReactPlaceholder className="mb-2" ready={readyComments} showLoadingAnimation rows={1}>
                <h2>
                    <strong>{qtyComments} Comentarios</strong>
                </h2>
            </ReactPlaceholder>
            <div className="d-flex">
                <img src={store.session.file_photo_user} style={{
                    borderRadius: '50px'
                }} width="30" className="mr-3 align-self-center" />
                <h5 className="bg-secondary p-2 align-self-center">Tu Comentario:</h5>
            </div>
            <FormCustom viewSubmit={false} dataSubmit={row} onSubmit={handleSubmitComment}>
                <TextArea text="Comentario" classNameParent="col-12 mt-4" rows="5" required value={row.p_description_comment} onChange={e => setRow({ ...row, p_description_comment: e.currentTarget.value })} />
                <div className="col-12 mt-3">
                    <Button type="submit">
                        {loadSubmit ? <i className="fa fa-circle-notch fa-spin"></i> : <><i className="fa fa-check mr-1"></i>Enviar</>}
                    </Button>
                </div>
            </FormCustom>
            <hr />
            <ReactPlaceholder className="mb-2" ready={readyComments} showLoadingAnimation rows={15}>
                {React.Children.toArray(comments.map(c => {
                    return <CommentEvent comment={c} onSuccess={() => setReloadComments(true)} />
                }))}
            </ReactPlaceholder>
        </> : <>
            <div className="alert alert-warning">
                <strong>¡Lo sentimos!</strong> No se ha encontrado información.
            </div>
            <Link to={Helpers.config.convertUrl({ url: '/evento-historico' })}>
                <Button>
                    <i className="fa fa-arrow-left mr-1"></i>Regresar
                </Button>
            </Link>
        </>}
    </ReactPlaceholder>
}

export default EventBlog