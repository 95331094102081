import React, { useState, useMemo } from 'react'
import { useEffect } from 'react'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, SwitchToggle, Select, TextArea } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/esm/Button'
import ImagesModal from '../Images/ImagesModal'

const BitacoraForm = ({ data, disablebSubmit, idCustomers, onSuccess }) => {
    const [row, setRow] = useState({
        p_id_bitacora: data.id_bitacora,
        p_id_customers: idCustomers,
        p_period_bita: data.period_bita,
        p_event_bita: data.event_bita,
        p_impact_bita: data.impact_bita,
        p_date_bita: data.date_bita,
        p_criterion_bita: data.criterion_bita,
        p_feedback_bita: data.feedback_bita,
    })

    const [ready, setReady] = useState(false)
    const [customer, setCustomer] = useState({})
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [periods, setPeriods] = useState([])
    
    useEffect(() => {
        Promise.all([
            Controllers.customers.get_customers_id({
                p_id_customers: idCustomers
            }),
            Controllers.period.get_period()
        ]).then(res => {
            setCustomer(res[0].data)
            setPeriods(res[1].data)
            setReady(true)
        })
    }, [idCustomers])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.bitacora.bitacora_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                window.scrollTo(0, 0)
                setLoadSubmit(false)
                onSuccess()
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
    }

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
        <FormCustom dataSubmit={row} viewSubmit={false} onSubmit={({ data}) => {
            if (!row.p_period_bita) {
                Helpers.toast.construct({ response: 'warning', message: 'Seleccionar Periódo', toast })
                return
            }
            if (!row.p_impact_bita) {
                Helpers.toast.construct({ response: 'warning', message: 'Seleccionar Impacto', toast })
                return
            }

            handleInsertUpdate({ data })
        }} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <Input
                text="Nombre"
                placeholder="Nombre"
                classNameParent="col-12 mt-2"
                required
                invalid="El campo es obligatorio."
                value={customer.full_name_cust}
                disabled
            />
            <Input
                text="Num. Nómina"
                placeholder="Num. Nómina"
                classNameParent="col-12 col-md-6 mt-2"
                required
                invalid="El campo es obligatorio."
                value={customer.id_nomina}
                disabled
                className="text-center"
            />
            <Input
                text="Sucursal"
                placeholder="Sucursal"
                classNameParent="col-12 col-md-6 mt-2"
                required
                invalid="El campo es obligatorio."
                value={customer.name_branch_office}
                disabled
            />
            <Select
                text="Periódo de Evaluación"
                classNameParent="col-12 col-md-6 mt-0"
                options={periods.map(p => {
                    return {
                        label: p.name_period,
                        value: p.id_period
                    }
                })}
                onChange={e => setRow({ ...row, p_period_bita: e.value })}
            />
            <Input
                text="Departamento"
                placeholder="Departamento"
                classNameParent="col-12 col-md-6 mt-3"
                required
                invalid="El campo es obligatorio."
                value={customer.name_category}
                disabled
            />
            <div className="col-12 mb-3">
                <hr className="border-light" />
            </div>
            <TextArea
                text="Suceso"
                rows="5"
                onChange={e => setRow({ ...row, p_event_bita: e.currentTarget.value })}
                defaultValue={data.event_bita}
                required
                invalid="El campo es obligatorio."
            />
            <div className="col-12">
                <hr className="border-light" />
            </div>
            <Select
                text="Impacto"
                classNameParent="col-12"
                options={[
                    { label: 'BAJO', value: 'BAJO' },
                    { label: 'MEDIO', value: 'MEDIO' },
                    { label: 'ALTO', value: 'ALTO' },
                    { label: 'GRAVE', value: 'GRAVE' },
                ]}
                onChange={e => setRow({ ...row, p_impact_bita: e.value })}
            />
            <div className="col-12">
                <hr className="border-light" />
            </div>
            <Input
                text="Fecha"
                type="date"
                placeholder="Fecha"
                classNameParent="col-12"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.date_bita}
                onChange={e => setRow({ ...row, p_date_bita: e.currentTarget.value })}
            />
            <div className="col-12 mb-3">
                <hr className="border-light" />
            </div>
            <TextArea
                text="Criterio que toca dentro de la evaluación de desempeño"
                rows="5"
                defaultValue={data.criterion_bita}
                onChange={e => setRow({ ...row, p_criterion_bita: e.currentTarget.value })}
                required
                invalid="El campo es obligatorio."
            />
            <div className="col-12 mb-3">
                <hr className="border-light" />
            </div>
            <TextArea
                text="¿Se realizó retroalimentación correctiva?"
                rows="5"
                defaultValue={data.feedback_bita}
                onChange={e => setRow({ ...row, p_feedback_bita: e.currentTarget.value })}
                classNameParent="col-12 mb-4"
                required
                invalid="El campo es obligatorio."
            />
            {/* <div className="col-6">
                <Button variant="primary btn-gradient-primary" className="mt-2" block>
                    <i className="fa fa-file-pdf mr-2"></i>Imprimir Historial
                </Button>
            </div> */}
            <div className="col-6">
                <Button variant="primary btn-gradient-primary" disabled={disablebSubmit} className="mt-2" block type="submit">
                    {loadSubmit ? <i className="fa fa-circle-notch fa-spin"></i> : <><i className="fa fa-check mr-2"></i> Guardar Información</>}
                </Button>
            </div>
        </FormCustom>
    </ReactPlaceholder>
}

BitacoraForm.defaultProps = {
    data: {
        id_bitacora: '',
        id_customers: '',
        period_bita: '',
        event_bita: '',
        impact_bita: '',
        date_bita: '',
        criterion_bita: '',
        feedback_bita: '',
    },
    onSubmit: () => {},
    loadSubmit: false,
    idCustomers: '',
    onSuccess: () => {}
}

export default BitacoraForm;