import React, { useEffect, useState, useMemo } from 'react'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, SwitchToggle, TextArea, Select } from '../../Components/Form/Form'
import ReactPlaceholder from 'react-placeholder'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify'

const IncidenceForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_incidence: data.id_incidence,
        p_date_incidence: data.date_incidence,
        p_folio_incidence: data.folio_incidence,
        p_id_category: data.id_category,
        p_description_incidence: data.description_incidence,
        p_solution_incidence: data.solution_incidence,
        /* p_date_close_incidence: data.date_close_incidence,
        p_folio_close_incidece: data.folio_close_incidece, */
        p_state_incidence: data.state_incidence,
    })

    const [categories, setCategories] = useState([])
    const [ready, setReady] = useState(false)

    useEffect(() => {
        Controllers.category.get_category().then(res => {
            setCategories(res.data)
            setReady(true)
        })
    }, [])

    const category = useMemo(() => {
        if (row.p_id_category === '') {
            return
        }
        
        let finded = categories.find(j => parseInt(j.id_category) === parseInt(row.p_id_category))

        if (!finded) {
            return
        }

        return {
            label: finded.name_category,
            value: finded.id_category
        }
    }, [row.p_id_category, categories])

    return <ReactPlaceholder rows={10} showLoadingAnimation ready={ready}>
        <FormCustom dataSubmit={row} onSubmit={({ e, data }) => {
            if (!data.p_id_category) {
                Helpers.toast.construct({ response: 'warning', toast, message: 'Seleccionar categoría' })
                return
            }
            onSubmit({ e, data })
        }} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <Input
                type="date"
                text="Fecha"
                placeholder="Fecha"
                classNameParent="col-12 col-md-6"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.date_incidence}
                onChange={e => setRow({ ...row, p_date_incidence: e.currentTarget.value })}
            />
            <Input
                text="Folio"
                placeholder="Folio"
                classNameParent="col-12 col-md-6 mt-4"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.folio_incidence}
                onChange={e => setRow({ ...row, p_folio_incidence: e.currentTarget.value })}
                maxLength={100}
                style={{
                    marginTop: -2
                }}
            />
            <Select
                text="Departamento"
                classNameParent="col-12"
                options={categories.map(j => {
                    return {
                        label: j.name_category,
                        value: j.id_category
                    }
                })}
                value={category}
                onChange={e => setRow({ ...row, p_id_category: e.value })}
            />
            <div className="col-12 mb-2">
                <hr className="border-light" />
            </div>
            <TextArea
                text="Descripción de la incidencia"
                placeholder="Descripción de la incidencia"
                classNameParent="col-12 mb-4 mt-2"
                rows="5"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.description_incidence}
                onChange={e => setRow({ ...row, p_description_incidence: e.currentTarget.value })}
                maxLength={255}
            />
            <div className="col-12 mb-2">
                <hr className="border-light" />
            </div>
            <TextArea
                text="Solución de Gerencia Sucursal"
                placeholder="Solución de Gerencia Sucursal"
                classNameParent="col-12 mb-4 mt-2"
                rows="5"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.solution_incidence}
                onChange={e => setRow({ ...row, p_solution_incidence: e.currentTarget.value })}
                maxLength={255}
            />
            <div className="col-12 mb-2">
                <hr className="border-light" />
            </div>
            {/* <Input
                type="date"
                text="Fecha Cierre"
                placeholder="Fecha Cierre"
                classNameParent="col-12 col-md-6"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.date_close_incidence}
                onChange={e => setRow({ ...row, p_date_close_incidence: e.currentTarget.value })}
            />
            <Input
                text="Folio Cierre"
                placeholder="Folio Cierre"
                classNameParent="col-12 col-md-6 mt-4"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.folio_close_incidece}
                onChange={e => setRow({ ...row, p_folio_close_incidece: e.currentTarget.value })}
                maxLength={100}
                style={{
                    marginTop: -2
                }}
            /> */}
            {/* <SwitchToggle
                classNameParent="col-12 mb-2"
                id="incidence_state"
                defaultChecked={parseInt(row.p_state_incidence) === 1}
                onChange={e => setRow({ ...row, p_state_incidence: e.currentTarget.checked ? 1 : 0 })}
            /> */}
        </FormCustom>
    </ReactPlaceholder>
}

IncidenceForm.defaultProps = {
    data: {
        id_incidence: '',
        date_incidence: '',
        folio_incidence: '',
        id_category: '',
        description_incidence: '',
        solution_incidence: '',
        date_close_incidence: '',
        folio_close_incidece: '',
        state_incidence: 1,
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default IncidenceForm;