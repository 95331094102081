import React, { useEffect } from 'react'
import { useState } from 'react';
import ReactPlaceholder from 'react-placeholder/lib';
import { useSelector } from 'react-redux';
import { FormCustom, TextArea } from '../../Components/Form/Form'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'

const IncidenceSolution = ({ idIncidence, onSubmit }) => {
    const store = useSelector(store => store)
    const [ready, setReady] = useState(false)
    const [row, setRow] = useState({
        p_id_incidence: idIncidence,
        p_description_comment: ''
    })
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [comments, setComments] = useState([])

    const handleSubmitComment = _ => {
        setLoadSubmit(true)
        Controllers.incidence.incidence_comment_insert(row).then(res => {
            Helpers.toast.construct({ ...res, toast })
            setRow({ ...row, p_description_comment: '' })
            setReady(false)
            setLoadSubmit(false)
            onSubmit()
        })
    }

    useEffect(() => {
        if (!ready) {
            Controllers.incidence.get_incidence_comment({
                p_id_incidence: idIncidence
            }).then(res => {
                setComments(res.data)
                setReady(true)
            })
        }
    }, [ready, idIncidence])

    return <>
        <div className="col-12 bg-secondary form-row p-4">
            <div className="col-12 col-md-4 mb-2">
                <Card>
                    <Card.Body>
                        <div className="d-flex">
                            <img src={store.session.file_photo_user} style={{
                                borderRadius: '50px'
                            }} width="30" className="mr-3 align-self-center" />
                            <h5 className="bg-secondary p-2 align-self-center">Tu Comentario:</h5>
                        </div>
                        <FormCustom viewSubmit={false} dataSubmit={row} onSubmit={handleSubmitComment}>
                            <TextArea text="Comentario" classNameParent="col-12 mt-4" rows="5" required value={row.p_description_comment} onChange={e => setRow({ ...row, p_description_comment: e.currentTarget.value })} />
                            <div className="col-12 mt-3">
                                <Button type="submit">
                                    {loadSubmit ? <i className="fa fa-circle-notch fa-spin"></i> : <><i className="fa fa-check mr-1"></i>Enviar</>}
                                </Button>
                            </div>
                        </FormCustom>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-12 col-md-8">
                {comments.length === 0 ? <div className="alert alert-warning">
                    <strong>¡Sé el primero!</strong> Deja tu alterantiva de solución.
                </div>: <ReactPlaceholder className="mb-2" ready={ready} showLoadingAnimation rows={15}>
                    {React.Children.toArray(comments.map(c => {
                        return <Card className="mb-2">
                            <Card.Body>
                                <section className="d-flex">
                                    <div>
                                        <img src={Helpers.config.resourcesUrl({ url: c.file_photo_cust })} width="30" style={{
                                            borderRadius: 50
                                        }} />
                                    </div>
                                    <div className="d-flex flex-column pl-2">
                                        <h6 className="text-dark font-weight-bold">{c.full_name_cust}</h6>
                                        <p>{c.date_created}</p>
                                        <p>{c.description_comment}</p>

                                    </div>
                                </section>
                            </Card.Body>
                        </Card>
                    }))}
                </ReactPlaceholder>}
            </div>
        </div>
    </>
}

export default IncidenceSolution