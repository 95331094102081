import React, { useState } from 'react'
import { Input, FormCustom, SwitchToggle } from '../../Components/Form/Form'

const BranchOfficeForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_branch_office: data.id_branch_office,
        p_name_branch_office: data.name_branch_office,
        p_state_branch_office: data.state_branch_office
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Descripción"
            placeholder="Descripción"
            classNameParent="col-12 mt-2 mb-2"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.name_branch_office}
            onChange={e => setRow({ ...row, p_name_branch_office: e.currentTarget.value })}
            maxLength={150}
        />
        <SwitchToggle
            classNameParent="col-12 mb-2"
            id="branch_office_state"
            defaultChecked={parseInt(row.p_state_branch_office) === 1}
            onChange={e => setRow({ ...row, p_state_branch_office: e.currentTarget.checked ? 1 : 0 })}
        />
    </FormCustom>
}

BranchOfficeForm.defaultProps = {
    data: {
        id_branch_office: '',
        name_branch_office: '',
        state_branch_office: 1,
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default BranchOfficeForm;