import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import permissions from './Components/Layout/Resources/Example.json'
import AppLayout from './Views/Layout/Layout'
import Login from './Components/Auth/Login/Login'
import NotFound from './Components/PageError/NotFound/NotFound'
import Helpers from './Components/Helpers/Helpers'
import Customers from './Views/Customers/Customers'
import CustomersList from './Views/Customers/CustomersList'
import Article from './Views/Article/Article'
import History from './Views/History/History'
import Mision from './Views/Text/Mision'
import Porra from './Views/Text/Porra'
import Queja from './Views/Text/Queja'
import YoSoy from './Views/Text/YoSoy'
import Dashboard from './Views/Dashboard/Dashboard'
import BranchOffice from './Views/BranchOffice/BranchOffice'
import Job from './Views/Job/Job'
import Event from './Views/Event/Event'
import HistoryEvent from './Views/HistoryEvent/HistoryEvent'
import Product from './Views/Product/Product'
import Foro from './Views/Foro/Foro'
import ForoBlog from './Views/Foro/ForoBlog'
import Category from './Views/Category/Category'
import PersonalInformation from './Views/PersonalInformation/PersonalInformation'
import StandardSeller from './Views/Text/StandardSeller'
import Video from './Views/Video/Video'
import VideoHistory from './Views/Video/VideoHistory'
import Evaluation from './Views/Evaluation/Evaluation'
import Course from './Views/Course/Course'
import EventList from './Views/Event/EventList'
import ArticleList from './Views/Article/ArticleList'
import ForoList from './Views/Foro/ForoList'
import Profile from './Views/Profile/Profile'
import { useSelector } from 'react-redux'
import Incidence from './Views/Incidence/Incidence'
import Period from './Views/Period/Period'
import Noti from './Views/Noti/Noti'
import HistoryNoti from './Views/HistoryNoti/HistoryNoti'
import NotiList from './Views/Noti/NotiList'
import Searching from './Views/Searching/Searching'
import CategoryProduct from './Views/CategoryProduct/CategoryProduct'
import Organigrama from './Views/Text/Organigrama'
import Privacidad from './Views/Text/Privacidad'
import Sale from './Views/Sale/Sale'

const Routes = ({ type }) => {

  const store = useSelector(store => store)

  const hasPermission = (page) => {
    let permissions = store.session.permissions
    
    let has = false
    
    permissions.forEach(tpm => {
      if (has) {
        return false
      }

      tpm.childrens.forEach(tp => {
        if (has) {
          return false
        }

        tp.childrens.forEach(tp => {
          if (has) {
            return false
          }

          if (tp.a_href === page) {
            has = true
          }
        })
      })
    })

    return has
  }
  
  if (type === 'auth') {
    return <BrowserRouter>
    <Switch>
      <Route exact path={Helpers.config.convertUrl({ url: '/login' })}>
        <Login />
      </Route>
      <Route exact path={Helpers.config.convertUrl({ url: '/' })}>
        <Login />
      </Route>
      <Route>
        <Redirect to={Helpers.config.convertUrl({ url: '/login' })} />
      </Route>
    </Switch>
  </BrowserRouter>
  }
  
  return <BrowserRouter>
    <Switch>
      {hasPermission('escritorio') ? <Route path={Helpers.config.convertUrl({ url: '/escritorio' })} exact>
        <AppLayout title="Escritorio" subtitle="Escritorio" permissions={permissions} page="escritorio">
          <Dashboard />
        </AppLayout>
      </Route> : ''}

      {hasPermission('productos') ? <Route path={Helpers.config.convertUrl({ url: '/productos' })} exact>
        <AppLayout title="Productos" subtitle="Productos" permissions={permissions} page="productos">
          <Product namePlural="Productos" nameSingle="Producto" separator="el" />
        </AppLayout>
      </Route> : ''}
      
      {hasPermission('colaboradores') ? <Route path={Helpers.config.convertUrl({ url: '/registro-colaborador' })} exact>
        <AppLayout title="Formulario Registro CRM" subtitle="Registro CRM" permissions={permissions} page="colaboradores">
          <Customers />
        </AppLayout>
      </Route> : ''}

      {hasPermission('blog-listado') ? <Route path={Helpers.config.convertUrl({ url: '/blog-listado' })} exact>
        <AppLayout title="Blog" subtitle="Blog" permissions={permissions} page="blog-listado">
          <ArticleList namePlural="Blog" nameSingle="Blog" separator="el" />
        </AppLayout>
      </Route> : ''}

      {hasPermission('blog-admin') ? <Route path={Helpers.config.convertUrl({ url: '/blog-admin' })} exact>
        <AppLayout title="Blog" subtitle="Blog" permissions={permissions} page="blog-admin">
          <Article />
        </AppLayout>
      </Route> : ''}

      {hasPermission('blog-historico') ? <Route path={Helpers.config.convertUrl({ url: '/blog-historico/:paginate?/:searchFilter?' })} exact>
        <AppLayout title="Histórico" subtitle="Blog" permissions={permissions} page="blog-historico">
          <History />
        </AppLayout>
      </Route> : ''}

      {hasPermission('blog-historico') ? <Route path={Helpers.config.convertUrl({ url: '/blog/:tokenArticle' })} exact>
        <AppLayout title="Blog" subtitle="Blog" permissions={permissions} page="blog-historico">
          <History showFull={false} />
        </AppLayout>
      </Route> : ''}

      {hasPermission('blog-historico') ? <Route path={Helpers.config.convertUrl({ url: '/categoria/:paginate?/:slugCategory' })} exact>
        <AppLayout title="Histórico" subtitle="Blog" permissions={permissions} page="blog-historico">
          <History />
        </AppLayout>
      </Route> : ''}

      {hasPermission('evento-listado') ? <Route path={Helpers.config.convertUrl({ url: '/evento-listado' })} exact>
        <AppLayout title="Eventos" subtitle="Eventos" permissions={permissions} page="evento-listado">
          <EventList namePlural="Eventos" nameSingle="Curso" separator="el" />
        </AppLayout>
      </Route> : ''}

      {hasPermission('evento-admin') ? <Route path={Helpers.config.convertUrl({ url: '/evento-admin' })} exact>
        <AppLayout title="Evento" subtitle="Evento" permissions={permissions} page="evento-admin">
          <Event />
        </AppLayout>
      </Route> : ''}

      {hasPermission('evento-historico') ? <Route path={Helpers.config.convertUrl({ url: '/evento-historico/:paginate?/:searchFilter?' })} exact>
        <AppLayout title="Histórico Evento" subtitle="Evento" permissions={permissions} page="evento-historico">
          <HistoryEvent />
        </AppLayout>
      </Route> : ''}

      {hasPermission('evento-historico') ? <Route path={Helpers.config.convertUrl({ url: '/categoria/evento/:paginate?/:slugCategory' })} exact>
        <AppLayout title="Histórico Evento" subtitle="Evento" permissions={permissions} page="evento-historico">
          <HistoryEvent />
        </AppLayout>
      </Route> : ''}

      {hasPermission('evento-historico') ? <Route path={Helpers.config.convertUrl({ url: '/evento/:tokenEvent' })} exact>
        <AppLayout title="Evento" subtitle="Evento" permissions={permissions} page="evento-historico">
          <HistoryEvent showFull={false} />
        </AppLayout>
      </Route> : ''}

      {hasPermission('foro-listado') ? <Route path={Helpers.config.convertUrl({ url: '/foro-listado' })} exact>
        <AppLayout title="Foro" subtitle="Foro" permissions={permissions} page="foro-listado">
          <ForoList namePlural="Foro" nameSingle="Foro" separator="el" />
        </AppLayout>
      </Route> : ''}

      {hasPermission('foro') ? <Route path={Helpers.config.convertUrl({ url: '/foro' })} exact>
        <AppLayout title="Foro" subtitle="Foro" permissions={permissions} page="foro">
          <Foro namePlural="Foros" nameSingle="Foro" separator="el" />
        </AppLayout>
      </Route> : ''}

      {hasPermission('foro') ? <Route path={Helpers.config.convertUrl({ url: '/foro/:tokenForo' })} exact>
        <AppLayout title="Foro" subtitle="Foro" permissions={permissions} page="foro">
          <ForoBlog />
        </AppLayout>
      </Route> : ''}

      {hasPermission('colaboradores') ? <Route path={Helpers.config.convertUrl({ url: '/colaboradores' })} exact>
        <AppLayout title="Listado Colaboradores CRM" subtitle="Colaboradores CRM" permissions={permissions} page="colaboradores">
          <CustomersList />
        </AppLayout>
      </Route> : ''}

      {hasPermission('colaboradores') ? <Route path={Helpers.config.convertUrl({ url: '/colaboradores/:idCustomers' })} exact>
        <AppLayout title="Formulario Edición CRM" subtitle="Edición CRM" permissions={permissions} page="colaboradores">
          <Customers />
        </AppLayout>
      </Route> : ''}

      {hasPermission('videos') ? <Route path={Helpers.config.convertUrl({ url: '/videos' })} exact>
        <AppLayout title="Videos" subtitle="Videos" permissions={permissions} page="videos">
          <Video namePlural="Videos" nameSingle="Video" separator="el" />
        </AppLayout>
      </Route> : ''}

      {hasPermission('cursos') ? <Route path={Helpers.config.convertUrl({ url: '/cursos' })} exact>
        <AppLayout title="Cursos" subtitle="Cursos" permissions={permissions} page="cursos">
          <Course namePlural="Cursos" nameSingle="Curso" separator="el" />
        </AppLayout>
      </Route> : ''}

      {hasPermission('buscador-de-videos') ? <Route path={Helpers.config.convertUrl({ url: '/buscador-de-videos/:tokenVideo?' })} exact>
        <AppLayout title="Videos" subtitle="Videos" permissions={permissions} page="buscador-de-videos">
          <VideoHistory />
        </AppLayout>
      </Route> : ''}

      {hasPermission('sistema-de-evaluacion') ? <Route path={Helpers.config.convertUrl({ url: '/sistema-de-evaluacion' })} exact>
        <AppLayout title="Evaluación" subtitle="Evaluación" permissions={permissions} page="sistema-de-evaluacion">
          <Evaluation />
        </AppLayout>
      </Route> : ''}
      
      {hasPermission('mision-vision') ? <Route path={Helpers.config.convertUrl({ url: '/mision-vision' })} exact>
        <AppLayout title="Valores" subtitle="Valores" permissions={permissions} page="mision-vision">
          <Mision readonly />
        </AppLayout>
      </Route> : ''}

      {hasPermission('porra') ? <Route path={Helpers.config.convertUrl({ url: '/porra' })} exact>
        <AppLayout title="Porra" subtitle="Porra" permissions={permissions} page="porra">
          <Porra readonly />
        </AppLayout>
      </Route> : ''}

      {hasPermission('manejo-quejas') ? <Route path={Helpers.config.convertUrl({ url: '/manejo-quejas' })} exact>
        <AppLayout title="Queja" subtitle="Queja" permissions={permissions} page="manejo-quejas">
          <Queja readonly />
        </AppLayout>
      </Route> : ''}

      {hasPermission('yo-soy-super') ? <Route path={Helpers.config.convertUrl({ url: '/yo-soy-super' })} exact>
        <AppLayout title="Yo Soy" subtitle="Yo Soy" permissions={permissions} page="yo-soy-super">
          <YoSoy readonly />
        </AppLayout>
      </Route> : ''}

      {hasPermission('estandares-de-venta') ? <Route path={Helpers.config.convertUrl({ url: '/estandares-de-venta' })} exact>
        <AppLayout title="Estándares de Venta" subtitle="Estándares de Venta" permissions={permissions} page="estandares-de-venta">
          <StandardSeller readonly />
        </AppLayout>
      </Route> : ''}

      {hasPermission('mision-vision-editar') ? <Route path={Helpers.config.convertUrl({ url: '/mision-vision-editar' })} exact>
        <AppLayout title="Valores" subtitle="Valores" permissions={permissions} page="mision-vision-editar">
          <Mision readonly={false} />
        </AppLayout>
      </Route> : ''}
      
      {hasPermission('porra-editar') ? <Route path={Helpers.config.convertUrl({ url: '/porra-editar' })} exact>
        <AppLayout title="Porra" subtitle="Porra" permissions={permissions} page="porra-editar">
          <Porra readonly={false} />
        </AppLayout>
      </Route> : ''}
      
      {hasPermission('manejo-quejas-editar') ? <Route path={Helpers.config.convertUrl({ url: '/manejo-quejas-editar' })} exact>
        <AppLayout title="Queja" subtitle="Queja" permissions={permissions} page="manejo-quejas-editar">
          <Queja readonly={false} />
        </AppLayout>
      </Route> : ''}
      
      {hasPermission('yo-soy-super-editar') ? <Route path={Helpers.config.convertUrl({ url: '/yo-soy-super-editar' })} exact>
        <AppLayout title="Yo Soy" subtitle="Yo Soy" permissions={permissions} page="yo-soy-super-editar">
          <YoSoy readonly={false} />
        </AppLayout>
      </Route> : ''}
      
      {hasPermission('estandares-de-venta-editar') ? <Route path={Helpers.config.convertUrl({ url: '/estandares-de-venta-editar' })} exact>
        <AppLayout title="Estándares de Venta" subtitle="Estándares de Venta" permissions={permissions} page="estandares-de-venta-editar">
          <StandardSeller />
        </AppLayout>
      </Route> : ''}

      {hasPermission('organigrama-editar') ? <Route path={Helpers.config.convertUrl({ url: '/organigrama-editar' })} exact>
        <AppLayout title="Organigrama" subtitle="Organigrama" permissions={permissions} page="organigrama-editar">
          <Organigrama readonly={false} />
        </AppLayout>
      </Route> : ''}

      {hasPermission('organigrama') ? <Route path={Helpers.config.convertUrl({ url: '/organigrama' })} exact>
        <AppLayout title="Organigrama" subtitle="Organigrama" permissions={permissions} page="organigrama">
          <Organigrama readonly />
        </AppLayout>
      </Route> : ''}

      {hasPermission('privacidad-editar') ? <Route path={Helpers.config.convertUrl({ url: '/privacidad-editar' })} exact>
        <AppLayout title="Privacidad" subtitle="Privacidad" permissions={permissions} page="privacidad-editar">
          <Privacidad readonly={false} />
        </AppLayout>
      </Route> : ''}

      {hasPermission('privacidad') ? <Route path={Helpers.config.convertUrl({ url: '/privacidad' })} exact>
        <AppLayout title="Privacidad" subtitle="Privacidad" permissions={permissions} page="privacidad">
          <Privacidad readonly />
        </AppLayout>
      </Route> : ''}

      {hasPermission('sucursales') ? <Route path={Helpers.config.convertUrl({ url: '/sucursales' })} exact>
        <AppLayout title="Sucursales" subtitle="Sucursales" permissions={permissions} page="sucursales">
          <BranchOffice namePlural="Sucursales" nameSingle="Sucursal" separator="el" />
        </AppLayout>
      </Route> : ''}

      {hasPermission('puesto-de-trabajo') ? <Route path={Helpers.config.convertUrl({ url: '/puesto-de-trabajo' })} exact>
        <AppLayout title="Puestos de Trabajo" subtitle="Puestos de Trabajo" permissions={permissions} page="puesto-de-trabajo">
          <Job namePlural="Puestos de Trabajo" nameSingle="Puesto de Trabajo" separator="el" />
        </AppLayout>
      </Route> : ''}

      {hasPermission('departamentos') ? <Route path={Helpers.config.convertUrl({ url: '/departamentos' })} exact>
        <AppLayout title="Departamentos" subtitle="Departamentos" permissions={permissions} page="departamentos">
          <Category namePlural="Departamentos" nameSingle="Departamento" separator="la" />
        </AppLayout>
      </Route> : ''}

      {hasPermission('perfiles') ? <Route path={Helpers.config.convertUrl({ url: '/perfiles' })} exact>
        <AppLayout title="Perfiles" subtitle="Perfiles" permissions={permissions} page="perfiles">
          <Profile namePlural="Perfiles" nameSingle="Perfil" separator="el" />
        </AppLayout>
      </Route> : ''}

      {hasPermission('incidence') ? <Route path={Helpers.config.convertUrl({ url: '/incidence' })} exact>
        <AppLayout title="Formulario de Incidencias" subtitle="Registro De Incidencias" permissions={permissions} page="incidence">
          <Incidence namePlural="Incidencias" nameSingle="Incidencia" separator="el" />
        </AppLayout>
      </Route> : ''}

      {hasPermission('periodos') ? <Route path={Helpers.config.convertUrl({ url: '/periodos' })} exact>
        <AppLayout title="Periodos" subtitle="Registro De Periodos" permissions={permissions} page="periodos">
          <Period namePlural="Periodos" nameSingle="Periodo" separator="el" />
        </AppLayout>
      </Route> : ''}

      {hasPermission('noti-listado') ? <Route path={Helpers.config.convertUrl({ url: '/noti-listado' })} exact>
        <AppLayout title="Noti Súper" subtitle="Noti Súper" permissions={permissions} page="noti-listado">
          <NotiList namePlural="Noti Súper" nameSingle="Noti Súper" separator="el" />
        </AppLayout>
      </Route> : ''}

      {hasPermission('noti-admin') ? <Route path={Helpers.config.convertUrl({ url: '/noti-admin' })} exact>
        <AppLayout title="Noti Súper" subtitle="Noti Súper" permissions={permissions} page="noti-admin">
          <Noti />
        </AppLayout>
      </Route> : ''}

      {hasPermission('noti-historico') ? <Route path={Helpers.config.convertUrl({ url: '/noti-historico/:paginate?/:searchFilter?' })} exact>
        <AppLayout title="Histórico Noti Súper" subtitle="Noti Súper" permissions={permissions} page="noti-historico">
          <HistoryNoti />
        </AppLayout>
      </Route> : ''}

      {hasPermission('noti-historico') ? <Route path={Helpers.config.convertUrl({ url: '/categoria/noti/:paginate?/:slugCategory' })} exact>
        <AppLayout title="Histórico Noti Súper" subtitle="Noti Súper" permissions={permissions} page="noti-historico">
          <HistoryNoti />
        </AppLayout>
      </Route> : ''}

      {hasPermission('noti-historico') ? <Route path={Helpers.config.convertUrl({ url: '/noti/:tokenNoti' })} exact>
        <AppLayout title="Noti Súper" subtitle="Noti Súper" permissions={permissions} page="noti-historico">
          <HistoryNoti showFull={false} />
        </AppLayout>
      </Route> : ''}
      
      {hasPermission('categoria-productos') ? <Route path={Helpers.config.convertUrl({ url: '/categoria-productos' })} exact>
        <AppLayout title="Categoría Productos" subtitle="Categoría Productos" permissions={permissions} page="categoria-productos">
          <CategoryProduct namePlural="Categoría Productos" nameSingle="Categoría Productos" separator="la" />
        </AppLayout>
      </Route> : ''}

      {hasPermission('ventas') ? <Route path={Helpers.config.convertUrl({ url: '/ventas' })} exact>
        <AppLayout title="Ventas" subtitle="Ventas" permissions={permissions} page="ventas">
          <Sale namePlural="Ventas" nameSingle="Venta" separator="la" />
        </AppLayout>
      </Route> : ''}

      {/* hasPermission('noti-historico') */ true ? <Route path={Helpers.config.convertUrl({ url: '/buscador/:keyword' })} exact>
        <AppLayout title="Buscardor" subtitle="Buscardor" permissions={permissions} page="buscador">
          <Searching />
        </AppLayout>
      </Route> : ''}

      <Route path={Helpers.config.convertUrl({ url: '/mi-perfil' })} exact>
        <AppLayout title="Perfil" subtitle="Perfil" permissions={permissions} page="mi-perfil">
          <PersonalInformation />
        </AppLayout>
      </Route>
      
      <Route path={Helpers.config.convertUrl({ url: '/login' })} exact>
        <Redirect to={Helpers.config.convertUrl({ url: '/' + store.session.main_profile })} />
      </Route>
      <Route path={Helpers.config.convertUrl({ url: '/' })} exact>
        <Redirect to={Helpers.config.convertUrl({ url: '/' + store.session.main_profile })} />
      </Route>
      <Route>
        <AppLayout title="No existe la página" permissions={permissions} showBread={false}>
          <NotFound backUrl="/" imageNotFound={Helpers.config.resourcesUrl({ url: '/images/dashboard/not-found.png' })} />
        </AppLayout>
      </Route>
    </Switch>
  </BrowserRouter>
}

Routes.defaultProps = {
  type: 'session'
}

export default Routes