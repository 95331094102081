import React, { useCallback, useMemo, useState } from 'react'
import { Input, FormCustom, SwitchToggle, Select } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import {toast} from 'react-toastify'

const SaleForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_sale: data.id_sale,
        p_description_sale: data.description_sale,
        p_amount_sale: data.amount_sale,
        p_type_sale: data.type_sale,
        p_image_sale: data.image_sale,
        p_show_dashboard_sale: data.show_dashboard_sale,
    })

    const [photo, setPhoto] = useState('')

    const optionsType = [
        { label: 'Rojo', value: 'Rojo' },
        { label: 'Negro', value: 'Negro' },
        { label: 'Estrella', value: 'Estrella' },
    ]

    const type = useMemo(() => {
        if (row.p_type_sale === '') {
            return
        }

        let finded = optionsType.find(j => j.value === row.p_type_sale)

        if (!finded) {
            return
        }

        return finded
    }, [row.p_type_sale, optionsType])

    const dataSubmit = useCallback(() => {
        let formData = new FormData()

        for (const key in row) {
            if (key === 'p_image_sale') {
                if (row.p_image_sale?.files) {
                    if (row.p_image_sale.files.length > 0) {
                        formData.append('p_image_sale', row.p_image_sale.files[0])
                        continue
                    }
                }
                formData.append('p_image_sale', '')
                continue
            }
            formData.append(key, row[key])
        }

        return formData
    }, [row])

    return <FormCustom dataSubmit={dataSubmit()} onSubmit={({ e, data }) => {
        if (!row.p_type_sale) {
            Helpers.toast.construct({
                response: 'warning',
                message: 'Seleccionar Resultados',
                toast
            })
            return
        }
        onSubmit({ e, data })
    }} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Nombre Tienda"
            placeholder="Nombre Tienda"
            classNameParent="col-12 mt-2 mb-2"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.description_sale}
            onChange={e => setRow({ ...row, p_description_sale: e.currentTarget.value })}
            maxLength={255}
        />
        <Input
            text="Semana"
            placeholder="Semana"
            classNameParent="col-12 mt-2 mb-2"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.amount_sale}
            onChange={e => setRow({ ...row, p_amount_sale: e.currentTarget.value })}
            maxLength={150}
            type="number"
            step="0.01"
            min="0"
        />
        <Select
            options={optionsType}
            text="Resultados"
            classNameParent="col-12 mb-2"
            value={type}
            onChange={e => setRow({ ...row, p_type_sale: e.value })}
        />
        <div className="col-12 mt-2">
            {photo || row.p_image_sale ? <img className="img-thumbnail mb-2" src={photo ? photo : Helpers.config.resourcesUrl({ url: row.p_image_sale })} width="150" /> : ''}
            <div>Seleccionar Imagen</div>
            <Input
                type="file"
                classNameParent=""
                required={isNaN(parseInt(data?.id_sale))}
                invalid="El campo es obligatorio."
                accept="image/*"
                onChange={e => {
                    setRow({ ...row, p_image_sale: e.currentTarget })
                    if (e.currentTarget.files.length > 0) {
                        let selectedFile = e.currentTarget.files[0]
                        let reader = new FileReader()

                        reader.onload = function (event) {
                            setPhoto(event.target.result)
                        }
                        reader.readAsDataURL(selectedFile)
                    } else {
                        setPhoto('')
                    }
                }}
            />
        </div>
        <SwitchToggle
            classNameParent="col-12 mb-2"
            id="show_dashboard_sale"
            textCheck="Mostrar en Escritorio"
            textUnChecked="Oculto en Escritorio"
            defaultChecked={parseInt(row.p_show_dashboard_sale) === 1}
            onChange={e => setRow({ ...row, p_show_dashboard_sale: e.currentTarget.checked ? 1 : 0 })}
        />
    </FormCustom>
}

SaleForm.defaultProps = {
    data: {
        id_sale: '',
        description_sale: '',
        amount_sale: '',
        type_sale: '',
        image_sale: '',
        show_dashboard_sale: 1
    },
    onSubmit: () => { },
    loadSubmit: false
}

export default SaleForm;