import React, { forwardRef, useEffect, useState } from 'react'
import { Layout, Header } from '../../Components/Layout/Layout'
/* import permissions from '../../Components/Layout/Resources/Example.json' */
import Helpers from '../../Components/Helpers/Helpers'
import Dropdown from 'react-bootstrap/Dropdown'
import { useSelector, useStore } from 'react-redux'
import { Link } from 'react-router-dom'

const CustomToggle = forwardRef(({ children, onClick }, ref) => {
  const store = useSelector(res => res)
  return <Header.Right.Icon ref={ref} onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    <Header.Right.Image src={store.session.file_photo_user} />
    {children}
  </Header.Right.Icon>
})

const AppLayout = ({ title, subtitle, children, page, showBread }) => {
  const handleLogout = () => {
    localStorage.clear()
    window.location.href = Helpers.config.folder_path;
  }

  const store = useSelector(res => res)

  return <Layout
    resizeNav
    imageLogo={Helpers.config.resourcesUrl({ url: '/images/dashboard/logo.png' })}
    permissions={store.session.permissions}
    title={title}
    subtitle={subtitle}
    page={page}
    companyName={Helpers.config.company.name}
    folderPath={Helpers.config.folder_path}
    iconsHeader={[
      <>
      <Dropdown>
        <Dropdown.Toggle variant="success" as={CustomToggle} />
        <Dropdown.Menu>
          <div className="p-3">
            {store?.session?.full_name}
          </div>
          <Link className="d-flex dropdown-item" to={Helpers.config.convertUrl({ url: '/mi-perfil' })} style={{
        minWidth: 250
      }}>
            <i className="far fa-user mr-2 align-self-center"></i>
            <span>Mi Perfil</span>
          </Link>
          <Dropdown.Divider></Dropdown.Divider>
          <Dropdown.Item className="d-flex" onClick={handleLogout} style={{
        minWidth: 250
      }}>
            <i className="fa fa-arrow-left mr-2 align-self-center"></i>
            <span>Salir</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      </>
    ]}
    showBread={showBread}
  >
    {children}
  </Layout>
}

AppLayout.defaultProps = {
  title: '',
  subtitle: '',
  page: ''
}

export default AppLayout