import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import { Link } from 'react-router-dom';
import SweetAlert from '../../Components/SweetAlert/SweetAlert';

const ArticleList = ({ nameSingle, separator, namePlural }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers.article.get_article({
                p_paginate: ''
            }).then(res => {
                setRows(res.data)
            }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setIsProccesing(false)
                setReload(false)
            })
        }
    }, [reload])

    const [dataDeleted, setDataDeleted] = useState({})
    const [loadSubmitDelete, setLoadSubmitDelete] = useState(false)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const handleDelete = () => {
        setLoadSubmitDelete(true)

        Controllers.article.article_delete(dataDeleted).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setShowConfirmDelete(false)
                setDataDeleted({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setReload(true)
            setLoadSubmitDelete(false)
        })
    }

    const handleShowModalDelete = e => {
        let { idArticle, nameArticle } = e.currentTarget.dataset
        setDataDeleted({
            p_id_article: idArticle,
            p_name_article: nameArticle
        })
        setShowConfirmDelete(true)
    }

    const handleHideModalDelete = () => setShowConfirmDelete(false)

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_article', text: 'ID', align: 'center' },
                { name: 'name_article', text: 'Nombres' },
                { name: 'file_images', text: 'Imagen', render: r => <img className="img-thumbnail" width="100" src={Helpers.config.resourcesUrl({ url: r.file_images })} />, align: 'center' },
                { name: 'name_category', text: 'Departamento' },
                { name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => {
                    return <div>
                        <Link to={Helpers.config.convertUrl({ url: '/blog/' + u.token_article })}>
                            <Button className="mr-1" size="xs">
                                <i className="fas fa-link"></i>
                            </Button>
                        </Link>
                        <Button variant="danger" size="xs" data-id-article={u.id_article} onClick={handleShowModalDelete} data-name-article={u.name_article}>
                            <i className="fa fa-trash"></i>
                        </Button>
                    </div>
                } },
            ],
        ]}
        rows={rows}
        isProcessing={isProcessing}
    />, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="d-flex justify-content-between bg-danger text-white">
                <span className="align-self-center">Listado De {namePlural}</span>
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
        <SweetAlert
            icon="delete"
            show={showConfirmDelete}
            showCancelButton
            title={<>¿Desea eliminar {separator} {nameSingle}?</>}
            subtitle={<>#{dataDeleted.p_id_article} - {dataDeleted.p_name_article}</>}
            confirmButtonText="¡Sí, eliminar!"
            cancelButtonText="¡No!"
            loadSubmit={loadSubmitDelete}
            onCancel={handleHideModalDelete}
            onConfirm={handleDelete}
        />
    </>
}

export default ArticleList