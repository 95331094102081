import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useState } from 'react'

const Input = ({ defaultValue, value, onChange, classNameParent, type, className, text, size, icon, append, invalid, iconText, smallColor, maxLength, marginBottom, hidePlaceholder, ...props }) => {
  const propInput = useCallback(() => {
    return defaultValue ? { defaultValue } : { value }
  }, [defaultValue, value])

  const refInput = useRef(null)
  const [floating, setFloating] = useState((defaultValue !== '' && defaultValue !== undefined) || (value !== '' && value !== undefined))

  if (type === 'hidden') 
    return (<input {...props} {...propInput()} type="hidden" className={'form-control ' + className} />)
  else 
    return (<div className={marginBottom + classNameParent}>
      {type === 'date' ? <small className={smallColor}>{text}</small> : ''}
      <div className={'input-group input-group-' + size}>
        {icon || iconText ? <div className="input-group-prepend">
          <div className="input-group-text">{icon ? <i className={icon}></i> : iconText}</div>
        </div> : ''}
        {type === 'file' ? <input {...props} {...propInput()} type="file" onChange={onChange} /> : <input {...props} {...propInput()} onChange={onChange} onFocus={_ => setFloating(true)} onBlur={_ => setFloating(refInput.current?.value !== '')} type={type} placeholder="" className={'form-control ' + className} ref={refInput} />}
        {type !== 'hidden' && type !== 'date' ? <small className={smallColor + ' floating-label ' + (floating || refInput.current?.value ? 'up-text' + (hidePlaceholder ? ' d-none' : '') : '')}>{text}</small> : ''}
        <div className="input-group-append">
          {React.Children.toArray(append)}
        </div>
        <div className="invalid-feedback">
          {invalid}
        </div>
      </div>
      
    </div>)
}
Input.defaultProps = {
  size: '',
  classNameParent: 'col-12',
  type: 'text',
  required: false,
  append: [],
  className: '',
  onChange: () => {},
  smallColor: 'text-muted',
  marginBottom: 'mb-4 ',
  hidePlaceholder: false
}
Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['hidden', 'text', 'date', 'time', 'password', 'email', 'number', 'datetime-local', 'file']),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.oneOf(['sm', 'lg', '']),
  classNameParent: PropTypes.string,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  pattern: PropTypes.string,
  append: PropTypes.array,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func
}
export default Input