import React, { useCallback, useState } from 'react'
import { useRef } from 'react'
import Button from 'react-bootstrap/esm/Button'
import { Input, FormCustom, SwitchToggle, TextArea, CheckBox, Select } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import ImagesModal from '../Images/ImagesModal'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import Controllers from '../../Api/Controllers'
import ReactPlaceholder from 'react-placeholder/lib'

const CourseForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_course: data.id_course,
        p_name_course: data.name_course,
        p_description_course: data.description_course,
        p_id_images: data.id_images,
        p_categories: data.categories,
        p_status_course: data.status_course,
        p_videos: data.videos
    })

    const [ready, setReady] = useState(false)
    const [categories, setCategories] = useState([])
    const [videos, setVideos] = useState([])
    const [videoSelected, setVideoSelected] = useState('')

    useEffect(() => {
        Promise.all([
            Controllers.category.get_category(),
            Controllers.video.get_video_public()
        ]).then(res => {
            setCategories(res[0].data)
            setVideos(res[1].data)
            setReady(true)
        })
    }, [])

    const [imageCourse, setImageCourse] = useState(isNaN(parseInt(data.id_course)) ? '' : {
        id_images: data.id_images,
        file_images: data.file_images
    })
    const [modalShow, setModalShow] = useState(false)

    const dataSubmit = useCallback(() => {
        
        return {
            ...row,
            p_videos: row.p_videos.map(v => {
                return {
                    id_video: v.id_video
                }
            })
        }
    }, [row])

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
        <FormCustom dataSubmit={dataSubmit()} onSubmit={({ data }) => {
            if (row.p_videos.length === 0) {
                Helpers.toast.construct({ response: 'warning', message: 'Seleccionar videos', toast })
                return
            }
            if (!row.p_id_images) {
                Helpers.toast.construct({ response: 'warning', message: 'Seleccionar imagen', toast })
                return
            }
            onSubmit({ data })
        }} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <div className="col-12">
                <div className="form-row">
                    <div className="col-12 col-md-6">
                        <div className="form-row">
                            <Input
                                text="Nombre del Curso"
                                classNameParent="col-12 mt-2 mb-2"
                                required
                                invalid="El campo es obligatorio."
                                defaultValue={data.name_course}
                                onChange={e => setRow({ ...row, p_name_course: e.currentTarget.value })}
                                maxLength={150}
                            />
                            <TextArea
                                text="Descripción"
                                classNameParent="col-12 mt-2 mb-3"
                                required
                                invalid="El campo es obligatorio."
                                defaultValue={data.description_course}
                                onChange={e => setRow({ ...row, p_description_course: e.currentTarget.value })}
                                rows={5}
                            />
                            <SwitchToggle
                                classNameParent="col-12 mb-2"
                                id="course_state"
                                defaultChecked={parseInt(row.p_status_course) === 1}
                                onChange={e => setRow({ ...row, p_status_course: e.currentTarget.checked ? 1 : 0 })}
                            />
                            
                            <div className="col-12">
                                <hr className="border-light" />
                                <p>Departamentos</p>
                            </div>

                            {React.Children.toArray(categories.map(c => {
                                return <CheckBox
                                    id={'chk-category-' + c.id_category}
                                    text={c.name_category}
                                    checked={row.p_categories.some(cc => parseInt(cc.id_category) === parseInt(c.id_category))}
                                    required={row.p_categories.length === 0}
                                    onChange={e => {
                                        if (e.currentTarget.checked) {
                                            setRow({ ...row, p_categories: [ ...row.p_categories, c ] })
                                        } else {
                                            setRow({ ...row, p_categories: row.p_categories.filter(cc => parseInt(cc.id_category) !== parseInt(c.id_category)) })
                                        }
                                    }}
                                />
                            }))}
                        </div>
                    </div>
                    <div className="col-md-6 col-12 d-flex flex-column mb-2 align-items-center">
                        <img className="img-thumbnail mb-2" src={Helpers.config.resourcesUrl({ url: imageCourse ? imageCourse.file_images : '/images/dashboard/sin_imagen.png' })} width="150" />
                        <Button onClick={_ => setModalShow(true)} block>
                            <i className="fa fa-search mr-1"></i>Seleccionar Imagen Destacada
                        </Button>
                    </div>
                </div>
            </div>
            
            <div className="col-12">
                <hr className="border-light" />
                <p>Seleccionar Videos</p>
            </div>

            <Select
                classNameParent="col-12 col-md-6 mb-3"
                text="Videos"
                options={videos.map(v => {
                    return {
                        label: <>
                            <img src={Helpers.config.resourcesUrl({ url: v.file_images })} width="50" height="35" className="img-thumbnail align-self-center" />
                            <span className="align-self-center ml-3">{v.name_video}</span>
                        </>,
                        value: v.id_video,
                        name_video: v.name_video,
                        file_images: v.file_images,
                        id_video: v.id_video,
                        isDisabled: row.p_videos.some(pv => parseInt(pv.id_video) === parseInt(v.id_video))
                    }
                })}
                value={videoSelected}
                onChange={e => setVideoSelected(e)}
                append={[<Button onClick={_ => {
                    if (videoSelected && !row.p_videos.some(pv => parseInt(pv.id_video) === parseInt(videoSelected.id_video))) {
                        setRow({ ...row, p_videos: [ ...row.p_videos, videoSelected ] })
                        setVideoSelected('')
                    }
                }}>
                    <i className="fa fa-plus mr-1"></i>Agregar
                </Button>]}
            />

            <div className="col-12 col-md-6">
                <p>Videos Agregados</p>
                {row.p_videos.length === 0 ? <div className="alert alert-warning">
                    No hay videos en el curso.
                </div> : <ul className="list-group">
                    {React.Children.toArray(row.p_videos.map(v => {
                        return <li className="list-group-item d-flex justify-content-between">
                            <div className="d-flex">
                                <img src={Helpers.config.resourcesUrl({ url: v.file_images })} width="50" height="35" className="img-thumbnail align-self-center" />
                                <span className="align-self-center ml-3">{v.name_video}</span>
                            </div>
                            <div className="align-self-center">
                                <Button size="xs" variant="danger" onClick={_ => {
                                    setRow({ ...row, p_videos: row.p_videos.filter(pv => {
                                        return parseInt(pv.id_video) !== parseInt(v.id_video)
                                    }) })
                                }}>
                                    <i className="fa fa-trash"></i>
                                </Button>
                            </div>
                        </li>
                    }))}
                </ul>}
            </div>
            <div className="col-12">
                <hr className="border-light" />
            </div>
        </FormCustom>
        <ImagesModal modalShow={modalShow} handleHideModalForm={() => setModalShow(false)} addImage={l => {
            setImageCourse(l)
            setRow({ ...row, p_id_images: l.id_images })
            setModalShow(false)
        }} />
    </ReactPlaceholder>
}

CourseForm.defaultProps = {
    data: {
        id_course: '',
        file_images: '',
        name_course: '',
        description_course: '',
        id_images: '',
        status_course: 1,
        categories: [],
        videos: []
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default CourseForm;