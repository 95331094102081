import React, { useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import Controllers from '../../Api/Controllers'
import { FormCustom, TextArea } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/esm/Card'

const CommentForo = ({ comment, onSuccess }) => {
    const [response, setResponse] = useState(false)
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [row, setRow] = useState({
        p_id_foro: comment.id_foro,
        p_id_comment_parent: comment.id_comment,
        p_description_comment: ''
    })

    const handleSubmitCommentForo = ({ data }) => {
        setLoadSubmit(true)
        Controllers.comment.comment_foro_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            setLoadSubmit(false)
            setResponse(false)
            setRow({ ...row, p_description_comment: '' })
            onSuccess()
        })
    }

    return <>
        <Card className="mb-2">
            <Card.Body className="d-flex flex-column">
                <div className="d-flex">
                    <div>
                        <img src={Helpers.config.resourcesUrl({ url: comment.file_photo_cust })} width="30" />
                    </div>
                    <div className="d-flex flex-column pl-2 comment_section">
                        <h6 className="bg-dark text-white p-2">{comment.full_name_cust}</h6>
                        <p>{comment.description_comment}</p>
                        <p>{comment.date_created}</p>
                        {!response ? <Button onClick={_ => setResponse(true)} variant="link" className="text-left algin-self-start mb-2">
                            Responder
                        </Button> :
                            <FormCustom dataSubmit={row} className="mb-4" viewSubmit={false} dataSubmit={row} onSubmit={handleSubmitCommentForo}>
                                <TextArea text="Comentario" classNameParent="col-12 mt-4" rows="5" required value={row.p_description_comment} onChange={e => setRow({ ...row, p_description_comment: e.currentTarget.value })} />
                                <div className="col-12 mt-3">
                                    <Button type="submit">
                                        {loadSubmit ? <i className="fa fa-circle-notch fa-spin"></i> : <><i className="fa fa-check mr-1"></i>Enviar</>}
                                    </Button>
                                    <Button variant="danger" className="ml-2" onClick={_ => setResponse(false)}>
                                        <i className="fa fa-times mr-1"></i>Cancelar
                                </Button>
                                </div>
                            </FormCustom>}
                    </div>
                </div>
                <div className="mt-3 comment_section">
                    {React.Children.toArray(comment.comments_child.map(c => {
                        return <section className="d-flex">
                            <div>
                                <img src={Helpers.config.resourcesUrl({ url: c.file_photo_cust })} width="30" style={{
                                    borderRadius: 50
                                }} />
                            </div>
                            <div className="d-flex flex-column pl-2">
                                <h6 className="text-dark font-weight-bold">{c.full_name_cust}</h6>
                                <p>{c.date_created}</p>
                                <p>{c.description_comment}</p>

                            </div>
                        </section>
                    }))}
                </div>
            </Card.Body>
        </Card>
    </>
}

export default CommentForo