import React, { useState, useMemo, useCallback } from 'react'
import { useEffect } from 'react'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/esm/Button'

const CalificationForm = ({ data, disablebSubmit, idCustomers, onSuccess }) => {
    const [row, setRow] = useState({
        p_id_evaluation: data.id_evaluation,
        p_id_customers: data.id_customers,
        p_evaluator_eval: data.evaluator_eval,
        p_attitude: {
            p_attitude_service: data.attitude.attitude_service,
            p_reliability: data.attitude.reliability,
            p_team_work: data.attitude.team_work,
            p_assertive_communication: data.attitude.assertive_communication,
            p_dress_code: data.attitude.dress_code
        },
        p_assistance: {
            p_assistance_lateness: data.assistance.assistance_lateness,
            p_assistance_meetings: data.assistance.assistance_meetings,
            p_availability: data.assistance.availability
        },
        p_results: {
            p_one: {
                p_concept: data.results.one.concept,
                p_calification: data.results.one.calification,
                p_percentage: data.results.one.percentage,
            },
            p_two: {
                p_concept: data.results.two.concept,
                p_calification: data.results.two.calification,
                p_percentage: data.results.two.percentage,
            },
            p_three: {
                p_concept: data.results.three.concept,
                p_calification: data.results.three.calification,
                p_percentage: data.results.three.percentage,
            },
            p_four: {
                p_concept: data.results.four.concept,
                p_calification: data.results.four.calification,
                p_percentage: data.results.four.percentage,
            },
            p_five: {
                p_concept: data.results.five.concept,
                p_calification: data.results.five.calification,
                p_percentage: data.results.five.percentage,
            }
        },
        p_percentage_total: data.percentage_total,
    })

    const [ready, setReady] = useState(false)
    const [customer, setCustomer] = useState({})
    
    useEffect(() => {
        Controllers.customers.get_customers_id({
            p_id_customers: idCustomers
        }).then(res => {
            setCustomer(res.data)
            setReady(true)
        })
    }, [idCustomers])

    const sumAttitude = useCallback(() => {
        let totalAttitude = 0

        if (isNaN(parseInt(row.p_attitude.p_attitude_service)) ||
            isNaN(parseInt(row.p_attitude.p_reliability)) ||
            isNaN(parseInt(row.p_attitude.p_team_work)) ||
            isNaN(parseInt(row.p_attitude.p_assertive_communication)) ||
            isNaN(parseInt(row.p_attitude.p_dress_code))
        ) {
            return -1
        }

        totalAttitude += parseInt(row.p_attitude.p_attitude_service)
        totalAttitude += parseInt(row.p_attitude.p_reliability)
        totalAttitude += parseInt(row.p_attitude.p_team_work)
        totalAttitude += parseInt(row.p_attitude.p_assertive_communication)
        totalAttitude += parseInt(row.p_attitude.p_dress_code)

        if (totalAttitude > 500) {
            return -1
        }

        return totalAttitude
    }, [row.p_attitude])

    const percentageAttitude = useCallback(() => {
        if (sumAttitude() === -1) {
            return -1
        }

        return (sumAttitude() * 20) / 500
    }, [sumAttitude])

    const sumAssistance = useCallback(() => {
        let totalAssistance = 0

        if (isNaN(parseInt(row.p_assistance.p_assistance_lateness)) ||
            isNaN(parseInt(row.p_assistance.p_assistance_meetings)) ||
            isNaN(parseInt(row.p_assistance.p_availability))
        ) {
            return -1
        }

        totalAssistance += parseInt(row.p_assistance.p_assistance_lateness)
        totalAssistance += parseInt(row.p_assistance.p_assistance_meetings)
        totalAssistance += parseInt(row.p_assistance.p_availability)

        if (totalAssistance > 300) {
            return -1
        }

        return totalAssistance
    }, [row.p_assistance])

    const percentageAssistance = useCallback(() => {
        if (sumAssistance() === -1) {
            return -1
        }

        return (sumAssistance() * 30) / 300
    }, [sumAssistance])

    const sumResults = useCallback(() => {
        let totalResults = 0

        if (isNaN(parseInt(row.p_results.p_one.p_percentage)) ||
            isNaN(parseInt(row.p_results.p_two.p_percentage)) ||
            isNaN(parseInt(row.p_results.p_three.p_percentage)) ||
            isNaN(parseInt(row.p_results.p_four.p_percentage)) ||
            isNaN(parseInt(row.p_results.p_five.p_percentage))
        ) {
            return -1
        }

        totalResults += parseInt(row.p_results.p_one.p_percentage)
        totalResults += parseInt(row.p_results.p_two.p_percentage)
        totalResults += parseInt(row.p_results.p_three.p_percentage)
        totalResults += parseInt(row.p_results.p_four.p_percentage)
        totalResults += parseInt(row.p_results.p_five.p_percentage)

        if (totalResults != 100) {
            return -1
        }

        return totalResults
    }, [row.p_results])

    const sumResultsCalification = useCallback(() => {
        let totalResults = 0

        if (isNaN(parseInt(row.p_results.p_one.p_calification)) ||
            isNaN(parseInt(row.p_results.p_two.p_calification)) ||
            isNaN(parseInt(row.p_results.p_three.p_calification)) ||
            isNaN(parseInt(row.p_results.p_four.p_calification)) ||
            isNaN(parseInt(row.p_results.p_five.p_calification))
        ) {
            return -1
        }

        totalResults += parseInt(row.p_results.p_one.p_calification)
        totalResults += parseInt(row.p_results.p_two.p_calification)
        totalResults += parseInt(row.p_results.p_three.p_calification)
        totalResults += parseInt(row.p_results.p_four.p_calification)
        totalResults += parseInt(row.p_results.p_five.p_calification)

        if (totalResults > 500) {
            return -1
        }

        return totalResults
    }, [row.p_results])

    const percentageResults = useCallback(() => {
        if (sumResults() === -1 || sumResultsCalification() === -1) {
            return -1
        }

        let percentage = (row.p_results.p_one.p_calification * row.p_results.p_one.p_percentage) / 100
        percentage += (row.p_results.p_two.p_calification * row.p_results.p_two.p_percentage) / 100
        percentage += (row.p_results.p_three.p_calification * row.p_results.p_three.p_percentage) / 100
        percentage += (row.p_results.p_four.p_calification * row.p_results.p_four.p_percentage) / 100
        percentage += (row.p_results.p_five.p_calification * row.p_results.p_five.p_percentage) / 100

        return (percentage * 50) / 100
    }, [sumResults])

    const dataSubmit = useCallback(() => {
        return {
            p_id_evaluation: row.p_id_evaluation,
            p_id_customers: idCustomers,
            p_evaluator_eval: row.p_evaluator_eval,
            p_total_percentage: (
                (percentageAttitude() === -1 ? 0 : percentageAttitude()) +
                (percentageAssistance() === -1 ? 0 : percentageAssistance()) +
                (percentageResults() === -1 ? 0 : percentageResults())
            ).toFixed(2),
            p_json_details: {
                attitude: { ...row.p_attitude, p_percentage: percentageAttitude() },
                assistance: { ...row.p_assistance, p_percentage: percentageAssistance() },
                results: { ...row.p_results, p_percentage: percentageResults() }
            }
        }
    }, [row])

    const [loadSubmit, setLoadSubmit] = useState(false)

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.evaluation.evaluation_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                window.scrollTo(0, 0)
                setLoadSubmit(false)
                onSuccess()
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
    }

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
        <FormCustom dataSubmit={dataSubmit()} onSubmit={handleInsertUpdate} viewSubmit={false} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <Input
                text="Personal a Evaluar"
                placeholder="Personal a Evaluar"
                classNameParent="col-12 col-md-8 mt-2 mb-2"
                required
                invalid="El campo es obligatorio."
                value={customer.full_name_cust}
                disabled
            />
            <Input
                text="Num. Nómina"
                placeholder="Num. Nómina"
                classNameParent="col-12 col-md-4 mt-2 mb-2"
                required
                invalid="El campo es obligatorio."
                value={customer.id_nomina}
                disabled
                className="text-center"
            />
            <Input
                text="Departamento"
                placeholder="Departamento"
                classNameParent="col-12 col-md-8 mt-2 mb-2"
                required
                invalid="El campo es obligatorio."
                value={customer.name_category}
                disabled
            />
            <Input
                text="Sucursal"
                placeholder="Sucursal"
                classNameParent="col-12 col-md-4 mt-2 mb-2"
                required
                invalid="El campo es obligatorio."
                value={customer.name_branch_office}
                disabled
            />
            <Input
                text="Responsable"
                placeholder="Responsable"
                classNameParent="col-12 mt-2 mb-2"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.evaluator_eval}
                onChange={e => setRow({ ...row, p_evaluator_eval: e.currentTarget.value })}
                maxLength={255}
            />
            <div className="col-12 col-md-6">
                <div className="p-2 text-white mb-4" style={{backgroundColor: '#211431' }}>
                    Actitud 20%
                </div>
                <div className="form-row">
                    <Input
                        text="Actitud del Servicio"
                        placeholder="Actitud del Servicio"
                        classNameParent="col-12"
                        required
                        invalid="El campo es obligatorio."
                        className="text-center"
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.attitude_service}
                        onChange={e => setRow({ ...row, p_attitude: {
                            ...row.p_attitude,
                            p_attitude_service: e.currentTarget.value
                        } })}
                    />
                    <Input
                        text="Confiabilidad"
                        placeholder="Confiabilidad"
                        classNameParent="col-12"
                        required
                        invalid="El campo es obligatorio."
                        className="text-center"
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.a}
                        defaultValue={data.reliability}
                        onChange={e => setRow({ ...row, p_attitude: {
                            ...row.p_attitude,
                            p_reliability: e.currentTarget.value
                        } })}
                    />
                    <Input
                        text="Trabajo en Equipo"
                        placeholder="Trabajo en Equipo"
                        classNameParent="col-12"
                        required
                        invalid="El campo es obligatorio."
                        className="text-center"
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.team_work}
                        onChange={e => setRow({ ...row, p_attitude: {
                            ...row.p_attitude,
                            p_team_work: e.currentTarget.value
                        } })}
                    />
                    <Input
                        text="Comunicación Asertiva"
                        placeholder="Comunicación Asertiva"
                        classNameParent="col-12"
                        required
                        invalid="El campo es obligatorio."
                        className="text-center"
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.assertive_communication}
                        onChange={e => setRow({ ...row, p_attitude: {
                            ...row.p_attitude,
                            p_assertive_communication: e.currentTarget.value
                        } })}
                    />
                    <Input
                        text="Código de Vestir"
                        placeholder="Código de Vestir"
                        classNameParent="col-12"
                        className="text-center"
                        required
                        invalid="El campo es obligatorio."
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.dress_code}
                        onChange={e => setRow({ ...row, p_attitude: {
                            ...row.p_attitude,
                            p_dress_code: e.currentTarget.value
                        } })}
                    />
                    <Input
                        text="Porcentaje"
                        placeholder="Porcentaje"
                        classNameParent="col-12"
                        required
                        invalid="El campo es obligatorio."
                        value={(percentageAttitude() === -1 ? 0 : percentageAttitude()).toFixed(2) + '%'}
                        disabled
                        className="text-center font-weight-bold"
                        style={{
                            fontSize: 20
                        }} 
                    />
                    {sumAttitude() === -1 ? <div className="col-12 mb-2" style={{
                        marginTop: '-20px'
                    }}>
                        <label className="text-danger">Por favor, ingresar cantidades correctas</label>
                    </div> : '' }
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="p-2 text-white mb-4" style={{backgroundColor: '#211431' }}>
                    Asistencia 30%
                </div>
                <div className="form-row">
                    <Input
                        text="Asistencia y Retardos"
                        placeholder="Asistencia y Retardos"
                        classNameParent="col-12"
                        required
                        invalid="El campo es obligatorio."
                        className="text-center"
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.assistance_lateness}
                        onChange={e => setRow({ ...row, p_assistance: {
                            ...row.p_assistance,
                            p_assistance_lateness: e.currentTarget.value
                        } })}
                    />
                    <Input
                        text="Asistencia a Juntas"
                        placeholder="Asistencia a Juntas"
                        classNameParent="col-12"
                        required
                        invalid="El campo es obligatorio."
                        className="text-center"
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.assistance_meetings}
                        onChange={e => setRow({ ...row, p_assistance: {
                            ...row.p_assistance,
                            p_assistance_meetings: e.currentTarget.value
                        } })}
                    />
                    <Input
                        text="Disponibilidad"
                        placeholder="Disponibilidad"
                        classNameParent="col-12"
                        required
                        invalid="El campo es obligatorio."
                        className="text-center"
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.availability}
                        onChange={e => setRow({ ...row, p_assistance: {
                            ...row.p_assistance,
                            p_availability: e.currentTarget.value
                        } })}
                    />
                    <Input
                        text="Porcentaje"
                        placeholder="Porcentaje"
                        classNameParent="col-12"
                        required
                        invalid="El campo es obligatorio."
                        value={(percentageAssistance() === -1 ? 0 : percentageAssistance()).toFixed(2) + '%'}
                        disabled
                        className="text-center font-weight-bold"
                        style={{
                            fontSize: 20
                        }} 
                    />
                    {sumAssistance() === -1 ? <div className="col-12 mb-2" style={{
                        marginTop: '-20px'
                    }}>
                        <label className="text-danger">Por favor, ingresar cantidades correctas</label>
                    </div> : '' }
                </div>
            </div>
            <div className="col-12">
                <div className="p-2 text-white mb-4" style={{backgroundColor: '#211431' }}>
                    Resultados 50%
                </div>
                <div className="form-row">
                    <Input
                        text="Concepto a Evaluar"
                        placeholder="Concepto a Evaluar"
                        classNameParent="col-6"
                        required
                        invalid="El campo es obligatorio."
                        defaultValue={data.results.one.concept}
                        onChange={e => setRow({ ...row, p_results: {
                            ...row.p_results,
                            p_one: {
                                ...row.p_results.p_one,
                                p_concept: e.currentTarget.value
                            }
                        } })}
                        maxLength={255}
                    />
                    <Input
                        text="Calificación"
                        placeholder="Calificación"
                        classNameParent="col-3"
                        required
                        invalid="El campo es obligatorio."
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.results.one.calification}
                        onChange={e => setRow({ ...row, p_results: {
                            ...row.p_results,
                            p_one: {
                                ...row.p_results.p_one,
                                p_calification: e.currentTarget.value
                            }
                        } })}
                        className="text-center"
                    />
                    <Input
                        text="Porcentaje"
                        placeholder="Porcentaje"
                        classNameParent="col-3"
                        required
                        invalid="El campo es obligatorio."
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.results.one.percentage}
                        onChange={e => setRow({ ...row, p_results: {
                            ...row.p_results,
                            p_one: {
                                ...row.p_results.p_one,
                                p_percentage: e.currentTarget.value
                            }
                        } })}
                        className="text-center"
                    />
                </div>
                <div className="form-row">
                    <Input
                        text="Concepto a Evaluar"
                        placeholder="Concepto a Evaluar"
                        classNameParent="col-6"
                        required
                        invalid="El campo es obligatorio."
                        defaultValue={data.results.two.concept}
                        onChange={e => setRow({ ...row, p_results: {
                            ...row.p_results,
                            p_two: {
                                ...row.p_results.p_two,
                                p_concept: e.currentTarget.value
                            }
                        } })}
                        maxLength={255}
                    />
                    <Input
                        text="Calificación"
                        placeholder="Calificación"
                        classNameParent="col-3"
                        required
                        invalid="El campo es obligatorio."
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.results.two.calification}
                        onChange={e => setRow({ ...row, p_results: {
                            ...row.p_results,
                            p_two: {
                                ...row.p_results.p_two,
                                p_calification: e.currentTarget.value
                            }
                        } })}
                        className="text-center"
                    />
                    <Input
                        text="Porcentaje"
                        placeholder="Porcentaje"
                        classNameParent="col-3"
                        required
                        invalid="El campo es obligatorio."
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.results.two.percentage}
                        onChange={e => setRow({ ...row, p_results: {
                            ...row.p_results,
                            p_two: {
                                ...row.p_results.p_two,
                                p_percentage: e.currentTarget.value
                            }
                        } })}
                        className="text-center"
                    />
                </div>
                <div className="form-row">
                    <Input
                        text="Concepto a Evaluar"
                        placeholder="Concepto a Evaluar"
                        classNameParent="col-6"
                        required
                        invalid="El campo es obligatorio."
                        defaultValue={data.results.three.concept}
                        onChange={e => setRow({ ...row, p_results: {
                            ...row.p_results,
                            p_three: {
                                ...row.p_results.p_three,
                                p_concept: e.currentTarget.value
                            }
                        } })}
                        maxLength={255}
                    />
                    <Input
                        text="Calificación"
                        placeholder="Calificación"
                        classNameParent="col-3"
                        required
                        invalid="El campo es obligatorio."
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.results.three.calification}
                        onChange={e => setRow({ ...row, p_results: {
                            ...row.p_results,
                            p_three: {
                                ...row.p_results.p_three,
                                p_calification: e.currentTarget.value
                            }
                        } })}
                        className="text-center"
                    />
                    <Input
                        text="Porcentaje"
                        placeholder="Porcentaje"
                        classNameParent="col-3"
                        required
                        invalid="El campo es obligatorio."
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.results.three.percentage}
                        onChange={e => setRow({ ...row, p_results: {
                            ...row.p_results,
                            p_three: {
                                ...row.p_results.p_three,
                                p_percentage: e.currentTarget.value
                            }
                        } })}
                        className="text-center"
                    />
                </div>
                <div className="form-row">
                    <Input
                        text="Concepto a Evaluar"
                        placeholder="Concepto a Evaluar"
                        classNameParent="col-6"
                        required
                        invalid="El campo es obligatorio."
                        defaultValue={data.results.four.concept}
                        onChange={e => setRow({ ...row, p_results: {
                            ...row.p_results,
                            p_four: {
                                ...row.p_results.p_four,
                                p_concept: e.currentTarget.value
                            }
                        } })}
                        maxLength={255}
                    />
                    <Input
                        text="Calificación"
                        placeholder="Calificación"
                        classNameParent="col-3"
                        required
                        invalid="El campo es obligatorio."
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.results.four.calification}
                        onChange={e => setRow({ ...row, p_results: {
                            ...row.p_results,
                            p_four: {
                                ...row.p_results.p_four,
                                p_calification: e.currentTarget.value
                            }
                        } })}
                        className="text-center"
                    />
                    <Input
                        text="Porcentaje"
                        placeholder="Porcentaje"
                        classNameParent="col-3"
                        required
                        invalid="El campo es obligatorio."
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.results.four.percentage}
                        onChange={e => setRow({ ...row, p_results: {
                            ...row.p_results,
                            p_four: {
                                ...row.p_results.p_four,
                                p_percentage: e.currentTarget.value
                            }
                        } })}
                        className="text-center"
                    />
                </div>
                <div className="form-row">
                    <Input
                        text="Concepto a Evaluar"
                        placeholder="Concepto a Evaluar"
                        classNameParent="col-6"
                        required
                        invalid="El campo es obligatorio."
                        defaultValue={data.results.five.concept}
                        onChange={e => setRow({ ...row, p_results: {
                            ...row.p_results,
                            p_five: {
                                ...row.p_results.p_five,
                                p_concept: e.currentTarget.value
                            }
                        } })}
                        maxLength={255}
                    />
                    <Input
                        text="Calificación"
                        placeholder="Calificación"
                        classNameParent="col-3"
                        required
                        invalid="El campo es obligatorio."
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.results.five.calification}
                        onChange={e => setRow({ ...row, p_results: {
                            ...row.p_results,
                            p_five: {
                                ...row.p_results.p_five,
                                p_calification: e.currentTarget.value
                            }
                        } })}
                        className="text-center"
                    />
                    <Input
                        text="Porcentaje"
                        placeholder="Porcentaje"
                        classNameParent="col-3"
                        required
                        invalid="El campo es obligatorio."
                        min="0"
                        max="100"
                        step="1"
                        type="number"
                        defaultValue={data.results.five.percentage}
                        onChange={e => setRow({ ...row, p_results: {
                            ...row.p_results,
                            p_five: {
                                ...row.p_results.p_five,
                                p_percentage: e.currentTarget.value
                            }
                        } })}
                        className="text-center"
                    />
                </div>
                <div className="form-row">
                    <div className="col-6"></div>
                    <Input
                        text="Porcentaje"
                        placeholder="Porcentaje"
                        classNameParent="col-6"
                        required
                        invalid="El campo es obligatorio."
                        disabled
                        value={(percentageResults() === -1 ? 0 : percentageResults()).toFixed(2) + '%'}
                        className="text-center font-weight-bold"
                        style={{
                            fontSize: 20
                        }} 
                    />
                    <div className="col-6"></div>
                    {sumResults() === -1 || sumResultsCalification() === -1 ? <div className="col-6 mb-2" style={{
                        marginTop: '-20px'
                    }}>
                        <label className="text-danger">Por favor, ingresar cantidades correctas</label>
                    </div> : '' }
                </div>
                <div className="form-row">
                    <div className="col-12">
                        <hr className="border-light" />
                    </div>
                    <Input
                        text="Porcentaje Total"
                        placeholder="Porcentaje Total"
                        classNameParent="col-12"
                        invalid="El campo es obligatorio."
                        value={(
                            (percentageAttitude() === -1 ? 0 : percentageAttitude()) +
                            (percentageAssistance() === -1 ? 0 : percentageAssistance()) +
                            (percentageResults() === -1 ? 0 : percentageResults())
                        ).toFixed(2) + '%'}
                        disabled
                        className="text-center font-weight-bold"
                        style={{
                            fontSize: 20
                        }}         
                    />
                </div>
            </div>
            <div className="col-6">
                <Button variant="primary btn-gradient-primary" className="mt-2" block onClick={e => {
                    let $this = e.currentTarget
                    let buttonHTML = $this.innerHTML
                    $this.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'

                    Controllers.evaluation.reporte_evalution_by_customers({
                        p_id_customers: idCustomers
                    }).then(res => {
                        let elemA = document.createElement('a')
                        elemA.setAttribute('download', res.data.filename)
                        elemA.setAttribute('href', res.data.file)
                        elemA.click()
                        $this.innerHTML = buttonHTML
                    })
                }}>
                    <i className="fa fa-file-excel mr-2"></i>Exportar Excel
                </Button>
            </div>
            <div className="col-6">
                <Button variant="primary btn-gradient-primary" disabled={disablebSubmit} className="mt-2" block type="submit">
                    {loadSubmit ? <i className="fa fa-circle-notch fa-spin"></i> : <><i className="fa fa-check mr-2"></i> Guardar Información</>}
                </Button>
            </div>
        </FormCustom>
    </ReactPlaceholder>
}

CalificationForm.defaultProps = {
    data: {
        id_evaluation: '',
        id_customers: '',
        evaluator_eval: '',
        attitude: {
            attitude_service: '',
            reliability: '',
            team_work: '',
            assertive_communication: '',
            dress_code: '',
            percentage: ''
        },
        assistance: {
            assistance_lateness: '',
            assistance_meetings: '',
            availability: '',
            percentage: '',
        },
        results: {
            one: {
                concept: '',
                calification: '',
                percentage: ''
            },
            two: {
                concept: '',
                calification: '',
                percentage: ''
            },
            three: {
                concept: '',
                calification: '',
                percentage: ''
            },
            four: {
                concept: '',
                calification: '',
                percentage: ''
            },
            five: {
                concept: '',
                calification: '',
                percentage: ''
            }
        },
        percentage_total: ''
    },
    onSubmit: () => {},
    idCustomers: '',
    onSuccess: () => {}
}

export default CalificationForm;