import React from 'react'
import NotiForm from './NotiForm'
import Card from 'react-bootstrap/Card';

const Noti = () => {
    return <>
        <Card>
            <Card.Header className="bg-danger text-white">
                Nuevo Noti Super
            </Card.Header>
            <Card.Body className="bg-secondary">
                <NotiForm />
            </Card.Body>
        </Card>
    </>
}

export default Noti