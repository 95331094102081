import React, { useState, useMemo } from 'react'
import { useEffect } from 'react'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, SwitchToggle, Select } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/esm/Button'
import ImagesModal from '../Images/ImagesModal'
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap'

const HistoryBitacora = ({ idCustomers }) => {
    const [rows, setRows] = useState([])
    const [ready, setReady] = useState(false)
    const [customer, setCustomer] = useState({})
    
    useEffect(() => {
        Promise.all([
            Controllers.bitacora.get_bitacora_customers({
                p_id_customers: idCustomers
            }),
            Controllers.customers.get_customers_id({
                p_id_customers: idCustomers
            })
        ]).then(res => {
            setRows(res[0].data)
            setCustomer(res[1].data)
            setReady(true)
        })
    }, [idCustomers])

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'date_bita', text: 'Fecha', align: 'center' },
                { name: 'id_bitacora', text: 'ID', align: 'center' },
                { name: 'period_bita', text: 'Periódo', align: 'center'},
                { name: 'impact_bita', text: 'Impacto', align: 'center' },
            ],
        ]}
        rows={rows}
        isProcessing={!ready}
    />, [rows, ready])

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
        <div className="form-row">
            <Input
                text="Nombre"
                placeholder="Personal a Evaluar"
                classNameParent="col-12 col-md-8 mt-2 mb-2"
                required
                invalid="El campo es obligatorio."
                value={customer.full_name_cust}
                disabled
            />
            <Input
                text="Num. Nómina"
                placeholder="Num. Nómina"
                classNameParent="col-12 col-md-4 mt-2 mb-2"
                required
                invalid="El campo es obligatorio."
                value={customer.id_customers}
                disabled
                className="text-center"
            />
        </div>
        {dtRows}
    </ReactPlaceholder>
}

HistoryBitacora.defaultProps = {
    idCustomers: ''
}

export default HistoryBitacora;