import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import ForoForm from './ForoForm'
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import { FormCustom, Input, Select } from '../../Components/Form/Form';
import { Link } from 'react-router-dom';
import ReactPlaceholder from 'react-placeholder/lib';

const Foro = ({ nameSingle, namePlural, separator }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [dataUpdated, setDataUpdated] = useState({})
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [loadSubmitDelete, setLoadSubmitDelete] = useState(false)
    const [dataDeleted, setDataDeleted] = useState({})
    const [ready, setReady] = useState(false)
    const [categories, setCategories] = useState([])
    const [formFilter, setFormFilter] = useState({
        p_id_category: '',
        p_text_filter: ''
    })
    const [readyCategory, setReadyCategory] = useState(false)

    useEffect(() => {
        Controllers.category.get_category().then(res => {
            setCategories([
                { id_category: '', name_category: 'Todas' },
                ...res.data,
            ])
            setReadyCategory(true)
        })
    }, [])

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            setReady(false)
            Controllers.foro.get_foro(formFilter).then(res => {
                setRows(res.data)
                setReady(true)
            }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setIsProccesing(false)
                setReload(false)
            })
        }
    }, [reload, formFilter])

    const category = useMemo(() => {
        if (formFilter.p_id_category === '') {
            return {
                label: 'Todas',
                value: ''
            }
        }
        
        let finded = categories.find(j => parseInt(j.id_category) === parseInt(formFilter.p_id_category))

        if (!finded) {
            return
        }

        return {
            label: finded.name_category,
            value: finded.id_category
        }
    }, [formFilter.p_id_category, categories])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.foro.foro_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setModalShow(false)
                setDataUpdated({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
            setReload(true)
        })
    }

    const handleGet = e => {
        let $this = e.currentTarget

        if (!$this.dataset?.idForo) {
            setModalTitle(`Registrar ${nameSingle}`)
            setDataUpdated({})
            setModalShow(true);
            return
        }

        let buttonHTML = $this.innerHTML;
        $this.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'
        let { idForo } = $this.dataset

        Controllers.foro.get_foro_id({ p_id_foro: idForo }).then(res => {
            setModalTitle(`Modificar ${nameSingle} #${idForo}`)
            setDataUpdated({ data: res.data })
            setModalShow(true);
        }).catch(req => {
            Helpers.promise.catch({ req, toast })
            setReload(true)
        })
        .finally(() => $this.innerHTML = buttonHTML)
    }

    const handleDelete = () => {
        setLoadSubmitDelete(true)

        Controllers.foro.foro_delete(dataDeleted).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setShowConfirmDelete(false)
                setDataDeleted({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setReload(true)
            setLoadSubmitDelete(false)
        })
    }

    const handleHideModalForm = () => {
        setModalShow(false)
        setLoadSubmit(false)
    }

    const handleShowModalDelete = e => {
        let { idForo, nameForo } = e.currentTarget.dataset
        setDataDeleted({
            p_id_foro: idForo,
            p_name_foro: nameForo
        })
        setShowConfirmDelete(true)
    }

    const handleHideModalDelete = () => setShowConfirmDelete(false)

    /* const setForoMain = ({ p_id_foro, p_main_foro, e }) => {
        let $this = e.currentTarget
        $this.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'

        Controllers.foro.foro_main_update({
            p_id_foro, p_main_foro
        }).then(_ => {
            setReload(true)
        })
    } */

    return <>
        <Card>
            <Card.Header className="d-flex justify-content-between bg-danger text-white">
                <span>Histórico</span>
            </Card.Header>
            <Card.Body className="form-row">
                <div className="col-12 mb-2">
                    <ReactPlaceholder ready={readyCategory} showLoadingAnimation rows={5}>
                        <FormCustom viewSubmit={false} onSubmit={_ => setReload(true)}>
                            <Select
                                options={categories.map(j => {
                                    return {
                                        label: j.name_category,
                                        value: j.id_category
                                    }
                                })}
                                text="Departamentos"
                                classNameParent="col-md-4"
                                value={category}
                                onChange={e => setFormFilter({ ...formFilter, p_id_category: e.value })}
                            />
                            <div className="col-md-8" style={{
                                marginTop: '13px'
                            }}>
                                <Input
                                    text="Buscar"
                                    onChange={e => setFormFilter({ ...formFilter, p_text_filter: e.currentTarget.value })}
                                    append={[
                                        <Button type="submit">
                                            <i className="fa fa-search"></i>
                                        </Button>
                                    ]}
                                />
                            </div>
                        </FormCustom>
                    </ReactPlaceholder>
                </div>
                <div className="col-12 bg-secondary p-4">
                    <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
                        <div className="form-row">
                            <div className="col-12 col-md-9 mb-2">
                                {rows.length === 0 ? <div className="alert alert-warning">
                                    <strong>¡Lo sentimos!</strong> No hay información para mostrar.
                                </div> : React.Children.toArray(rows.map(r => {
                                    return <Card className="mb-2">
                                        <Card.Body className="d-flex position-relative pt-4">
                                            <Link to={Helpers.config.convertUrl({ url: '/foro/' + r.token_foro })}>
                                                <img src={Helpers.config.resourcesUrl({ url: r.file_photo_foro })} width="50" height="50" style={{
                                                    borderRadius: 50
                                                }} />
                                            </Link>
                                            <div className="d-flex flex-column pl-3 w-100">
                                                <Link to={Helpers.config.convertUrl({ url: '/foro/' + r.token_foro })}>
                                                    <h6 className="bg-dark text-white p-2">
                                                        {r.name_foro}
                                                    </h6>
                                                </Link>
                                                <p>{r.observation_foro}</p>
                                                <small className="text-muted">{r.date_created}</small>
                                            </div>
                                            {/* parseInt(r.main_foro) === 1 ? <>
                                                <div className="badge badge-info" style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 45,
                                                }}>
                                                    Principal
                                                </div>
                                                <div className="badge badge-danger cur-pointer" onClick={e => setForoMain({
                                                    p_main_foro: 0,
                                                    p_id_foro: r.id_foro,
                                                    e
                                                })} style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                }}>
                                                    Quitar
                                                </div>
                                            </> : <>
                                                <div className="badge badge-success cur-pointer" onClick={e => setForoMain({
                                                    p_main_foro: 1,
                                                    p_id_foro: r.id_foro,
                                                    e
                                                })} style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0
                                                }}>
                                                    Agregar
                                                </div>
                                            </> */}
                                        </Card.Body>
                                    </Card>
                                }))}
                            </div>
                            <div className="col-12 col-md-3 pl-4">
                                <Button  variant="primary btn-gradient-primary" onClick={handleGet}>
                                    <i className="fa fa-plus mr-1"></i> Crear {nameSingle}
                                </Button>
                            </div>
                        </div>
                    </ReactPlaceholder>
                </div>
            </Card.Body>
        </Card>
        <Modal show={modalShow} onHide={handleHideModalForm} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <ForoForm onSubmit={handleInsertUpdate} loadSubmit={loadSubmit} {...dataUpdated} />
            </Modal.Body>
        </Modal>
        <SweetAlert
            icon="delete"
            show={showConfirmDelete}
            showCancelButton
            title={<>¿Desea eliminar {separator} {nameSingle}?</>}
            subtitle={<>#{dataDeleted.p_id_foro} - {dataDeleted.p_name_foro}</>}
            confirmButtonText="¡Sí, eliminar!"
            cancelButtonText="¡No!"
            loadSubmit={loadSubmitDelete}
            onCancel={handleHideModalDelete}
            onConfirm={handleDelete}
        />
    </>
}

export default Foro