import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import { Select } from '../../Components/Form/Form';
import ReactPlaceholder from 'react-placeholder/lib';
import CalificationForm from './CalificationForm';
import BitacoraForm from './BitacoraForm';
import HistoryBitacora from './HistoryBitacora';

const Evaluation = () => {
    const [ready, setReady] = useState(false)
    const [branchOffices, setBranchOffices] = useState([])
    const [branchOfficeSelected, setBranchOfficeSelected] = useState('')
    const [customerSelected, setCustomerSelected] = useState('')
    const [customers, setCustomers] = useState([])

    useEffect(() => {
        Controllers.branch_office.get_branch_office().then(res => {
            setBranchOffices(res.data.filter(b => parseInt(b.state_branch_office) === 1))
            setReady(true)
        })
    }, [])

    const [titleCalification, setTitleCalification] = useState('')

    useEffect(() => {
        if (branchOfficeSelected !== '') {
            setCustomers([])
            Controllers.customers.get_customers_branch_office({
                p_id_branch_office: branchOfficeSelected.value
            }).then(res => {
                setCustomers(res.data)
            })
        }
    }, [branchOfficeSelected])

    const [typeForm, setTypeForm] = useState(null)

    const formAction = useMemo(() => {
        if (typeForm === null) {
            return ''
        }
        if (typeForm === 1) {
            setTitleCalification('Calificar Empleado')
            return <CalificationForm idCustomers={customerSelected.value} onSuccess={() => setTypeForm(null)} />
        }
        if (typeForm === 2) {
            setTitleCalification('Bitácora del Desempeño')
            return <BitacoraForm idCustomers={customerSelected.value} onSuccess={() => setTypeForm(null)} />
        }
        if (typeForm === 3) {
            setTitleCalification('Historial de Bitácoras')
            return <HistoryBitacora idCustomers={customerSelected.value} />
        }
    }, [typeForm, customerSelected])

    return <>
        <div className="form-row">
            <div className="col-12 col-md-4">
                <Card>
                    <Card.Header className="d-flex justify-content-between bg-danger text-white">
                        <div className="form-row">
                            <span>Tipo de Evaluación</span>
                        </div>
                    </Card.Header>
                    <Card.Header>
                        <div className="form-row">
                            <ReactPlaceholder ready={ready} showLoadingAnimation rows={3}>
                                <Select 
                                    text="Sucursal"
                                    options={branchOffices.map(b => {
                                        return {
                                            label: b.name_branch_office,
                                            value: b.id_branch_office,
                                        }
                                    })}
                                    classNameParent="col-12 mb-2"
                                    onChange={e => {
                                        setBranchOfficeSelected(e)
                                        setCustomerSelected('')
                                        setTypeForm(null)
                                    }}
                                />
                            </ReactPlaceholder>
                            <ReactPlaceholder ready={ready} showLoadingAnimation rows={3}>
                                <Select 
                                    text="Empleados"
                                    options={customers.map(b => {
                                        return {
                                            label: b.full_name_cust,
                                            value: b.id_customers,
                                        }
                                    })}
                                    value={customerSelected}
                                    onChange={e => {
                                        setCustomerSelected(e)
                                        setTypeForm(null)
                                    }}
                                />
                            </ReactPlaceholder>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="form-row">
                            <div className="col-12 cur-pointer mb-3" onClick={_ => {
                                if (branchOfficeSelected === '') {
                                    Helpers.toast.construct({ response: 'warning', message: 'Seleccionar Sucursal', toast })
                                    return
                                }
                                if (customerSelected === '') {
                                    Helpers.toast.construct({ response: 'warning', message: 'Seleccionar Empleado', toast })
                                    return
                                }
                                setTypeForm(1)
                            }}>
                                <div className="bg-dark p-3 text-center text-white">
                                    CALIFICAR
                                </div>
                            </div>
                            <div className="col-12 cur-pointer mb-3" onClick={_ => {
                                if (branchOfficeSelected === '') {
                                    Helpers.toast.construct({ response: 'warning', message: 'Seleccionar Sucursal', toast })
                                    return
                                }
                                if (customerSelected === '') {
                                    Helpers.toast.construct({ response: 'warning', message: 'Seleccionar Empleado', toast })
                                    return
                                }
                                setTypeForm(2)
                            }}>
                                <div className="bg-dark p-3 text-center text-white">
                                    BITÁCORA
                                </div>
                            </div>
                            <div className="col-12 cur-pointer mb-3" onClick={_ => {
                                if (branchOfficeSelected === '') {
                                    Helpers.toast.construct({ response: 'warning', message: 'Seleccionar Sucursal', toast })
                                    return
                                }
                                if (customerSelected === '') {
                                    Helpers.toast.construct({ response: 'warning', message: 'Seleccionar Empleado', toast })
                                    return
                                }
                                setTypeForm(3)
                            }}>
                                <div className="bg-dark p-3 text-center text-white">
                                    HISTORIAL
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-12 col-md-8 mb-4">
                {typeForm === null ? '' : <Card>
                    <Card.Header className="d-flex justify-content-between bg-danger text-white">
                        {titleCalification}
                    </Card.Header>
                    <Card.Body>
                        {formAction}
                    </Card.Body>
                </Card>}
            </div>
        </div>
    </>
}

export default Evaluation