import React, { useCallback, useState } from 'react'
import { useRef } from 'react'
import Button from 'react-bootstrap/esm/Button'
import { Input, FormCustom, SwitchToggle, TextArea, CheckBox } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import ImagesModal from '../Images/ImagesModal'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import Controllers from '../../Api/Controllers'
import ReactPlaceholder from 'react-placeholder/lib'

const VideoAdditionalForm = ({ data, onSubmit, disablebSubmit, loadSubmit, idVideo }) => {
    const [row, setRow] = useState({
        p_id_video_additional: data.id_video_additional,
        p_id_video: data.id_video,
        p_name_add: data.name_add,
        p_description_add: data.description_add,
        p_file_add: data.file_add,
        p_status_add: data.status_add,
    })

    const dataSubmit = useCallback(() => {
        let formData = new FormData()

        for (const key in row) {
            if (key === 'p_id_video') {
                formData.append('p_id_video', idVideo)
                continue
            }

            if (key === 'p_file_add') {
                if (row.p_file_add?.files) {
                    if (row.p_file_add.files.length > 0) {
                        formData.append('p_file_add', row.p_file_add.files[0])
                        continue
                    }
                }
                formData.append('p_file_add', '')
                continue
            }
            formData.append(key, row[key])
        }

        return formData
    }, [row, idVideo])

    return <>
        <FormCustom dataSubmit={dataSubmit()} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <Input
                text="Nombre del material"
                classNameParent="col-12 mt-2 mb-2"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.name_add}
                onChange={e => setRow({ ...row, p_name_add: e.currentTarget.value })}
                maxLength={150}
            />
            <TextArea
                text="Descripción"
                classNameParent="col-12 mt-2 mb-3"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.description_add}
                onChange={e => setRow({ ...row, p_description_add: e.currentTarget.value })}
                rows={5}
            />
            <div className="col-12">
                {!isNaN(parseInt(data.id_video_additional)) ? <a target="_blank" href={Helpers.config.resourcesUrl({ url: data.file_add })}>
                    <Button className="mb-2">
                        <i className="fa fa-file mr-1"></i>Descargar Material
                    </Button>
                </a> : ''}
                <Input
                    type="file"
                    classNameParent=""
                    required={isNaN(parseInt(data?.id_video_additional))}
                    invalid="El campo es obligatorio."
                    onChange={e => {
                        setRow({ ...row, p_file_add: e.currentTarget })
                    }}
                />
            </div>
            <SwitchToggle
                classNameParent="col-12 mb-2"
                id="video_add_state"
                defaultChecked={parseInt(row.p_status_add) === 1}
                onChange={e => setRow({ ...row, p_status_add: e.currentTarget.checked ? 1 : 0 })}
            />
        </FormCustom>
    </>
}

VideoAdditionalForm.defaultProps = {
    data: {
        id_video_additional: '',
        id_video: '',
        name_add: '',
        description_add: '',
        file_add: '',
        status_add: 0,
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default VideoAdditionalForm;