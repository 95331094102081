import React, { useEffect, useState, useMemo } from 'react'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, SwitchToggle, TextArea, Select } from '../../Components/Form/Form'
import ReactPlaceholder from 'react-placeholder'

const IncidenceClose = ({ idIncidence, disablebSubmit, onSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_incidence: idIncidence,
        p_date_close_incidence: '',
        p_folio_close_incidence: '',
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            type="date"
            text="Fecha Cierre"
            placeholder="Fecha Cierre"
            classNameParent="col-12"
            required
            invalid="El campo es obligatorio."
            defaultValue={''}
            onChange={e => setRow({ ...row, p_date_close_incidence: e.currentTarget.value })}
        />
        <Input
            text="Folio Cierre"
            placeholder="Folio Cierre"
            classNameParent="col-12 mt-4"
            required
            invalid="El campo es obligatorio."
            defaultValue={''}
            onChange={e => setRow({ ...row, p_folio_close_incidence: e.currentTarget.value })}
            maxLength={100}
            style={{
                marginTop: -2
            }}
        />
    </FormCustom>
}

IncidenceClose.defaultProps = {
    idIncidence: '',
    onSubmit: () => {},
    loadSubmit: false
}

export default IncidenceClose;