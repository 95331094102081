import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import { Link } from 'react-router-dom';
import SweetAlert from '../../Components/SweetAlert/SweetAlert';

const ForoList = ({ nameSingle, separator, namePlural }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers.foro.get_foro().then(res => {
                setRows(res.data)
            }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setIsProccesing(false)
                setReload(false)
            })
        }
    }, [reload])

    const [dataDeleted, setDataDeleted] = useState({})
    const [loadSubmitDelete, setLoadSubmitDelete] = useState(false)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const handleDelete = () => {
        setLoadSubmitDelete(true)

        Controllers.foro.foro_delete(dataDeleted).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setShowConfirmDelete(false)
                setDataDeleted({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setReload(true)
            setLoadSubmitDelete(false)
        })
    }

    const handleShowModalDelete = e => {
        let { idForo, nameForo } = e.currentTarget.dataset
        setDataDeleted({
            p_id_foro: idForo,
            p_name_foro: nameForo
        })
        setShowConfirmDelete(true)
    }

    const handleHideModalDelete = () => setShowConfirmDelete(false)

    const handleShowInDashboard = e => {
        let { idForo, mainForo } = e.currentTarget.dataset

        Controllers.foro.foro_main_update({
            p_main_foro: mainForo,
            p_id_foro: idForo
        }).then(res => {
            setReload(true)
            Helpers.toast.construct({ ...res, toast })
        })
    }

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_foro', text: 'ID', align: 'center' },
                { name: 'name_foro', text: 'Nombres' },
                { name: 'file_photo_foro', text: 'Imagen', render: r => <img className="img-thumbnail" width="100" src={Helpers.config.resourcesUrl({ url: r.file_photo_foro })} />, align: 'center' },
                { name: 'name_category', text: 'Departamento' },
                { name: 'main_foro', text: 'En Escritorio', align: 'center', render: r => <>
                    <div className={'badge badge-' + (parseInt(r.main_foro) === 1 ? 'success' : 'danger')}>
                        {parseInt(r.main_foro) === 1 ? 'SI' : 'NO'}
                    </div>
                    <Button variant="dark" size="xs" className="ml-1" data-id-foro={r.id_foro} data-main-foro={parseInt(r.main_foro) === 1 ? 0 : 1} onClick={handleShowInDashboard}>
                        <i className="fas fa-retweet"></i>
                    </Button>
                </> },
                { name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => {
                    return <div>
                        <Link to={Helpers.config.convertUrl({ url: '/foro/' + u.token_foro })}>
                            <Button className="mr-1" size="xs">
                                <i className="fas fa-link"></i>
                            </Button>
                        </Link>
                        <Button variant="danger" size="xs" data-id-foro={u.id_foro} onClick={handleShowModalDelete} data-name-foro={u.name_foro}>
                            <i className="fa fa-trash"></i>
                        </Button>
                    </div>
                } },
            ],
        ]}
        rows={rows}
        isProcessing={isProcessing}
    />, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="d-flex justify-content-between bg-danger text-white">
                <span className="align-self-center">Listado De {namePlural}</span>
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
        <SweetAlert
            icon="delete"
            show={showConfirmDelete}
            showCancelButton
            title={<>¿Desea eliminar {separator} {nameSingle}?</>}
            subtitle={<>#{dataDeleted.p_id_foro} - {dataDeleted.p_name_foro}</>}
            confirmButtonText="¡Sí, eliminar!"
            cancelButtonText="¡No!"
            loadSubmit={loadSubmitDelete}
            onCancel={handleHideModalDelete}
            onConfirm={handleDelete}
        />
    </>
}

export default ForoList